import type { ComparisonRow as ParquetComparisonRows } from '~/data/openapi-client/index';
import { Database, db } from '~/db';
import type * as duckdb from '@duckdb/duckdb-wasm';
import { CompiledQuery, type ExpressionBuilder, ExpressionWrapper, Kysely, sql, type SqlBool } from 'kysely';
import type { CompositeGridFilter, GridFilter } from './hooks';
import { DisplayedRow } from './hooks';
import { match, P } from 'ts-pattern';
import { FilterOperator } from '@progress/kendo-data-query';
import { duckDB, executeCompiledQuery } from '~/duckdb';

export type TableName = `comparison_rows_${string}`;
export type ComparisonRow = ParquetComparisonRows & {
  RelativeDifference: number;
  RelativeDifferencePercent: number;
};

export type ComparisonRowsTable = ComparisonRow;

export function createTableName(comparisonId: string): TableName {
  comparisonId = comparisonId.replaceAll('-', '_');
  return `comparison_rows_${comparisonId}`;
}

export function createFilename(comparisonId: string): string {
  comparisonId = comparisonId.replaceAll('-', '_');

  return `comparison_rows_${comparisonId}.parquet`;
}

export async function modifyComparisonTableSchema(
  tableName: TableName,
  db: Kysely<Database>,
  execute: (query: CompiledQuery) => Promise<any>,
) {
  await execute(
    db.schema
      .alterTable(tableName)
      .addColumn('RelativeDifference', 'double precision', (b) => b.ifNotExists())
      .compile(),
  );

  await execute(
    db.schema
      .alterTable(tableName)
      .addColumn('RelativeDifferencePercent', 'double precision', (b) => b.ifNotExists())
      .compile(),
  );
}

export async function computeComparisonValues(
  tableName: TableName,
  db: Kysely<Database>,
  execute: (query: CompiledQuery) => Promise<any>,
) {
  await execute(
    db
      .updateTable(tableName)
      .set(({ ref }) => {
        const l = ref('LeftValueParsed');
        const r = ref('RightValueParsed');

        return {
          RelativeDifference: sql`${r} - ${l}`,
        };
      })
      .where((eb) => eb.and([eb('LeftValueParsed', 'is not', null), eb('RightValueParsed', 'is not', null)]))
      .compile(),
  );

  await execute(
    db
      .updateTable(tableName)
      .set({
        RelativeDifferencePercent: sql`'-Infinity'`,
      })
      .where((eb) => {
        return eb.and([
          eb('LeftValueParsed', 'is not', null),
          eb('LeftValueParsed', '!=', 0),
          eb('RightValueParsed', '=', 0),
        ]);
      })
      .compile(),
  );

  await execute(
    db
      .updateTable(tableName)
      .set({
        RelativeDifferencePercent: sql`'Infinity'`,
      })
      .where((eb) => {
        return eb.and([
          eb('LeftValueParsed', '=', 0),
          eb('RightValueParsed', '!=', 0),
          eb('RightValueParsed', 'is not', null),
        ]);
      })
      .compile(),
  );

  await execute(
    db
      .updateTable(tableName)
      .set({
        RelativeDifferencePercent: 0,
      })
      .where((eb) => eb.and([eb('LeftValueParsed', '=', 0), eb('RightValueParsed', '=', 0)]))
      .compile(),
  );

  await execute(
    db
      .updateTable(tableName)
      .set(({ ref }) => {
        const l = ref('LeftValueParsed');
        const r = ref('RightValueParsed');

        return {
          // @formatter:off
          RelativeDifferencePercent: sql<number>` ((${r} - ${l}) / abs(${l})) * 100 `,
          // @formatter:off
        };
      })
      .where((eb) => {
        return eb.and([
          eb('RightValueParsed', 'is not', null),
          eb('RightValueParsed', '!=', 0),
          eb('LeftValueParsed', 'is not', null),
          eb('LeftValueParsed', '!=', 0),
        ]);
      })
      .compile(),
  );
}

export async function registerComparisonFile(
  connection: duckdb.AsyncDuckDBConnection,
  buffer: Uint8Array,
  comparisonId: string,
): Promise<TableName> {
  const filename = createFilename(comparisonId);
  await duckDB.registerFileBuffer(filename, buffer);
  const tableName = createTableName(comparisonId);

  // @formatter:off
  await connection.query(`create table if not exists ${tableName} as select * from read_parquet(${filename})`);
  // @formatter:off
  await modifyComparisonTableSchema(tableName, db, (q) => executeCompiledQuery(q, connection));
  await computeComparisonValues(tableName, db, (q) => executeCompiledQuery(q, connection));

  return tableName;
}

export async function listComparisons(
  conn: duckdb.AsyncDuckDBConnection,
  query: CompiledQuery,
): Promise<ComparisonRow[]> {
  const result = await executeCompiledQuery(query, conn);

  return result.toArray().map((x) => x.toJSON());
}

export async function countComparisons(conn: duckdb.AsyncDuckDBConnection, query: CompiledQuery): Promise<number> {
  const result = await executeCompiledQuery(query, conn);

  return Number(result.toArray()[0].count);
}

export type Column = keyof DisplayedRow;
export type Direction = 'asc' | 'desc';

export type Filters = {
  sort: Column;
  direction: Direction;
  limit: number;
  offset: number;
  filter: CompositeGridFilter | undefined;
};

const DisplayedRowTableMapping: {
  sort: Record<keyof DisplayedRow, keyof ComparisonRowsTable>;
  filter: Record<keyof DisplayedRow, keyof ComparisonRowsTable>;
} = {
  sort: {
    Id: 'Id',
    TableCode: 'TableCode',
    RowCode: 'RowCode',
    ColumnCode: 'ColumnCode',
    ZAxis: 'ZAxis',
    LeftValue: 'RightValueParsed',
    RightValue: 'RightValueParsed',
    RelativeDifference: 'RelativeDifference',
    DifferencePercent: 'RelativeDifferencePercent',
  },
  filter: {
    Id: 'Id',
    TableCode: 'TableCode',
    RowCode: 'RowCode',
    ColumnCode: 'ColumnCode',
    ZAxis: 'ZAxis',
    LeftValue: 'RightValueParsed',
    RightValue: 'RightValueParsed',
    RelativeDifference: 'Difference', // business requirement
    DifferencePercent: 'RelativeDifferencePercent',
  },
};

const isNumber = (v: any) => {
  if (typeof v === 'number') return true;

  if (v === '') return false;
  const casted = Number(v);
  return casted != Number.NaN;
};

function mapGridFilterToSql(
  eb: ExpressionBuilder<Database, TableName>,
  filter: GridFilter,
): ExpressionWrapper<Database, `comparison_rows_${string}`, SqlBool> | undefined {
  const field = DisplayedRow.keyof().parse(filter.field);
  const column = DisplayedRowTableMapping.filter[field];

  return match(filter)
    .with({ operator: FilterOperator.Contains }, (f) => eb(column, 'ilike', `%${String(f.value)}%`))
    .with({ operator: FilterOperator.DoesNotContain }, (f) => eb(column, 'not like', `%${String(f.value)}%`))
    .with({ operator: FilterOperator.StartsWith }, (f) => eb(column, 'ilike', `${String(f.value)}%`))
    .with({ operator: FilterOperator.DoesNotStartWith }, (f) => eb(column, 'not like', `${String(f.value)}%`))
    .with({ operator: FilterOperator.EndsWith }, (f) => eb(column, 'ilike', `%${String(f.value)}`))
    .with({ operator: FilterOperator.DoesNotEndWith }, (f) => eb(column, 'not like', `%${String(f.value)}`))
    .with({ operator: FilterOperator.EqualTo }, (f) => eb(column, '=', f.value))
    .with({ operator: FilterOperator.GreaterThan, value: P.when(isNumber) }, (f) => eb(column, '>', Number(f.value)))
    .with({ operator: FilterOperator.GreaterThanOrEqual, value: P.when(isNumber) }, (f) =>
      eb(column, '>=', Number(f.value)),
    )
    .with({ operator: FilterOperator.IsEmpty }, (f) => eb(column, '=', ''))
    .with({ operator: FilterOperator.IsNotEmpty }, (f) => eb(column, '!=', ''))
    .with({ operator: FilterOperator.IsNotNull }, (f) => eb(column, 'is not', null))
    .with({ operator: FilterOperator.IsNull }, (f) => eb(column, 'is', null))
    .with({ operator: FilterOperator.LessThan, value: P.when(isNumber) }, (f) => eb(column, '<', Number(f.value)))
    .with({ operator: FilterOperator.LessThanOrEqual, value: P.when(isNumber) }, (f) =>
      eb(column, '<=', Number(f.value)),
    )
    .with({ operator: FilterOperator.NotEqualTo }, (f) => eb(column, '!=', f.value))
    .otherwise(() => undefined);
}

function mapCompositeFilter(eb: ExpressionBuilder<Database, TableName>, compositeFilter: CompositeGridFilter) {
  const filters = compositeFilter.filters
    .map((f) => {
      return match(f)
        .with({ logic: P.string }, (f) => mapCompositeFilter(eb, f as CompositeGridFilter))
        .otherwise((f) => mapGridFilterToSql(eb, f as GridFilter));
    })
    .filter(Boolean);

  return match(compositeFilter)
    .with({ logic: 'or' }, (cf) => eb.or(filters))
    .with({ logic: 'and' }, (cf) => eb.and(filters))
    .exhaustive();
}

export function createQuery(db: Kysely<Database>, comparisonId: string, filters: Filters) {
  const tableName = createTableName(comparisonId);

  const qb = db
    .selectFrom(tableName)
    .$if(!!filters.filter, (qb) => qb.where((eb) => mapCompositeFilter(eb, filters.filter!)));

  const selectQuery = qb
    .selectAll()
    .offset(filters.offset)
    .limit(filters.limit)
    .orderBy(DisplayedRowTableMapping.sort[filters.sort], filters.direction)
    .orderBy('TableCode', 'asc')
    .orderBy('RowCode', 'asc')
    .orderBy('ColumnCode', 'asc');

  const countQuery = qb.select((eb) => eb.fn.countAll().as('count'));

  return {
    select: selectQuery.compile(),
    count: countQuery.compile(),
  };
}
