import React from 'react';
import Breadcrumbs from '~/app/components/core/breadcrumbs/index';
import ErrorPopup from '~/app/components/core/popup/api-error';
import { manageClientPath, manageClientsPagePath, manageClientsPath } from '~/app/constants/url/auditor';
import { actions, selectors } from '~/data/index.js';
import { Redirect, Route, Switch } from 'react-router-dom';
import ClientRoute from './client/ClientRouter';
import ClientsRoute from './clients/Clients';
import { useAppDispatch, useAppSelector } from '~/data/utils/hooks';

export const ClientsContainer = () => {
  const dispatch = useAppDispatch();

  const apiSuccess = useAppSelector(selectors.api.getSuccess);
  const apiError = useAppSelector(selectors.api.getError);

  const resetApi = () => dispatch(actions.api.reset());

  return (
    <>
      <Breadcrumbs.Anchor
        path={manageClientsPath}
        link={manageClientsPath}
        component={<span title="Clients">Clients</span>}
      />
      <Switch>
        <Route path={manageClientsPagePath(':page([1-9]\\d*)')} component={ClientsRoute} />
        <Route path={manageClientPath(':clientId')} component={ClientRoute} />
        <Redirect from={manageClientsPath} to={manageClientsPagePath(1)} />
      </Switch>

      {!apiSuccess && <ErrorPopup error={apiError} closeFunction={() => resetApi()} />}
    </>
  );
};
