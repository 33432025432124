import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  CircularProgress,
  MenuItem,
  Skeleton,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepContent,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import Select from '@mui/material/Select';
import {
  reportKeys,
  useGetReport,
  useGetReportingEngineProgress,
  useGetVersions,
  useListReportFileUploadProgresses,
  useListVersionFilesReadyForProcessing,
  useLockReport,
  useReportLogs,
  useStartReportWorkflow,
} from '~/data/reports/queries';
import { useHistory, useParams } from 'react-router-dom';
import { scopeTypeMapper } from '../../reports/UploadReportModal';
import dayjs from 'dayjs';
import {
  CountryCode,
  ReportingEngineStatus,
  ReportLifecycleStatus,
  VersionRepresentation,
  VersionStatus,
} from '~/data/openapi-client';
import {
  reportDashboardPath,
  reportDataCheckPath,
  reportFileUploadPath,
  reportVersionReadonlyCheckPath,
} from '~/app/constants/url/auditor';
import RevertOutlineIcon from '~/ui/components/icon/icons/24px/revert-outline.svg';
import DocumentsOutlineIcon from '~/ui/components/icon/icons/documents.svg';
import { useQueryClient } from '@tanstack/react-query';
import cx from '~/data/utils/helpers/cx';
import HistoryDialog from './HistoryDialog';
import { Match, Switch } from '~/app/components/core/switch';
import sortBy from 'lodash/sortBy';
import { Show } from '~/app/components/core/show';
import secondsToMilliseconds from 'date-fns/esm/secondsToMilliseconds';
import { AxiosError } from 'axios';
import AttachFileSharpIcon from '@mui/icons-material/AttachFileSharp';
import { logger } from '~/logger';
import { useGetClient } from '~/data/clients/queries';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import UploadedFilesDialog from './UploadedFilesDialog';

export enum ReportSteps {
  RequestDocuments,
  ReviewEngine,
  ThirdStep,
  LockReport,
}

interface StepContentProps {
  disabled: boolean;
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#22992E',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#22992E',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#EAEAF0',
    borderLeftWidth: 2,
    height: 94,
    ...theme.applyStyles('dark', {
      borderColor: theme.palette.grey[800],
    }),
  },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { completed?: boolean } }>(({ theme }) => ({
  color: '#EAEAF0',
  display: 'flex',
  marginLeft: 7,
  alignItems: 'center',
  '& .QontoStepIcon-completedIcon': {
    color: '#784AF4',
    zIndex: 1,
  },
  '& .QontoStepIcon-circle': {
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  ...theme.applyStyles('dark', {
    color: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        color: '#22992E',
      },
    },
  ],
}));

function QontoStepIcon(props: StepIconProps) {
  const { completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ completed }} className={className}>
      <div className="QontoStepIcon-circle" />
    </QontoStepIconRoot>
  );
}

const ReportNext = () => {
  const history = useHistory();
  const { clientId = '', reportId = '' } = useParams<{ clientId?: string; reportId?: string }>();
  const [step, setStep] = useState(ReportSteps.ReviewEngine);
  const [currentVersionId, setCurrentVersionId] = useState<string | undefined>(undefined);
  const [showHistoryLog, setShowHistoryLog] = useState(false);
  const [showFileDetails, setShowFileDetails] = useState(false);

  const { data: client } = useGetClient(clientId);

  const { data: latestVersion, isInitialLoading: isLatestVersionLoading } = useGetVersions(
    clientId,
    reportId,
    'latest',
    {
      refetchInterval: secondsToMilliseconds(15),
    },
  );

  const { mutate: lockReport } = useLockReport(clientId, reportId);

  const { data: selectedVersion } = useGetVersions(clientId, reportId, currentVersionId ?? '', {
    refetchInterval: secondsToMilliseconds(10),
  });

  const { data: filesReadyForProcessing } = useListVersionFilesReadyForProcessing(
    clientId,
    reportId,
    latestVersion?.id!,
    {
      enabled: !!latestVersion?.id,
      refetchInterval: secondsToMilliseconds(10),
    },
  );

  const { data: fileUploadProgresses } = useListReportFileUploadProgresses(clientId, reportId, {
    refetchInterval: secondsToMilliseconds(5),
  });

  const { data: reportEngineProgress } = useGetReportingEngineProgress(clientId, reportId, {
    refetchInterval: secondsToMilliseconds(10),
  });

  const isCurrentVersionLatestProcessing = currentVersionId === reportEngineProgress?.latestProcessedVersionId;

  const [reviewing, setReviewing] = useState(false);

  const queryClient = useQueryClient();

  const { data: report, isInitialLoading: isReportLoading } = useGetReport(clientId, reportId);
  const { data: reportLogs } = useReportLogs(clientId, reportId, {
    limit: 1,
    sort: 'Timestamp',
    direction: 'DESC',
  });

  const { mutate: startWorkflow } = useStartReportWorkflow(clientId, reportId, {
    onError(error) {
      setReviewing(false);
      if (error instanceof AxiosError) {
        logger.error(error, 'Failed to start the workflow for version %s', selectedVersion?.id);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: reportKeys.version(clientId, reportId, 'latest') });
      queryClient.invalidateQueries({ queryKey: reportKeys.reportingEngineProgress(clientId, reportId) });
    },
  });

  const isReportLocked = report?.lifecycleStatus === ReportLifecycleStatus.Locked;

  const latestLog = reportLogs?.items[0];

  const reportVersions: VersionRepresentation[] = sortBy(report?.versions, (v) => v.order);

  useEffect(() => {
    if (latestVersion?.id) {
      setCurrentVersionId(latestVersion?.id);
      queryClient.invalidateQueries({ queryKey: reportKeys.report(clientId, reportId) });
    }
  }, [latestVersion?.id]);

  const isLatestVersionSelected = currentVersionId === latestVersion?.id;

  const versionOptions = report
    ? reportVersions.map((v) => ({
        value: v.id ?? undefined,
        label: `${report?.scopeType ? scopeTypeMapper[report.scopeType] : ''} - ${dayjs(report.periodEnd).format(
          'DD-MM-YYYY',
        )} V${v.order}`,
      }))
    : [];

  useEffect(() => {
    if (!isLatestVersionSelected && !isCurrentVersionLatestProcessing) {
      setReviewing(false);
      setStep(ReportSteps.LockReport);
      return;
    }
    if (
      !isCurrentVersionLatestProcessing ||
      !reportEngineProgress ||
      reportEngineProgress.status === ReportingEngineStatus.Idle
    ) {
      setReviewing(false);
      setStep(ReportSteps.ReviewEngine);
      return;
    }
    if (reportEngineProgress.status === ReportingEngineStatus.Processing) {
      setReviewing(true);
      setStep(ReportSteps.ReviewEngine);
      return;
    }
    if (reportEngineProgress.status === ReportingEngineStatus.Success) {
      setReviewing(false);
      setStep(ReportSteps.LockReport);
      return;
    }
    if (reportEngineProgress.status === ReportingEngineStatus.Error) {
      setReviewing(false);
      setStep(ReportSteps.ReviewEngine);
    }
  }, [reportEngineProgress, isCurrentVersionLatestProcessing, isLatestVersionSelected]);

  const steps = useMemo(
    () => [
      {
        value: ReportSteps.RequestDocuments,
        label: 'Request documents',
        Content: ({ disabled }: StepContentProps) => (
          <div className="mt-1 absolute">
            <Typography className="text-sm mb-2">Version files: {selectedVersion?.files?.length ?? 0}</Typography>
            <Button
              size="small"
              variant="contained"
              disabled={disabled || isReportLocked}
              onClick={() => {
                history.push(reportFileUploadPath(clientId, reportId));
              }}
            >
              Upload more documents
            </Button>
          </div>
        ),
      },
      {
        value: ReportSteps.ReviewEngine,
        label: 'Review engine',
        Content: ({ disabled }: StepContentProps) => (
          <div className="absolute">
            <div className="flex flex-col">
              <div
                className={cx(
                  isCurrentVersionLatestProcessing &&
                    reportEngineProgress?.status === ReportingEngineStatus.Error &&
                    'text-red',
                  isCurrentVersionLatestProcessing &&
                    reportEngineProgress?.status === ReportingEngineStatus.Success &&
                    'text-success',
                )}
              >
                <Typography fontSize={12} className="text-inherit">
                  <Switch>
                    <Match when={isCurrentVersionLatestProcessing}>
                      {reportEngineProgress?.status === ReportingEngineStatus.Success ||
                      reportEngineProgress?.status === ReportingEngineStatus.Error
                        ? 'Files has been processed'
                        : 'Files processing'}
                      : {selectedVersion?.actualFileCount ?? reportEngineProgress?.fileCount ?? 0}
                    </Match>
                    <Match when={!isCurrentVersionLatestProcessing && isLatestVersionSelected}>
                      Files ready for processing: {filesReadyForProcessing?.length ?? 0}
                    </Match>
                    <Match when={!isCurrentVersionLatestProcessing && selectedVersion?.status !== VersionStatus.Draft}>
                      Files has been processed:{' '}
                      {selectedVersion?.actualFileCount ?? selectedVersion?.files?.length ?? 0}
                    </Match>
                  </Switch>
                </Typography>
                <Typography fontSize={12} className="text-inherit flex items-center gap-[]">
                  <Switch>
                    <Match when={isReportLocked}>Status: {selectedVersion?.status}</Match>
                    <Match when={isLatestVersionSelected}>
                      Status:{' '}
                      {isCurrentVersionLatestProcessing
                        ? reportEngineProgress?.status ?? ReportingEngineStatus.Idle
                        : ReportingEngineStatus.Idle}
                    </Match>
                    <Match when={!isLatestVersionSelected}>
                      Status:{' '}
                      {isCurrentVersionLatestProcessing ? reportEngineProgress?.status : selectedVersion?.status}
                    </Match>
                  </Switch>
                  <Show when={!!selectedVersion?.statusInfo?.isFailed}>
                    <Tooltip title={selectedVersion?.statusInfo?.errorMessage ?? selectedVersion?.statusInfo?.message}>
                      <InfoOutlinedIcon className="text-sm" fontSize="inherit" />
                    </Tooltip>
                  </Show>
                </Typography>
              </div>
              <Button
                size="small"
                variant="contained"
                disabled={
                  disabled ||
                  isReportLocked ||
                  reviewing ||
                  !filesReadyForProcessing?.length ||
                  !isLatestVersionSelected ||
                  (isCurrentVersionLatestProcessing
                    ? reportEngineProgress?.status !== ReportingEngineStatus.Idle
                    : selectedVersion?.status !== VersionStatus.Draft)
                }
                onClick={() => {
                  setReviewing(true);
                  startWorkflow({});
                }}
              >
                Start analysis
              </Button>
            </div>
          </div>
        ),
      },
      {
        value: ReportSteps.ThirdStep,
        label: client?.countryCode === CountryCode.Za ? 'Dashboard' : 'Check reviews',
        Content: ({ disabled }: StepContentProps) => (
          <Show
            when={client?.countryCode !== CountryCode.Za}
            fallback={
              <div className="absolute">
                <Button
                  size="small"
                  variant="contained"
                  disabled={disabled}
                  onClick={() => {
                    history.push(reportDashboardPath(clientId, reportId));
                  }}
                >
                  Dashboard
                </Button>
              </div>
            }
          >
            <div className="absolute">
              <Button
                size="small"
                variant="contained"
                disabled={disabled || !selectedVersion?.validationChecksCount}
                onClick={() => {
                  if (isLatestVersionSelected) {
                    history.push(reportDataCheckPath(clientId, reportId));
                    return;
                  }
                  if (selectedVersion?.id) {
                    history.push(reportVersionReadonlyCheckPath(clientId, reportId, selectedVersion.id));
                  }
                }}
              >
                Review checks
              </Button>
            </div>
          </Show>
        ),
      },
      {
        value: ReportSteps.LockReport,
        label: 'Lock report',
        Content: ({ disabled }: StepContentProps) => (
          <div>
            <Button
              size="small"
              variant="contained"
              className="absolute"
              disabled={disabled || !isLatestVersionSelected || isReportLocked}
              onClick={() => {
                lockReport(
                  {},
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries({ queryKey: reportKeys.report(clientId, reportId) });
                    },
                  },
                );
              }}
            >
              Lock report
            </Button>
          </div>
        ),
      },
    ],
    [
      selectedVersion?.id,
      selectedVersion?.status,
      reviewing,
      filesReadyForProcessing,
      isCurrentVersionLatestProcessing,
      reportEngineProgress,
    ],
  );

  return (
    <div className="flex min-h-[max(calc(100vh_-_240px),450px)]">
      <div className="w-[376px] bg-[#E9E9E9] p-4">
        <div className="flex flex-col gap-6">
          {isReportLoading || isLatestVersionLoading ? (
            <Skeleton variant="rectangular" className="h-[45px]" />
          ) : (
            <Select
              className="w-full"
              value={currentVersionId ?? latestVersion?.id}
              onChange={(e) => {
                setCurrentVersionId(e.target.value);
              }}
            >
              {versionOptions?.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}

          <div className="flex flex-col gap-1">
            <Typography className="text-medium flex items-center gap-1">
              <SvgIcon component={RevertOutlineIcon} fontSize="inherit" />
              Review History
            </Typography>
            <div className="ml-6 flex flex-col gap-1">
              <Show when={!!latestLog?.actor && !!latestLog?.timestamp}>
                <>
                  <Typography fontSize={14} className="text-medium">
                    <div>
                      {`Last edited by ${
                        latestLog?.actor.type === 'User'
                          ? JSON.parse(latestLog.actor.properties ?? '').Email
                          : latestLog?.actor.type
                      }`}
                    </div>
                    <div className="text-xs">
                      on {Intl.DateTimeFormat().format(new Date(latestLog?.timestamp ?? 0))} at{' '}
                      {dayjs(new Date(latestLog?.timestamp ?? 0)).format('HH:mm')}
                    </div>
                  </Typography>
                </>
              </Show>
              <Typography
                fontSize={14}
                role="button"
                className="underline text-orange"
                onClick={() => setShowHistoryLog(true)}
              >
                View History Logs
              </Typography>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <Typography className="text-medium flex items-center gap-1">
              <DocumentsOutlineIcon height={18} width={18} />
              Documents
            </Typography>
            <div className="ml-6 flex flex-col gap-2">
              <ol className="pl-4 text-orange">
                {fileUploadProgresses?.map((file) => (
                  <li key={file.name} className="list-decimal break-all">
                    <Typography className="text-sm">
                      <Show when={file.status == 'Processing'}>
                        <CircularProgress size="0.87em" className="mr-1 align-middle" />
                      </Show>
                      {file.name}
                    </Typography>
                  </li>
                ))}
              </ol>
              <Button
                className="w-fit"
                variant="contained"
                size="small"
                onClick={() => {
                  history.push(reportFileUploadPath(clientId, reportId));
                }}
                disabled={isReportLocked}
              >
                Upload new files
              </Button>
              <Button
                className="w-fit"
                variant="contained"
                size="small"
                onClick={() => {
                  setShowFileDetails(true);
                }}
              >
                Details
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white grow px-10 py-4 relative">
        <Stepper orientation="vertical" activeStep={step} connector={<QontoConnector />}>
          {steps.map(({ label, value, Content }) => (
            <Step key={value} active={step >= value}>
              <StepLabel
                classes={{ disabled: 'text-[#DEDEDE]', label: 'text-xl !font-normal font-primary' }}
                className="p-0"
                StepIconComponent={QontoStepIcon}
              >
                {label}
              </StepLabel>
              <StepContent>
                <Content disabled={step < value} />
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </div>
      <HistoryDialog open={showHistoryLog} handleClose={() => setShowHistoryLog(false)} />
      <UploadedFilesDialog
        open={showFileDetails}
        onClose={() => setShowFileDetails(false)}
        versionId={currentVersionId ?? ''}
      />
    </div>
  );
};

export default ReportNext;
