import { useQuery } from '@tanstack/react-query';
import { dazzler } from '../dazzler-api';
import { queryKeyBuilder } from '../utils/helpers/query-key.builder';
import { PowerBiExplanationSection, ScopeType } from '../openapi-client';
import minutesToMilliseconds from 'date-fns/fp/minutesToMilliseconds';

export const powerBiExplanationKeys = (() => {
  const base = 'power-bi-explanation';
  return {
    base: () => queryKeyBuilder(base),
    getSections: (scopeType: ScopeType) => queryKeyBuilder(base, scopeType, 'getPowerBiExplanationSections'),
  };
})();

export const useGetPowerBiExplanationSections = (
  scopeType: ScopeType,
  options?: Omit<PowerBiExplanationSection[], 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    queryFn: () => dazzler.powerBiExplanation.getSections({ scopeType }).then((res) => res.data),
    queryKey: powerBiExplanationKeys.getSections(scopeType),
    staleTime: minutesToMilliseconds(15),
    enabled: !!scopeType,
    ...options,
  });
