/* eslint-disable no-console */
import * as constants from '~/data/constants/clients';
import * as selectors from '~/data/selectors/clients';
import { saveBlob } from '~/data/utils/helpers';
import type { ThunkArguments } from '~/data/store';

export const fetchClients =
  (filter) =>
  async (dispatch, _, { api, schema, normalize }: ThunkArguments) => {
    const newFilter = filter;
    if (newFilter) {
      Object.keys(newFilter).forEach((key) => {
        if (newFilter[key] === undefined) {
          delete newFilter[key];
        }
      });
    }
    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const res = await api.get(`clients`, {
        params: newFilter,
        baseURL: api.prefixedURL,
      });
      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        count: res.data.count,
        payload: normalize(res.data.items, [schema.client]),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
    }
  };

export const updateClients =
  () =>
  async (dispatch, _, { api }: ThunkArguments) => {
    dispatch({ type: constants.LIST_UPDATE_START });
    try {
      await api.patch(`clients`, undefined, {
        baseURL: api.prefixedURL,
      });
      dispatch({
        type: constants.LIST_UPDATE_SUCCESS,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LIST_UPDATE_FAILED });
    }
  };

export const fetchClient =
  (clientId) =>
  async (dispatch, _, { api, schema, normalize }: ThunkArguments) => {
    dispatch({ type: constants.LOAD_START });
    try {
      const res = await api.get(`clients/${clientId}`, {
        baseURL: api.prefixedURL,
      });

      dispatch({
        type: constants.LOAD_SUCCESS,
        payload: normalize(res.data, schema.client),
      });
    } catch (e) {
      dispatch({ type: constants.LOAD_FAILED });
    }
  };

export const editClient =
  (values, actions) =>
  async (dispatch, _, { api, schema, normalize }: ThunkArguments) => {
    dispatch({ type: constants.UPDATE_START });
    actions.setSubmitting(true);
    try {
      const res = await api.patch(`clients/${values.clientId}`, values, {
        baseURL: api.prefixedURL,
      });
      actions.setSubmitting(false);
      actions.setStatus(values.clientId);

      dispatch({
        type: constants.UPDATE_SUCCESS,
        payload: normalize(res.data, schema.client),
      });

      return res.data;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.UPDATE_FAILED });
      actions.setSubmitting(false);

      throw e;
    }
  };

export const fetchDashboardUrl =
  (clientId, scope) =>
  async (dispatch, _, { api }: ThunkArguments) => {
    dispatch({ type: constants.DASHBOARD_LOAD_START });
    try {
      const res = await api.get(`clients/${clientId}/dashboards/${scope}`, {
        baseURL: api.prefixedURL,
      });
      dispatch({
        type: constants.DASHBOARD_LOAD_SUCCESS,
        payload: res.data,
      });

      return res.data;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.DASHBOARD_LOAD_FAILED });
      throw e;
    }
  };

export const startDownloadRequest =
  (clientId, scope) =>
  async (dispatch, _, { api }: ThunkArguments) => {
    dispatch({ type: constants.DOWNLOAD_REQUEST_START });

    try {
      const res = await api.post(`/clients/${clientId}/dashboards/${scope}/downloads`, undefined, {
        baseURL: api.prefixedURL,
      });

      dispatch({
        type: constants.DOWNLOAD_REQUEST_SUCCESS,
        payload: {
          reference: res.data.reference,
        },
      });

      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.DOWNLOAD_REQUEST_FAILED });
      return false;
    }
  };

export const pollDownloadStatus =
  (clientId, scope) =>
  async (dispatch, getState, { api }: ThunkArguments) => {
    dispatch({ type: constants.POLL_STATUS_START });

    try {
      const reference = selectors.getReference(getState());
      const res = await api.get(`/clients/${clientId}/dashboards/${scope}/downloads/${reference}/status`, {
        baseURL: api.prefixedURL,
      });

      dispatch({
        type: constants.POLL_STATUS_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.POLL_STATUS_FAILED });
      return false;
    }
  };

export const downloadReport =
  (clientId, scope, fileName) =>
  async (dispatch, getState, { api }: ThunkArguments) => {
    dispatch({ type: constants.REPORT_DOWNLOAD_START });

    try {
      const reference = selectors.getReference(getState());
      const { data: blob } = await api.get(`/clients/${clientId}/dashboards/${scope}/downloads/${reference}`, {
        responseType: 'blob',
        baseURL: api.prefixedURL,
      });

      saveBlob(blob, fileName);
      dispatch({
        type: constants.REPORT_DOWNLOAD_SUCCESS,
      });
      return true;
    } catch (e) {
      console.error(e);

      const reader = new FileReader();

      reader.onload = () => {
        dispatch({ type: constants.REPORT_DOWNLOAD_FAILED, payload: reader.result });
      };

      reader.readAsText(e.response.data);
      return false;
    }
  };

export const clearDownloadingProgress = () => ({ type: constants.CLEAR_DOWNLOADING_PROGRESS });

export const clear = () => ({ type: constants.CLEAR });

export const clearSingle = () => ({ type: constants.CLEAR_SINGLE });
