const prefix = (type) => `dazzler/internal/${type}`;

export const UPDATE_START = prefix('UPDATE_START');
export const UPDATE_SUCCESS = prefix('UPDATE_SUCCESS');
export const UPDATE_FAILED = prefix('UPDATE_FAILED');

export const LOG_FETCH_START = prefix('LOG_FETCH_START');
export const LOG_FETCH_SUCCESS = prefix('LOG_FETCH_SUCCESS');
export const LOG_FETCH_FAILED = prefix('LOG_FETCH_FAILED');

export const JOB_FETCH_START = prefix('JOB_FETCH_START');
export const JOB_FETCH_SUCCESS = prefix('JOB_FETCH_SUCCESS');
export const JOB_FETCH_FAILED = prefix('JOB_FETCH_FAILED');

export const DOWNLOAD_USAGE_START = prefix('DOWNLOAD_USAGE_START');
export const DOWNLOAD_USAGE_SUCCESS = prefix('DOWNLOAD_USAGE_SUCCESS');
export const DOWNLOAD_USAGE_FAILED = prefix('DOWNLOAD_USAGE_FAILED');

export const CLEAR = prefix('CLEAR');
