import * as rowConstants from '~/data/constants/comparisonrows';
import * as constants from '~/data/constants/comparisons';
import type { ThunkArguments } from '~/data/store';

export const createComparison =
  (fileId) =>
  async (dispatch, getState, { api }: ThunkArguments) => {
    dispatch({ type: constants.CREATE_START });

    try {
      const res = await api.post(`/comparisons`, { fileId }, { baseURL: api.prefixedURL });
      if (res.status !== 200) throw new Error(res.statusText);

      dispatch({
        type: constants.CREATE_SUCCESS,
      });

      return res;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.CREATE_FAILED });

      throw e;
    }
  };

export const fetchComparison =
  (comparisonId) =>
  async (dispatch, _, { api, schema, normalize }: ThunkArguments) => {
    dispatch({ type: constants.LOAD_START });
    try {
      const res = await api.get(`/comparisons/${comparisonId}`, { baseURL: api.prefixedURL });

      dispatch({
        type: constants.LOAD_SUCCESS,
        payload: normalize(res.data, schema.comparison),
      });
    } catch (e) {
      dispatch({ type: constants.LOAD_FAILED });
    }
  };

export const fetchComparisonRows =
  (comparisonId, filter) =>
  async (dispatch, _, { api, schema, normalize }: ThunkArguments) => {
    const newFilter = filter;
    if (newFilter) {
      Object.keys(newFilter).forEach((key) => {
        if (
          newFilter[key] === undefined ||
          newFilter[key] === false ||
          newFilter[key] === '' ||
          newFilter[key] === null ||
          (Number.isNaN(Number(newFilter[key])) && newFilter[key]?.length < 3)
        ) {
          delete newFilter[key];
        }
      });
    }

    dispatch({ type: rowConstants.LIST_LOAD_START });
    try {
      const res = await api.get(`comparisons/${comparisonId}/rows`, {
        params: newFilter,
        baseURL: api.prefixedURL,
      });
      const { count, totalCount, items } = res.data;
      dispatch({
        type: rowConstants.LIST_LOAD_SUCCESS,
        count,
        totalCount,
        payload: normalize(items, [schema.comparisonrow]),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: rowConstants.LIST_LOAD_FAILED });
    }
  };

export const clear = () => ({ type: constants.CLEAR });

export const clearSingle = () => ({ type: constants.CLEAR_SINGLE });

export const clearRows = () => ({ type: rowConstants.CLEAR });
