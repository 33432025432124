import React from 'react';
import Typography from '@mui/material/Typography';
import Row from '~/ui/components/grid/row';
import { useListReportDownloadOptions } from '~/data/reports/queries';
import { useParams } from 'react-router-dom';
import { DownloadCard } from '~/app/components/pages/auditor/clients/client/report/[reportId]/downloads/card';

function DownloadsPage() {
  const { clientId = '', reportId = '' } = useParams<{ clientId?: string; reportId?: string }>();
  const { data: downloadsList } = useListReportDownloadOptions(clientId, reportId, {
    enabled: !!clientId && !!reportId,
    staleTime: 5 * 1000,
  });

  return (
    <div>
      <Row>
        <Typography fontSize={24} component="h1" className="text-dark">
          Downloads
        </Typography>
      </Row>
      <ul className="flex gap-8 w-full justify-center mt-12">
        {downloadsList?.map((item) => (
          <li key={item.name}>
            <DownloadCard
              name={item.name!}
              details={item.details!}
              status={item.status!}
              downloadURI={item.downloadURI!}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default DownloadsPage;
