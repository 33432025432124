export class HTTPError extends Error {
  localStatus: number;

  constructor(message, status = 400) {
    super(message);
    this.localStatus = status;
    if (typeof Error.captureStackTrace === 'function' && Error.stackTraceLimit !== 0) {
      Error.captureStackTrace(this, this.constructor);
    }
  }

  status(status) {
    this.localStatus = status;
    return this;
  }
}

export class ConnectionError extends HTTPError {
  constructor(message) {
    super(message);
    this.localStatus = 504;
    if (typeof Error.captureStackTrace === 'function' && Error.stackTraceLimit !== 0) {
      Error.captureStackTrace(this, this.constructor);
    }
  }

  status(status) {
    this.localStatus = status;
    return this;
  }
}
