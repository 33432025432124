export const getState = (store) => store.api;

export const getUnauthorized = (store) => getState(store).get('unauthorized');
export const getServerError = (store) => getState(store).get('serverError');
export const getNotFound = (store) => getState(store).get('notFound');
export const getConnectionError = (store) => getState(store).get('connectionError');
export const getServiceUnavailable = (store) => getState(store).get('serviceUnavailable');
export const getUnexpectedError = (store) => getState(store).get('unexpectedError');
export const getSuccess = (store) =>
  !getServerError(store) &&
  !getNotFound(store) &&
  !getConnectionError(store) &&
  !getServiceUnavailable(store) &&
  !getUnexpectedError(store);
export const getError = (store) => getState(store).get('error');
export const getForbidden = (store) => getState(store).get('forbidden');
