const prefix = (type) => `dazzler/imports/${type}`;

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const UPLOAD_START = prefix('UPLOAD_START');
export const UPLOAD_SUCCESS = prefix('UPLOAD_SUCCESS');
export const UPLOAD_FAILED = prefix('UPLOAD_FAILED');

export const DOWNLOAD_START = prefix('DOWNLOAD_START');
export const DOWNLOAD_SUCCESS = prefix('DOWNLOAD_SUCCESS');
export const DOWNLOAD_FAILED = prefix('DOWNLOAD_FAILED');

export const CLEAR = prefix('CLEAR');

export const CLEAR_SINGLE = prefix('CLEAR_SINGLE');
