import { AnyPropsType } from '~/AnyPropsType';
import React from 'react';

import BreadcrumbsAnchor from './anchor';
import BreadcrumbsProvider from './provider';
import BreadcrumbsMUI from '@mui/material/Breadcrumbs';
import { Show } from '~/app/components/core/show';
import { BreadcrumbsProps, Typography } from '@mui/material';
import { itemProps } from '~/app/components/core/breadcrumbs';

const createBreadcrumbs = () => {
  const Context = React.createContext({});

  const withContext =
    (Component) =>
    ({ ...props }) =>
      <Component context={Context} {...props} />;

  const Breadcrumbs = ({
    components,
    ...props
  }: BreadcrumbsProps & {
    components: {
      Item: ({ item }: itemProps) => JSX.Element;
    };
  }) => {
    const { Item } = components;

    return (
      <Context.Consumer>
        {(value: any) => (
          <BreadcrumbsMUI aria-label="breadcrumb" {...props}>
            {value.routes.map((item, i, arr) => (
              <Show
                when={arr.length > i + 1}
                fallback={
                  <Typography fontSize={16} className="text-medium">
                    {item.component}
                  </Typography>
                }
              >
                <Item item={item} />
              </Show>
            ))}
          </BreadcrumbsMUI>
        )}
      </Context.Consumer>
    );
  };

  return {
    Provider: withContext(BreadcrumbsProvider),
    Anchor: withContext(BreadcrumbsAnchor),
    Breadcrumbs,
  };
};

export default createBreadcrumbs;
