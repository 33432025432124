import * as constants from '~/data/constants/taxonomies';
import type { ThunkArguments } from '~/data/store';

export const fetchTaxonomies =
  () =>
  async (dispatch, _, { api }: ThunkArguments) => {
    try {
      dispatch({ type: constants.LIST_LOAD_START });
      const res = await api.get(`taxonomies`, { baseURL: api.prefixedURL });

      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        payload: res.data.items,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
    }
  };
