import { useMutation, type UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import hoursToMilliseconds from 'date-fns/hoursToMilliseconds';
import { queryKeyBuilder } from '~/data/utils/helpers/query-key.builder';
import { ClientComparisonApiAxiosParamCreator } from '~/data/openapi-client/index';
import { api, apiConfig, dazzlerConfiguration } from '~/data/dazzler-api';
import ky from 'ky';
import {
  type ComparisonRow,
  countComparisons,
  createQuery,
  type Direction,
  type Filters,
  listComparisons,
  registerComparisonFile,
} from './db';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { ListRepresentation } from '~/data/utils/types';
import { db } from '~/db';
import { fromThrowable } from 'neverthrow';
import { FilterOperator } from '@progress/kendo-data-query';
import { z } from 'zod';
import type { CompositeFilterDescriptor } from '@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface';
import { match, P } from 'ts-pattern';
import { connection } from '~/duckdb';

export const downloadComparisonRowsKey = (clientId: string, comparisonId: string) =>
  queryKeyBuilder('comparison-rows', 'client', clientId, 'comparisons', comparisonId, 'db-parquet-rows');

export const useDownloadComparisonRows = (
  clientId: string,
  comparisonId: string,
  options?: Omit<UseQueryOptions<Uint8Array>, 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    queryKey: downloadComparisonRowsKey(clientId, comparisonId),
    queryFn: async function downloadComparisonRows({ signal }) {
      const { url } = await ClientComparisonApiAxiosParamCreator(dazzlerConfiguration).downloadComparisonRowsParquet(
        clientId,
        comparisonId,
      );
      const endpoint = new URL(url, apiConfig.baseURL);
      const response = await ky(endpoint, {
        headers: {
          Authorization: api.getAuthorizationHeader() ?? '',
        },
        retry: {
          limit: 3,
        },
        signal,
      });

      const bytes = new Uint8Array(await response.arrayBuffer());
      await registerComparisonFile(connection, bytes, comparisonId);

      return bytes;
    },
    staleTime: hoursToMilliseconds(8),
    ...options,
  });

export const comparisonRowsQueryKey = (clientId: string, comparisonId: string, filters: Filters) =>
  queryKeyBuilder('comparison-rows', 'client', clientId, 'comparisons', comparisonId, 'rows', filters);

export function useFetchComparisonRows(
  clientId: string,
  comparisonId: string,
  filters: Filters,
  options?: Omit<
    UseQueryOptions<
      ListRepresentation<
        ComparisonRow & {
          RelativeDifference: number;
          RelativeDifferencePercent: number;
        }
      >
    >,
    'queryKey' | 'queryFn'
  >,
) {
  const query = createQuery(db, comparisonId, filters);

  return useQuery({
    queryKey: comparisonRowsQueryKey(clientId, comparisonId, filters),
    queryFn: async () => {
      const [items, count] = await Promise.all([
        listComparisons(connection, query.select),
        countComparisons(connection, query.count),
      ]);
      return {
        items,
        count,
      };
    },
    ...options,
  });
}

export const DisplayedRow = z.object({
  Id: z.string(),
  TableCode: z.string(),
  RowCode: z.string(),
  ColumnCode: z.string(),
  ZAxis: z.string(),
  LeftValue: z.string(),
  RightValue: z.string(),
  RelativeDifference: z.string().nullable(),
  DifferencePercent: z.string(),
});

export type DisplayedRow = z.infer<typeof DisplayedRow>;

/**
 * helper that verifies the types
 */
export function ComparisonField<T extends keyof DisplayedRow>(key: T) {
  return key;
}

const filterSchema = z.object({
  field: z.string(),
  operator: z.nativeEnum(FilterOperator),
  value: z.any().nullish(),
  ignoreCase: z.boolean().optional(),
});

export type GridFilter = z.infer<typeof filterSchema>;

export type CompositeGridFilter = { logic: 'or' | 'and'; filters: Array<GridFilter | CompositeGridFilter> };

const compositeFilterSchema: z.ZodType<CompositeGridFilter> = z.lazy(() =>
  z.object({
    logic: z.enum(['or', 'and']),
    filters: z.array(z.union([filterSchema, compositeFilterSchema])),
  }),
);

const serializeFilter = (filter?: CompositeFilterDescriptor) =>
  filter != null ? encodeURIComponent(JSON.stringify(filter)) : undefined;

export type Parameters = {
  offset: number;
  limit: number;
  sort: keyof DisplayedRow;
  direction: Direction;
  filter: CompositeGridFilter | undefined;
};

export type ParameterSetter = (parameters: {
  offset: number | undefined;
  limit: number | undefined;
  sort: string | undefined;
  direction: Direction | undefined;
  filter: CompositeFilterDescriptor | undefined;
}) => void;

export function useGridParameters(): [Parameters, ParameterSetter] {
  const [query, setQuery] = useQueryParams({
    offset: withDefault(NumberParam, 0),
    limit: withDefault(NumberParam, 10),
    sort: withDefault(StringParam, ComparisonField('TableCode')),
    direction: withDefault(StringParam, 'asc' satisfies Direction),
    filter: withDefault(StringParam, ''),
  });

  const filter = fromThrowable(decodeURIComponent)(query.filter)
    .andThen(fromThrowable(JSON.parse))
    .andThen(fromThrowable(compositeFilterSchema.parse))
    .unwrapOr(undefined);

  const setParameters: ParameterSetter = (params) => {
    setQuery({
      ...params,
      filter: serializeFilter(params.filter),
    });
  };

  return [
    {
      offset: query.offset || 0,
      limit: query.limit || 10,
      sort: (query.sort as keyof DisplayedRow) || ComparisonField('TableCode'),
      direction: (query.direction as Direction) || 'asc',
      filter,
    },
    setParameters,
  ];
}

export function toDisplayedRows(rows: ComparisonRow[], formatter: Intl.NumberFormat): Array<DisplayedRow> {
  return rows.map<DisplayedRow>((i) => ({
    Id: i.Id,
    TableCode: i.TableCode,
    RowCode: i.RowCode,
    ColumnCode: i.ColumnCode,
    ZAxis: i.ZAxis,
    LeftValue: i.LeftValueParsed != null ? formatter.format(i.LeftValueParsed) : i.LeftValueRaw,
    RightValue: i.RightValueParsed != null ? formatter.format(i.RightValueParsed) : i.RightValueRaw,
    RelativeDifference: i.RelativeDifference != null ? formatter.format(i.RelativeDifference) : null,
    DifferencePercent: match(i.RelativeDifferencePercent)
      .with(-Infinity, () => '-∞')
      .with(Infinity, () => '∞')
      .with(-0, () => '0%')
      .with(P.number, (n) => formatter.format(n) + '%')
      .otherwise(() => ''),
  }));
}

export function useDownloadDisplayedRows(
  options?: UseMutationOptions<
    void,
    Error,
    {
      comparisonId: string;
      formatter: Intl.NumberFormat;
      saveExcel: (rows: DisplayedRow[]) => void;
      filters: Filters;
    }
  >,
) {
  return useMutation({
    mutationFn: async function downloadDisplayedRows({ comparisonId, filters, saveExcel, formatter }) {
      filters.offset = 0;
      filters.limit = Infinity;
      const query = createQuery(db, comparisonId, filters);
      const items = await listComparisons(connection, query.select);

      saveExcel(toDisplayedRows(items, formatter));
    },
    ...options,
  });
}
