import React from 'react';
import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, DialogProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ModalProps extends DialogProps {
  closeIcon?: boolean;
  modalTitle?: string | React.ReactNode;
  children?: React.ReactNode;
  contentText?: string | React.ReactNode;
  onClose?: () => void;
}

const Modal = ({
  closeIcon = false,
  modalTitle = '',
  children,
  contentText,
  onClose,
  open = false,
  ...props
}: ModalProps) => (
  <Dialog
    scroll="body"
    open={open}
    PaperProps={{
      sx: {
        padding: 0,
      },
    }}
    {...props}
  >
    <DialogTitle data-cy="modalTitle">
      {closeIcon && (
        <IconButton
          data-cy="modalCloseIcon"
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      {modalTitle}
    </DialogTitle>

    <DialogContent data-cy="modalContent">
      <Box marginBottom="16px">
        <DialogContentText>{contentText}</DialogContentText>
      </Box>
      {children}
    </DialogContent>
  </Dialog>
);

export default Modal;
