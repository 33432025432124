import './global.css';
import React from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { muiTheme } from '~/ui/utils/MUItheme';

import { actions } from '~/data';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import store from '~/data/store';
import { logout } from '~/data/utils/helpers/logout';
import { refresh } from '~/data/utils/helpers/refresh';
import history from '~/app/history';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Dazzler from './app/components/pages';
import { intercomId } from './config';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import '~/app/styles/index.css';
import { QueryParamProvider } from 'use-query-params';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

store.dispatch(actions.auth.getInitialCredentials() as any);

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: AxiosError, query) => {
      if (!(error instanceof AxiosError)) return;

      const statusCode = error.response?.status;

      if (statusCode === 401) {
        const accessToken = window.sessionStorage.getItem('auth');
        const refreshToken = window.localStorage.getItem('refresh');

        if (typeof accessToken !== 'string' || !refreshToken) {
          logout();
          return;
        }
        try {
          refresh().then(() => {
            query.invalidate();
          });
        } catch (_: unknown) {
          logout();
        }
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry: (failureCount: number, error: AxiosError) => {
        if (error.response?.status === 401) {
          return false;
        }

        return (!error?.response?.status || error?.response?.status >= 500) && failureCount <= 3;
      },
      useErrorBoundary: (error: AxiosError) => {
        return (
          (!error?.response?.status && !error.message.match(/cancel/)) ||
          error?.response?.status === 404 ||
          Number(error?.response?.status) >= 500
        );
      },
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Provider store={store}>
          <Router history={history}>
            <QueryParamProvider adapter={ReactRouter5Adapter}>
              <IntercomProvider appId={intercomId} autoBoot apiBase="https://api-iam.eu.intercom.io">
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={muiTheme}>
                    <Dazzler />
                    <ToastContainer />
                  </ThemeProvider>
                </StyledEngineProvider>
              </IntercomProvider>
            </QueryParamProvider>
          </Router>
        </Provider>
      </LocalizationProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);
root.render(<App />);
