import { schema } from 'normalizr';

export const user = new schema.Entity('users');

export const report = new schema.Entity('reports');

export const comparison = new schema.Entity('comparisons');

export const comparisonrow = new schema.Entity('comparisonrows');

export const scope = new schema.Entity('scopes');

export const client = new schema.Entity('clients');

export const entity = new schema.Entity('entities');

export const file = new schema.Entity('files');

export const clientsAdmin = new schema.Entity('clientsAdmin');

export const ebaImport = new schema.Entity('imports');
