import { clientsPagePath } from '~/app/constants/url/auditor';
import { onFilterHelper } from '~/app/utils/filter-helpers';
import { onPaginationHelper } from '~/app/utils/pagination-helpers';
import { onSortingHelper } from '~/app/utils/sorting-helpers';
import { selectors, useDispatch, useSelector } from '~/data/index.js';
import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { clients } from '~/data/actions/index';
import { useDebounceValue } from '~/app/utils/debounce';
import ClientsTable from './ClientsTable';

const Clients = () => {
  const dispatch = useDispatch();
  const { page } = useParams<{ page: string }>();

  const clientsList = useSelector(selectors.clients.getItems);
  const isClientsLoaded = useSelector(selectors.clients.itemsLoaded);
  const totalItems = useSelector(selectors.clients.getTotalItems);

  const fetchClients = (filter) => dispatch(clients.fetchClients(filter));

  const [inputValue, setInputValue] = useState('');
  const [sort, setSort] = useState({ key: 'Name', direction: 'asc' });
  const [perPage, setPerPage] = useState(10);

  const debouncedSearchText = useDebounceValue(inputValue);

  const queryParams = {
    search: debouncedSearchText,
    sort: sort.key,
    direction: sort.direction,
    limit: perPage,
    offset: Number(page) > 1 ? (Number(page) - 1) * perPage : 0,
  };

  useEffect(() => {
    fetchClients(queryParams);
  }, [debouncedSearchText, sort, page, perPage]);

  const onSearch = (newSearch) => {
    setInputValue(newSearch);
    onFilterHelper('search', newSearch);
  };

  const onSort = (newSort) => {
    setSort(newSort);
    onSortingHelper(newSort.key, newSort.direction);
  };

  const onPagination = (e) => {
    setPerPage(e.target.value);
    onPaginationHelper(e.target);
  };

  if (isClientsLoaded && clientsList.size === 0 && Number(page) > 1) {
    return <Redirect to={clientsPagePath(Math.ceil(totalItems / perPage))} />;
  }

  return (
    <ClientsTable
      page={page || 1}
      perPage={perPage}
      clients={clientsList}
      onSearch={onSearch}
      onSort={onSort}
      search={inputValue}
      sort={sort.key}
      totalItems={totalItems}
      onPagination={onPagination}
      isClientsLoaded={isClientsLoaded}
    />
  );
};

export default Clients;
