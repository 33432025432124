import * as React from 'react';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import { Text } from '../text/index';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

interface ToastProps extends SnackbarProps {
  handleClose: () => void;
  type?: AlertColor;
}

const Toast = ({ open, handleClose, message, type = 'info', ...props }: ToastProps) => {
  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      open={open}
      autoHideDuration={10000}
      onClose={handleClose}
      action={action}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      sx={{ maxWidth: '400px' }}
      {...props}
    >
      <Alert onClose={handleClose} sx={{ width: '100%' }} severity={type} variant="outlined">
        <Text color={Text.color.colorTextMedium} size={Text.size.s}>
          {message}
        </Text>
      </Alert>
    </Snackbar>
  );
};

export default Toast;
