import css from 'classnames';
import React from 'react';

import s from './styles.module.scss';

type rowProps = {
  className?: string;
  children?: any;
};
const Row = ({ className, children, ...props }: rowProps) => (
  <div {...props} className={css(s.row, className)}>
    {children}
  </div>
);

Row.defaultProps = {
  className: null,
  children: null,
};

export default Row;
