import React, { useState } from 'react';
import type { DownloadRepresentation } from '~/data/openapi-client/index';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Button, CircularProgress, SvgIcon } from '@mui/material';
import DownloadIcon from '~/ui/components/icon/icons/24px/download-outline.svg';
import { useDownloadReportAssets } from '~/data/reports/queries';
import { Match, Switch } from '~/app/components/core/switch';

type DownloadCardProps = {
  name: string;
  details: string;
  status: DownloadRepresentation['status'];
  downloadURI: string;
};

function formatDownloadPercentage(percentage: number | undefined | null) {
  if (percentage == null) return '';

  // 0.1234 -> 12
  // 0.01 -> 01
  const formatted = Math.trunc(percentage * 100)
    .toString()
    .padStart(2, '0');

  return formatted;
}

export function DownloadCard({ name, details, status, downloadURI }: DownloadCardProps) {
  const { mutate: downloadAsset, isLoading: isDownloading } = useDownloadReportAssets();

  return (
    <Paper className="h-[275px] xl:w-[380px] flex flex-col justify-between items-start">
      <div className="flex flex-col gap-8">
        <Typography fontSize={18} fontWeight={700}>
          {name}
        </Typography>
        <Typography className={status !== 'Ready' && status !== 'Incomplete' ? 'text-orange' : ''}>
          {details}
        </Typography>
      </div>
      <Switch>
        <Match when={!isDownloading}>
          <Button
            variant="contained"
            disabled={status == 'Unavailable'}
            onClick={() => {
              downloadAsset({ uri: downloadURI! });
            }}
          >
            <div className="flex items-center gap-2">
              <SvgIcon component={DownloadIcon} fontSize="inherit" />
              <span className="mt-1">Download</span>
            </div>
          </Button>
        </Match>
        <Match when={isDownloading}>
          <Typography>
            <CircularProgress size={16} className="mr-1" /> Downloading
          </Typography>
        </Match>
      </Switch>
    </Paper>
  );
}
