import { withRouter } from 'react-router-dom';

import s from './styles.module.scss';
import { Container, Typography } from '@mui/material';

type footerProps = {
  children?: any;
};
const Footer = ({ children }: footerProps) => (
  <Container classes={{ root: 'sm:px-10' }} maxWidth="xl" className="py-8">
    <footer className="flex justify-between w-full">
      <div className={s.footerDisclaimerWrapper}>
        <Typography fontSize={12}>
          {new Date().getFullYear()} PwC. All rights reserved. PwC refers to the US member firm of the PwC network or
          one of its subsidiaries or affiliates.
        </Typography>
      </div>
      <div>{children}</div>
    </footer>
  </Container>
);

Footer.defaultProps = {
  children: null,
};

export default withRouter(Footer as any);
