import { particlesPackagesProgress, jobProgress, logListProgress, clientUsageProgress } from '~/data/reducers/internal';

const getState = (store) => store.internal;

export const getLogs = (store) => getState(store).get('logs');

export const particlesUpdateLoading = (store) => particlesPackagesProgress.getLoading(getState(store));
export const jobLoaded = (store) => jobProgress.getLoaded(getState(store));
export const logsLoaded = (store) => logListProgress.getLoaded(getState(store));
export const clientUsageDownloaded = (store) => clientUsageProgress.getLoaded(getState(store));
