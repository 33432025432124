import { userConnectedClientsPagePath, userConnectedClientsPath, userPath } from '~/app/constants/url/auditor';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import Breadcrumbs from '~/app/components/core/breadcrumbs/index';
import Loader from '~/app/components/core/preloader';
import ConnectedClients from './User';
import { selectors, actions } from '~/data/index.js';
import { useAppDispatch, useAppSelector } from '~/data/utils/hooks';

const ConnectedClientsRoute = () => {
  const dispatch = useAppDispatch();
  const { userId } = useParams<{ userId: string }>();

  const user = useAppSelector((state) => selectors.users.getItem(state, userId));
  const isUserLoaded = useAppSelector(selectors.users.itemLoaded);

  const fetchUser = () => dispatch(actions.users.fetchUser(userId));
  const clear = () => dispatch(actions.users.clear());

  useEffect(() => {
    if (!isUserLoaded) {
      fetchUser();
    }

    return () => {
      clear();
    };
  }, []);

  return isUserLoaded ? (
    <>
      <Breadcrumbs.Anchor
        path={userPath(':userId')}
        link={userPath(userId)}
        component={
          <span title="User">
            {!(user.get('firstName') && user.get('familyName'))
              ? 'No name'
              : `${user.get('firstName')} ${user.get('familyName')}`}{' '}
          </span>
        }
      />
      <Switch>
        <Route path={userConnectedClientsPagePath(':userId', ':page([1-9]\\d*)')} component={ConnectedClients} />
        <Redirect from={userConnectedClientsPath(userId)} to={userConnectedClientsPagePath(userId, 1)} />
      </Switch>
    </>
  ) : (
    <Loader />
  );
};

export default ConnectedClientsRoute;
