import React, { ReactElement } from 'react';
import Button from '@mui/lab/LoadingButton';
import { Box, TextField } from '@mui/material';
import { Text } from '~/ui/index.js';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { ClientSchema } from '~/data/index.js';
import FormField from '../form/field';
import Modal from '~/ui/components/modal/index';

import s from './styles.module.scss';

export interface EditVatPopUpProps extends React.HTMLAttributes<HTMLDivElement> {
  client: any;
  handleClose: () => void;
  editClient: (vatNumber: string, formikActions: FormikHelpers<{ vatNumber: string }>) => void;
}

const EditVatPopUp = ({ handleClose, client, editClient }: EditVatPopUpProps): ReactElement => {
  const onSubmit = (values, formikActions) => {
    editClient(values.vatNumber, formikActions);
  };
  return (
    <Modal
      open
      className={s.vatDialog}
      closeIcon
      onClose={handleClose}
      modalTitle={<Text weight={Text.weight.semiMedium}>Edit VAT number</Text>}
    >
      <Formik
        onSubmit={onSubmit}
        validationSchema={ClientSchema}
        initialValues={{
          vatNumber: client?.vatNumber,
        }}
        enableReinitialize
      >
        {() => (
          <Form>
            <Box marginBottom={3}>
              <Field
                name="vatNumber"
                component={FormField}
                inputComponent={TextField}
                fullWidth
                size="small"
                color="primary"
              />
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="outlined" color="primary" onClick={handleClose} className={s.vatDialogCancelBtn}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditVatPopUp;
