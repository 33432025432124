import Breadcrumbs from '~/app/components/core/breadcrumbs/index';
import { userConnectedClientsPath, usersPagePath, usersPath } from '~/app/constants/url/auditor';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Users } from './users/index';
import { UserRouter } from './user/index';

export const UsersRoute = () => (
  <>
    <Breadcrumbs.Anchor path={usersPath} link={usersPath} component={<span title="Users">Manage users</span>} />
    <Switch>
      <Route path={usersPagePath(':page([1-9]\\d*)')} component={Users} />
      <Route path={userConnectedClientsPath(':userId')} component={UserRouter} />
      <Redirect from={usersPath} to={usersPagePath(1)} />
    </Switch>
  </>
);
