import * as authConstants from '~/data/constants/auth';
import * as constants from '~/data/constants/scopes';
import LoadingProgress from '~/data/utils/reducers/loading';
import Pagination from '~/data/utils/reducers/pagination';
import { fromJS } from 'immutable';

export const itemsPagination = new Pagination('scopes');
export const loadingProgress = new LoadingProgress('scopes');
export const listLoadingProgress = new LoadingProgress('scopesList');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { scopes },
    } = payload;
    newState.mergeIn(['entities'], fromJS(scopes));
  });

const loadItems = (state, action) =>
  state.withMutations((newState) => {
    const { result, limit } = action.payload;
    const { count } = action;
    mergeData(newState, action.payload);
    itemsPagination.set(newState, count, limit, fromJS(result));
    listLoadingProgress.setLoaded(newState);
  });

const loadItem = (state, payload) =>
  state.withMutations((newState) => {
    mergeData(newState, payload);
    loadingProgress.setLoaded(newState);
  });

const deleteEntity = (state, id) =>
  state.withMutations((newState) => {
    listLoadingProgress.changeProgress(newState, false, true, false);
    newState.deleteIn(['entities', id]);
    itemsPagination.removePageItem(newState, id);
  });

const initialState = fromJS({
  entities: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadItems(state, action);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.LOAD_START:
      return loadingProgress.setLoading(state);
    case constants.LOAD_SUCCESS:
      return loadItem(state, action.payload);
    case constants.LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);
    case constants.CLEAR_SINGLE:
      return loadingProgress.clear(state);
    case constants.DELETE_SUCCESS:
      return deleteEntity(state, action.id);
    case authConstants.SESSION_CLOSE:
    case constants.CLEAR:
      return initialState;
    default:
      return state;
  }
};
