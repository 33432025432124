import {
  availableUsersLoadingProgress,
  availableUsersPagination,
  changingRoleLoadingProgress,
  connectedUsersLoadingProgress,
  connectedUsersPagination,
  itemsPagination,
  listLoadingProgress,
  loadingProgress,
} from '~/data/reducers/users';
import * as schemas from '~/data/utils/schemas';
import { Map } from 'immutable';
import { denormalize } from 'normalizr';

const getState = (store) => store.users;

const getEntities = (store) => getState(store).get('entities');

export const getItem = (store, id) => getEntities(store).get(id);

export const getItems = (store) => {
  const entities = Map({
    users: getEntities(store),
  });
  return denormalize(itemsPagination.getPageItems(getState(store)), [schemas.user], entities);
};

export const getConnectedUsers = (store) => {
  const entities = Map({
    users: getEntities(store),
  });
  return denormalize(connectedUsersPagination.getPageItems(getState(store)), [schemas.user], entities);
};

export const getAvailableUsers = (store) => {
  const entities = Map({
    users: getEntities(store),
  });
  return denormalize(availableUsersPagination.getLoadedItems(getState(store)), [schemas.user], entities);
};

export const itemLoaded = (store) => loadingProgress.getLoaded(getState(store));

export const itemsLoaded = (store) => listLoadingProgress.getLoaded(getState(store));

export const getTotalItems = (store) => itemsPagination.getTotalItems(getState(store));

export const connectedUsersLoaded = (store) => connectedUsersLoadingProgress.getLoaded(getState(store));

export const getTotalConnectedUsers = (store) => connectedUsersPagination.getTotalItems(getState(store));

export const availableUsersLoaded = (store) => availableUsersLoadingProgress.getLoaded(getState(store));

export const getTotalAvailableUsers = (store) => availableUsersPagination.getTotalItems(getState(store));

export const getRoleChanged = (store) => changingRoleLoadingProgress.getLoaded(getState(store));
export const getRoleChanging = (store) => changingRoleLoadingProgress.getLoading(getState(store));
export const getRoleChangingFailed = (store) => changingRoleLoadingProgress.getLoadFailed(getState(store));
