import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useGetExcelSheet, useListVisualExcelWorkbooks } from '~/app/components/visual-excel/hooks';
import { CellRange, Worksheet, WorksheetHandle } from '~/app/components/visual-excel/worksheet';
import * as XLSX from '~/vendor/xlsx/xlsx';
import { flushSync } from 'react-dom';
import { Skeleton } from '@mui/material';

export type WorkbookHandle = {
  selectRange: (reportFileId: string, sheet: string, range: CellRange) => void;
  clearSelection: () => void;
  setActiveFileId: (reportFileId: string) => void;
};

export type WorkbookProps = {
  fileList: VisualExcel[];
  handlers?: {
    updateFileId?: (reportFileId: string) => void;
  };
};

export type VisualExcel = {
  reportFileId: string;
  downloadURI: string;
};

const DEFAULT_RANGE: CellRange = {
  start: { row: 0, col: 0 },
  end: { row: 0, col: 0 },
};

type SheetAction = (handle: WorksheetHandle) => void;

export const Workbook = React.forwardRef<WorkbookHandle, WorkbookProps>(({ fileList, handlers }, ref) => {
  const sheetRef = useRef<WorksheetHandle>(null);
  const [selectedRange, setSelectedRange] = useState<CellRange>(DEFAULT_RANGE);

  const [activeFileId, setActiveFileId] = useState(fileList[0]?.reportFileId || '');
  const [activeSheet, setActiveSheet] = useState('');

  const pendingActions = useRef<SheetAction[]>([]);

  const queries = useListVisualExcelWorkbooks(fileList);

  const workbookMap = useMemo(() => {
    const map = new Map<string, XLSX.WorkBook>();

    for (const query of queries) {
      if (query.status == 'success') {
        map.set(query.data.reportFileId, query.data.workbook);
      }
    }
    return map;
  }, [queries]);

  const activeWorkbook = workbookMap.get(activeFileId);

  const {
    data: worksheet,
    status,
    isFetching,
  } = useGetExcelSheet(activeSheet, activeFileId, {
    keepPreviousData: true,
    enabled: !!activeSheet && !!activeFileId && !!activeWorkbook,
  });

  useEffect(() => {
    handlers?.updateFileId?.(activeFileId);
  }, [activeFileId]);

  useEffect(() => {
    if (status == 'success' && !isFetching) {
      requestAnimationFrame(() => {
        pendingActions.current.forEach((action) => action(sheetRef.current!));
      });
    }
  }, [isFetching, status]);

  const sameWorksheet = (fileId: string, sheet: string) => {
    return fileId === activeFileId && sheet === activeSheet;
  };

  const selectRange = (reportFileId: string, sheet: string, range: CellRange) => {
    if (sameWorksheet(reportFileId, sheet)) {
      sheetRef.current?.focusRange(range);
    } else {
      pendingActions.current.push((handle) => handle.focusRange(range));
    }

    flushSync(() => {
      setActiveFileId(reportFileId);
      setActiveSheet(sheet);
      setSelectedRange(range);
    });
  };

  const clearSelection = () => {
    setSelectedRange(DEFAULT_RANGE);
    sheetRef.current?.focusRange(DEFAULT_RANGE);
  };

  useImperativeHandle(ref, () => {
    return {
      selectRange,
      clearSelection,
      setActiveFileId,
    };
  });

  useEffect(() => {
    if (activeWorkbook != null && !activeWorkbook.SheetNames.includes(activeSheet)) {
      setActiveSheet(activeWorkbook.SheetNames[0]);
    }
  }, [activeWorkbook]);

  if (!activeWorkbook || !worksheet)
    return <Skeleton variant="rectangular" width="100%" height={500} className="rounded-[4px]" />;

  return (
    <div>
      <Worksheet
        ref={sheetRef}
        sheetNames={activeWorkbook.SheetNames}
        worksheet={worksheet}
        activeSheet={activeSheet}
        selectedRange={selectedRange}
      />
    </div>
  );
});
