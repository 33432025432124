import { itemsPagination, listLoadingProgress, loadingProgress } from '~/data/reducers/comparisonrows';
import * as schemas from '~/data/utils/schemas';
import { Map } from 'immutable';
import { denormalize } from 'normalizr';

const getState = (store) => store.comparisonrows;

const getEntities = (store) => getState(store).get('entities');

export const getItem = (store, id) => getEntities(store).get(id);

export const getItems = (store) => {
  const entities = Map({
    comparisonrows: getEntities(store),
  });
  return denormalize(itemsPagination.getPageItems(getState(store)), [schemas.comparisonrow], entities);
};

export const itemLoaded = (store) => loadingProgress.getLoaded(getState(store));

export const itemsLoaded = (store) => listLoadingProgress.getLoaded(getState(store));

export const getFilteredItems = (store) => itemsPagination.getTotalItems(getState(store));

export const getTotalItems = (store) => getState(store).get('totalCount');
