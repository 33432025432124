import { useEffect } from 'react';

const useIntersectionObserver = (callback: IntersectionObserverCallback, options?: IntersectionObserverInit) => {
  const observer = new IntersectionObserver(callback, options);

  useEffect(() => () => {
    observer.disconnect();
  });

  return observer;
};

export default useIntersectionObserver;
