import {
  dashboardLoadingProgress,
  downloadRequestProgress,
  itemsPagination,
  listLoadingProgress,
  loadingProgress,
  pollDownloadProgress,
  reportDownloadingProgress,
} from '~/data/reducers/clients';
import * as schemas from '~/data/utils/schemas';
import { Map } from 'immutable';
import { denormalize } from 'normalizr';

const getState = (store) => store.clients;

const getEntities = (store) => getState(store).get('entities');

export const getItem = (store, id) => getEntities(store).get(id);

export const getItems = (store) => {
  const entities = Map({
    clients: getEntities(store),
  });
  return denormalize(itemsPagination.getPageItems(getState(store)), [schemas.client], entities);
};

export const getDashboard = (store) => getState(store).get('dashboard');

export const getReference = (store) => getState(store).get('reference');

export const getPercentComplete = (store) => getState(store).get('percentComplete');

export const itemLoaded = (store) => loadingProgress.getLoaded(getState(store));

export const dashboardLoaded = (store) => dashboardLoadingProgress.getLoaded(getState(store));

export const itemsLoaded = (store) => listLoadingProgress.getLoaded(getState(store));

export const getTotalItems = (store) => itemsPagination.getTotalItems(getState(store));

export const getPollDownloadLoaded = (store) => pollDownloadProgress.getLoaded(getState(store));
export const getPollDownloadLoading = (store) => pollDownloadProgress.getLoading(getState(store));
export const getPollDownloadFailed = (store) => pollDownloadProgress.getLoadFailed(getState(store));

export const getDownloadRequestLoaded = (store) => downloadRequestProgress.getLoaded(getState(store));
export const getDownloadRequestLoading = (store) => downloadRequestProgress.getLoading(getState(store));
export const getDownloadRequestFailed = (store) => downloadRequestProgress.getLoadFailed(getState(store));

export const getReportDownloaded = (store) => reportDownloadingProgress.getLoaded(getState(store));
export const getReportDownloading = (store) => reportDownloadingProgress.getLoading(getState(store));
export const getReportDownloadingFailed = (store) => reportDownloadingProgress.getLoadFailed(getState(store));

export const getFullDownloadingProcessFailed = (store) =>
  getPollDownloadFailed(store) || getDownloadRequestFailed(store) || getReportDownloadingFailed(store);
export const getFullDownloadingProcessLoading = (store) =>
  getPollDownloadLoading(store) || getDownloadRequestLoading(store) || getReportDownloading(store);
