export const basePath = '/';

const applyBasePathTo = (path) => `${basePath}${path}`;

// Shared paths
export const profilePath = applyBasePathTo('profile');

export const signInPath = applyBasePathTo('sign-in');

export const signOutPath = applyBasePathTo('sign-out');

export const paginatedPath = (path) => (page) => `${path}/page/${page}`;

export const supportPaths = (() => {
  return {
    report: (clientId: string, reportId: string) => `/auditor/clients/${clientId}/reports/${reportId}/logs`,
    comparisonFile: (clientId: string, fileId: string) =>
      `/auditor/clients/${clientId}/comparisons/files/${fileId}/logs`,
    comparison: (clientId: string, comparisonId: string) =>
      `/auditor/clients/${clientId}/comparisons/${comparisonId}/logs`,
  };
})();
