import React from 'react';
import Button from '@mui/lab/LoadingButton';
import { TextField } from '@mui/material';
import { Text } from '~/ui/index.js';
import { Field, Form, Formik } from 'formik';
import { ClientMaterialitySchema } from '~/data/index.js';
import FormField from '~/app/components/core/form/field';
import Modal from '~/ui/components/modal/index';

import s from './styles.module.scss';

const EditClientPopUp = ({ handleClose, editClient, client }) => {
  const onSubmit = async (values: any, formikActions) => {
    const res = await editClient({ clientId: client.get('id'), ...values }, formikActions);
    if (res) {
      handleClose();
    }
  };
  return (
    <Modal open closeIcon onClose={handleClose} modalTitle={<Text weight={Text.weight.medium}>Edit client</Text>}>
      <Formik
        onSubmit={onSubmit}
        validationSchema={ClientMaterialitySchema}
        initialValues={{
          materiality: client.get('materiality')?.toJS(),
        }}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form className={s.pageForm}>
            <Field
              label="Overall Materiality Bank"
              inputComponent={TextField}
              component={FormField}
              id="materiality.bank"
              name="materiality.bank"
              placeholder="Amount"
              fullWidth
            />
            <Field
              label="Overall Materiality Controlling Company"
              inputComponent={TextField}
              component={FormField}
              id="materiality.controllingCompany"
              name="materiality.controllingCompany"
              placeholder="Amount"
              fullWidth
            />
            <Field
              label="Overall Materiality Consolidated"
              inputComponent={TextField}
              component={FormField}
              id="materiality.consolidated"
              name="materiality.consolidated"
              placeholder="Amount"
              fullWidth
            />

            <div className={s.formButtonsWrap}>
              <Button variant="outlined" data-cy="clientEditCancel" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" data-cy="saveClient" type="submit" disabled={isSubmitting}>
                Save changes
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditClientPopUp;
