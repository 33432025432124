import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogProps as DialogPropsMUI,
  DialogTitle,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Show } from '~/app/components/core/show';
import { useReportLogs } from '~/data/reports/queries';
import { useParams } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { AuditLogsSortParams, SorterDirectionParams } from '~/data/query-params';
import dayjs from 'dayjs';
import { Direction } from '~/data/openapi-client';
import { useDebounceValue } from '~/app/utils/debounce';

type HistoryDialogProps = Omit<DialogPropsMUI, 'handleClose'> & {
  handleClose: () => void;
};

const getLoadingRows = (num: number) =>
  new Array(num).fill(undefined).map((item, i) => ({
    date: <Skeleton variant="text" />,
    name: <Skeleton variant="text" />,
    actions: <Skeleton variant="text" />,
    id: `loading-row-${i}`,
  }));

const directionMapper: Record<Direction, 'asc' | 'desc'> = {
  [Direction.Asc]: 'asc',
  [Direction.Desc]: 'desc',
};

const HistoryDialog = ({ ...props }: HistoryDialogProps) => {
  const { clientId = '', reportId = '' } = useParams<{ clientId?: string; reportId?: string }>();
  const [params, setParams] = useQueryParams({
    limit: withDefault(NumberParam, 5),
    offset: withDefault(NumberParam, 0),
    sort: withDefault(AuditLogsSortParams, 'Timestamp'),
    direction: withDefault(SorterDirectionParams, 'DESC'),
    search: withDefault(StringParam, ''),
  });

  const search = useDebounceValue(params.search);

  const { data: reportLogs, isInitialLoading: isLogsLoading } = useReportLogs(
    clientId,
    reportId,
    {
      ...params,
      search,
      sort: params.sort!,
      direction: params.direction ?? undefined,
    },
    {
      keepPreviousData: true,
    },
  );

  const rows =
    reportLogs?.items.map((log) => ({
      date: dayjs(log.timestamp).format('DD-MM-YYYY'),
      name: log.actor.type === 'User' ? JSON.parse(log.actor.properties ?? '').Email : log.actor.type,
      actions: log.action,
      id: log.id,
    })) ?? [];

  const loadingRows = isLogsLoading ? getLoadingRows(params.limit) : [];

  return (
    <Dialog classes={{ paper: 'min-w-[800px]' }} {...props}>
      <DialogTitle sx={{ m: 0, p: 0 }} className="flex justify-between items-center">
        <Typography fontSize={20}>History Log</Typography>
        <IconButton aria-label="close" onClick={props.handleClose}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 1, p: 0 }}>
        <TextField
          type="search"
          variant="outlined"
          className="w-full"
          placeholder="Search"
          onChange={(e) => setParams({ search: e.target.value })}
        />
        <TableContainer component={Paper} sx={{ mt: 1, p: 0 }} className="border-none">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sortDirection={
                    params.sort === 'Timestamp' && params.direction ? directionMapper[params.direction] : false
                  }
                  width="20%"
                >
                  <TableSortLabel
                    active={params.sort === 'Timestamp'}
                    direction={directionMapper[params.direction ?? 'DESC']}
                    onClick={() => {
                      setParams((prev) => ({
                        sort: 'Timestamp',
                        direction: prev.direction === 'DESC' ? 'ASC' : 'DESC',
                      }));
                    }}
                  >
                    Date
                  </TableSortLabel>
                </TableCell>
                <TableCell width="50%">Actor</TableCell>
                <TableCell width="30%">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(isLogsLoading ? loadingRows : rows).map((row) => (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.actions}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Show when={params.limit < (reportLogs?.count ?? 0) || isLogsLoading}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={reportLogs?.count ?? 0}
            rowsPerPage={params.limit}
            page={params.offset / params.limit}
            onPageChange={(e, page) => {
              setParams({
                offset: page * params.limit,
              });
            }}
            onRowsPerPageChange={(e) => {
              setParams({
                limit: +e.target.value,
              });
            }}
          />
        </Show>
      </DialogContent>
    </Dialog>
  );
};

export default HistoryDialog;
