import React from 'react';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { useTheme } from '@mui/material';

interface MaterialDatePickerProps extends DatePickerProps<any> {
  className?: string;
  helperText?: string;
  error?: string;
}

const MaterialDatePicker = ({ className, error, helperText, value, onChange, ...props }: MaterialDatePickerProps) => {
  const theme = useTheme();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        {...props}
        value={value}
        format="dd/MM/yyyy"
        className={className}
        onChange={onChange}
        componentsProps={{
          textField: {
            helperText: error && (error === ' ' || error.includes('Invalid Date')) && (helperText || 'Invalid Date'),
            sx: {
              svg: { color: theme.palette.secondary.light },
              input: { color: theme.palette.secondary.light },
            },
            error: !!error,
          },
        }}
      />
    </LocalizationProvider>
  );
};

export { MaterialDatePicker };
