import { AnyPropsType } from '~/AnyPropsType';
import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import s from '../styles.module.scss';

const HeaderMenuItem = ({ className, style, children, href, ...props }: AnyPropsType & { href: string }) =>
  href.startsWith('http') ? (
    <a {...props} href={href} className={cx(s.footerTopNavMenuItem, className)} style={style}>
      {children}
    </a>
  ) : (
    <Link {...props} to={href} className={cx(s.footerTopNavMenuItem, className)} style={style}>
      {children}
    </Link>
  );

// HeaderMenuItem.defaultProps = {
//   style: null,
//   className: null,
//   children: null,
// };

// HeaderMenuItem.propTypes = {
//   /* eslint-disable-next-line */
//   style: PropTypes.object,
//   className: PropTypes.string,
//   children: PropTypes.node,
//   onClick: PropTypes.func.isRequired,
// };

export default HeaderMenuItem;
