import * as constants from '~/data/constants/taxonomies';
import LoadingProgress from '~/data/utils/reducers/loading';
import { fromJS } from 'immutable';

export const loadingProgress = new LoadingProgress('reports');
const initialState = fromJS({});

export default (state: any = initialState, action) => {
  switch (action.type) {
    case constants.LIST_LOAD_SUCCESS:
      return state.withMutations((newState) => {
        loadingProgress.setLoaded(newState);
        newState.set('items', action.payload);
      });

    case constants.LIST_LOAD_START:
      return loadingProgress.setLoading(state);

    case constants.LIST_LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);
    default:
      return state;
  }
};
