import * as authConstants from '~/data/constants/auth';
import * as constants from '~/data/constants/users';
import LoadingProgress from '~/data/utils/reducers/loading';
import Pagination from '~/data/utils/reducers/pagination';
import { fromJS } from 'immutable';

export const itemsPagination = new Pagination('users');
export const loadingProgress = new LoadingProgress('users');
export const listLoadingProgress = new LoadingProgress('usersList');
export const changingRoleLoadingProgress = new LoadingProgress('changingRole');

export const connectedUsersPagination = new Pagination('connectedUsers');
export const connectedUsersLoadingProgress = new LoadingProgress('connectedUsers');

export const availableUsersPagination = new Pagination('availableUsers');
export const availableUsersLoadingProgress = new LoadingProgress('availableUsers');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { users },
    } = payload;
    newState.mergeIn(['entities'], fromJS(users));
  });

const loadItems = (state, action) =>
  state.withMutations((newState) => {
    const { result, limit } = action.payload;
    const { count } = action;
    mergeData(newState, action.payload);
    itemsPagination.set(newState, count, limit, fromJS(result));
    listLoadingProgress.setLoaded(newState);
  });

const loadConnectedUsers = (state, action) =>
  state.withMutations((newState) => {
    const { result, limit } = action.payload;
    const { count } = action;
    mergeData(newState, action.payload);
    connectedUsersPagination.set(newState, count, limit, fromJS(result));
    connectedUsersLoadingProgress.setLoaded(newState);
  });

const loadAvailableUsers = (state, action) =>
  state.withMutations((newState) => {
    const { result, limit } = action.payload;
    const { count } = action;
    mergeData(newState, action.payload);
    availableUsersPagination.set(newState, count, limit, fromJS(result));
    availableUsersLoadingProgress.setLoaded(newState);
  });

const loadItem = (state, payload) =>
  state.withMutations((newState) => {
    mergeData(newState, payload);
    loadingProgress.setLoaded(newState);
  });

const deleteUser = (state, id) =>
  state.withMutations((newState) => {
    loadingProgress.changeProgress(newState, false, true, false);
    newState.deleteIn(['entities', id]);
    connectedUsersPagination.removePageItem(newState, id);
  });

const deleteConnectedUser = (state, id) =>
  state.withMutations((newState) => {
    loadingProgress.changeProgress(newState, false, true, false);
    newState.deleteIn(['entities', id]);
    connectedUsersPagination.removePageItem(newState, id);
  });

const changeUserRole = (state, userId) =>
  state.withMutations((newState) => {
    changingRoleLoadingProgress.changeProgress(newState, false, true, false);
    newState.setIn(['entities', userId, 'isAdmin'], !state.getIn(['entities', userId, 'isAdmin']));
  });

const initialState = fromJS({
  entities: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_SUCCESS:
    case constants.DELETE_USER_SUCCESS:
      return loadItems(state, action);
    case constants.LIST_LOAD_FAILED:
    case constants.DELETE_USER_FAILED:
      return listLoadingProgress.setLoadFailed(state);

    case constants.DELETE_CONNECTED_USER_SUCCESS:
      return deleteConnectedUser(state, action.id);

    case constants.CONNECTED_USERS_LOAD_START:
    case constants.DELETE_CONNECTED_USER_START:
      return connectedUsersLoadingProgress.setLoading(state);
    case constants.CONNECTED_USERS_LOAD_SUCCESS:
      return loadConnectedUsers(state, action);
    case constants.CONNECTED_USERS_LOAD_FAILED:
      return connectedUsersLoadingProgress.setLoadFailed(state);

    case constants.AVAILABLE_USERS_LOAD_START:
      return availableUsersLoadingProgress.setLoading(state);
    case constants.AVAILABLE_USERS_LOAD_SUCCESS:
      return loadAvailableUsers(state, action);
    case constants.AVAILABLE_USERS_LOAD_FAILED:
      return availableUsersLoadingProgress.setLoadFailed(state);

    case constants.LOAD_START:
      return loadingProgress.setLoading(state);
    case constants.LOAD_SUCCESS:
    case constants.UPDATE_SUCCESS:
      return loadItem(state, action.payload);
    case constants.LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);

    case constants.CHANGE_USER_START:
      return changingRoleLoadingProgress.setLoading(state);
    case constants.CHANGE_USER_SUCCESS:
      return changeUserRole(state, action.userId);
    case constants.CHANGE_USER_FAILED:
      return changingRoleLoadingProgress.setLoadFailed(state);

    case constants.CLEAR_SINGLE:
      return loadingProgress.clear(state);
    case authConstants.SESSION_CLOSE:
    case constants.CLEAR:
      return initialState;
    default:
      return state;
  }
};
