import { useParams } from 'react-router-dom';
import { Button, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { useGetReport, useReportFilesList, useReportVersionsList } from '~/data/reports/queries';

import {
  useDownloadReportFileProcessingLogs,
  useDownloadReportProcessingLogs,
  useDownloadVersionProcessingLogs,
} from '~/data/downloads/queries';

import { LoadingButton } from '@mui/lab';
import { Match, Switch } from '~/app/components/core/switch';

function ReportProcessingLogsPage() {
  const { clientId, reportId } = useParams<{ clientId: string; reportId: string }>();

  const versionLimit = 10;

  const { data: report, isInitialLoading: isReportLoading } = useGetReport(clientId, reportId);
  const { data: versionList } = useReportVersionsList(clientId, reportId, {
    direction: 'DESC',
    sort: 'Order',
    limit: versionLimit,
  });
  const { data: fileList } = useReportFilesList(clientId, reportId, {
    limit: 20,
  });

  const { mutate: downloadReportLogs, isLoading: isDownloadingReportLogs } = useDownloadReportProcessingLogs();
  const { mutate: downloadVersionLogs } = useDownloadVersionProcessingLogs();
  const { mutate: downloadFileLogs } = useDownloadReportFileProcessingLogs();

  return (
    <section data-name="report-processing-logs-page">
      <Typography fontSize={24} component="h1" className="mb-5">
        Processing logs
      </Typography>

      <div>
        <div className="mb-4">
          <Switch>
            <Match when={isDownloadingReportLogs === true}>
              <Typography>
                <CircularProgress size={16} className="mr-1" /> Downloading
              </Typography>
            </Match>
            <Match when={isDownloadingReportLogs === false}>
              <LoadingButton
                loading={isReportLoading}
                variant="contained"
                onClick={() => downloadReportLogs({ clientId, reportId })}
              >
                Download report "{report?.name}" logs
              </LoadingButton>
            </Match>
          </Switch>
        </div>

        <Typography className="mb-3">Logs for {versionLimit} latest versions:</Typography>
        <ul className="mb-4 flex flex-col gap-4">
          {versionList?.items.map((version) => (
            <li key={version.id}>
              <Button
                variant="contained"
                onClick={() => downloadVersionLogs({ clientId, reportId, versionId: version.id })}
              >
                Download version #{version.order} logs
              </Button>
            </li>
          ))}
        </ul>

        <Typography className="mb-3">Files:</Typography>
        <ul className="mb-4 flex flex-col gap-4">
          {fileList?.items.map((file) => (
            <li key={file.reportFileId}>
              <Button
                variant="contained"
                onClick={() => downloadFileLogs({ clientId, reportId, reportFileId: file.reportFileId })}
              >
                Download file "{file.name}" logs
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default ReportProcessingLogsPage;
