import React from 'react';
import s from './styles.module.scss';

const Loader = () => (
  <div className={s.container}>
    <div className={s.loader}>
      <div className={s.loaderItem}>
        <div className={s.loaderItemDot} />
        <div className={s.loaderItemDot} />
        <div className={s.loaderItemDot} />
      </div>
      <div className={s.loaderItem}>
        <div className={s.loaderItemDot} />
        <div className={s.loaderItemDot} />
        <div className={s.loaderItemDot} />
      </div>
      <div className={s.loaderItem}>
        <div className={s.loaderItemDot} />
        <div className={s.loaderItemDot} />
        <div className={s.loaderItemDot} />
      </div>
    </div>
  </div>
);

Loader.Small = () => (
  <div className={s.containerSmall}>
    <div className={s.loader}>
      <div className={s.loaderItem}>
        <div className={s.loaderItemDot} />
        <div className={s.loaderItemDot} />
        <div className={s.loaderItemDot} />
      </div>
      <div className={s.loaderItem}>
        <div className={s.loaderItemDot} />
        <div className={s.loaderItemDot} />
        <div className={s.loaderItemDot} />
      </div>
      <div className={s.loaderItem}>
        <div className={s.loaderItemDot} />
        <div className={s.loaderItemDot} />
        <div className={s.loaderItemDot} />
      </div>
    </div>
  </div>
);

export default Loader;
