import { queryKeyBuilder } from '~/data/utils/helpers/query-key.builder';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ClientComparisonApi, type ClientComparisonSort, ComparisonValidator } from '~/data/openapi-client/index';
import { api, dazzler, type EndpointResponse } from '~/data/dazzler-api';
import { PagingParams, type SortingParams } from '~/data/utils/types';
import { AxiosError } from 'axios';
import { useCallback, useRef, useState } from 'react';
import minutesToMilliseconds from 'date-fns/minutesToMilliseconds';
import ky, { Options as KyOptions } from 'ky';
import { baseURL } from '~/config';
import { downloadFile, getFilename } from '../downloads/file';

export const comparisonKeys = (() => {
  const base = 'comparisons';

  return {
    base: () => queryKeyBuilder(base),
    clientComparisonList: (clientId: string, params?: ClientComparisonsParams) =>
      queryKeyBuilder(base, 'clients', clientId, params),
    file: (clientId: string, fileId: string) => queryKeyBuilder(base, 'clients', clientId, 'files', fileId),
    fileList: (clientId: string, params?: ClientComparisonFileParams) =>
      queryKeyBuilder(base, 'clients', clientId, 'files', params),
    comparison: (clientId: string, comparisonId: string) =>
      queryKeyBuilder(base, 'clients', clientId, 'comparisons', comparisonId),
    createComparison: (clientId: string) => queryKeyBuilder(base, clientId, 'createComparison'),
  };
})();

type ClientComparisonsParams = PagingParams & SortingParams<ClientComparisonSort>;

export function useListClientComparisons(
  clientId: string,
  params?: ClientComparisonsParams,
  options?: UseQueryOptions<EndpointResponse<ClientComparisonApi['listComparisons']>>,
) {
  return useQuery({
    queryKey: comparisonKeys.clientComparisonList(clientId, params),
    queryFn: async function getClientComparison() {
      const response = await dazzler.clientComparisons.listComparisons({
        clientId,
        ...params,
      });

      return response.data;
    },
    ...options,
  });
}

export function useGetComparison(
  clientId: string,
  comparisonId: string,
  options?: Omit<UseQueryOptions<EndpointResponse<ClientComparisonApi['getComparison']>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery({
    queryKey: comparisonKeys.comparison(clientId, comparisonId),
    queryFn: async function getComparison() {
      const response = await dazzler.clientComparisons.getComparison({
        clientId,
        comparisonId,
      });

      return response.data;
    },
    ...options,
  });
}

type ClientComparisonFileParams = PagingParams;

export function useListComparisonFiles(
  clientId: string,
  params?: ClientComparisonFileParams,
  options?: Omit<UseQueryOptions<EndpointResponse<ClientComparisonApi['listComparisonFiles']>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery({
    queryFn: async function listComparisonFiles({ signal }) {
      const response = await dazzler.clientComparisons.listComparisonFiles(
        {
          clientId,
          ...params,
        },
        { signal },
      );

      return response.data;
    },
    queryKey: comparisonKeys.fileList(clientId, params),
    enabled: !!clientId,
    staleTime: minutesToMilliseconds(5),
    ...options,
  });
}

export function useGetComparisonFile(
  clientId: string,
  fileId: string,
  options?: Omit<UseQueryOptions<EndpointResponse<ClientComparisonApi['getComparisonFile']>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery({
    queryKey: comparisonKeys.file(clientId, fileId),
    queryFn: async function getComparisonFile() {
      const response = await dazzler.clientComparisons.getComparisonFile({
        clientId,
        fileId,
      });

      return response.data;
    },
    ...options,
  });
}

export function useUploadComparisonFile(
  clientId: string,
  options?: Omit<
    UseMutationOptions<
      EndpointResponse<ClientComparisonApi['uploadComparisonFile']>,
      AxiosError,
      { file: File },
      unknown
    >,
    'mutationFn' | 'mutationKey'
  >,
) {
  const [progress, setProgress] = useState(0);
  const abortControllerRef = useRef<AbortController | null>(null);

  const mutation = useMutation({
    mutationFn: async ({ file }) => {
      setProgress(0);
      abortControllerRef.current = new AbortController();
      const response = await dazzler.clientComparisons.uploadComparisonFile(
        {
          clientId,
          file,
        },
        {
          onUploadProgress: (ev) => {
            setProgress(Math.round((ev.loaded * 100) / (ev.total ?? 1)));
          },
          signal: abortControllerRef.current.signal,
        },
      );

      return response.data;
    },
    ...options,
  });

  const reset = useCallback(() => {
    abortControllerRef.current?.abort();
    mutation.reset();
    setProgress(0);
  }, [mutation.reset, setProgress]);

  return { ...mutation, progress, abortControllerRef, reset };
}

export function useCreateComparison(
  clientId: string,
  options?: Omit<
    UseMutationOptions<
      EndpointResponse<ClientComparisonApi['createComparison']>,
      unknown,
      { body: ComparisonValidator },
      unknown
    >,
    'mutationFn' | 'mutationKey'
  >,
) {
  return useMutation({
    mutationFn: async ({ body }) => {
      const response = await dazzler.clientComparisons.createComparison({
        clientId,
        comparisonValidator: body,
      });

      return response.data;
    },
    mutationKey: comparisonKeys.createComparison(clientId),
    ...options,
  });
}

export function useDownloadComparisonVisualExcel(
  clientId: string,
  comparisonId: string,
  options?: UseMutationOptions<
    void,
    Error,
    {
      signal?: AbortSignal;
      onDownloadProgress?: KyOptions['onDownloadProgress'];
    }
  >,
) {
  return useMutation({
    mutationFn: async function downloadComparisonAssets({ signal, onDownloadProgress }) {
      const endpoint = new URL(`/api/clients/${clientId}/comparisons/${comparisonId}/visual-excel.xlsx`, baseURL);
      const res = await ky(endpoint, {
        headers: {
          Authorization: api.getAuthorizationHeader() ?? '',
        },
        retry: {
          limit: 3,
        },
        signal,
        onDownloadProgress,
      });

      const fallback = endpoint.pathname.split('/').at(-1);

      const blob = await res.blob();
      const fileName = getFilename(res.headers.get('Content-Disposition'), fallback ?? 'report-asset');

      const file = new File([blob], fileName, { type: 'application/octet-stream' });
      downloadFile(file);
    },
    ...options,
  });
}
