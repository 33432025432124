import React from 'react';
import { useAppDispatch } from '~/data/utils/hooks';
import { Text } from '~/ui/index.js';
import { Box } from '@mui/material';
import Button from '@mui/lab/LoadingButton';

import s from './styles.module.scss';
import { Field, Form, Formik } from 'formik';
import FormField from '~/app/components/core/form/field';
import { MaterialDatePicker } from '~/ui/components/mui-datepicker/index';
import { useDownloadClientUsageReport } from '~/data/downloads/queries';

export const ClientUsageReport = () => {
  const { mutate: downloadUsageReport } = useDownloadClientUsageReport();

  const onSubmit = (values: { start: Date; end: Date }) => {
    downloadUsageReport({
      start: values.start,
      end: values.end,
    });
  };

  return (
    <>
      <div className={s.usageReport}>
        <Box margin="32px 0 18px">
          <Text size={Text.size.s} weight={Text.weight.medium}>
            Client Usage Report (Billing)
          </Text>
        </Box>

        <Box className={s.usageReportWrap}>
          <Box>
            <Text size={Text.size.s} weight={Text.weight.medium}>
              Generate a report
            </Text>
          </Box>
          <Box paddingLeft="22px" width="40%">
            <Formik
              onSubmit={onSubmit}
              initialValues={{
                start: new Date(2020, 1, 1),
                end: new Date(),
                clientIds: [],
              }}
              enableReinitialize
            >
              {({ isSubmitting }) => (
                <Form className={s.pageForm}>
                  <Box>
                    <Field
                      inputComponent={MaterialDatePicker}
                      component={FormField}
                      id="start"
                      name="start"
                      label="Start Date (based on period end)"
                      valueAdapter={(v) => v}
                      onChangeAdapter={(v) => new Date(v.getTime() - v.getTimezoneOffset() * 60000)}
                    />
                  </Box>
                  <Box>
                    <Field
                      inputComponent={MaterialDatePicker}
                      component={FormField}
                      id="end"
                      name="end"
                      label="End Date (based on period end)"
                      valueAdapter={(v) => v}
                      onChangeAdapter={(v) => new Date(v.getTime() - v.getTimezoneOffset() * 60000)}
                    />
                  </Box>

                  <Box className={s.formButtonsWrap}>
                    <Button
                      variant="contained"
                      data-cy="downloadReport"
                      type="submit"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                    >
                      Generate and download report
                    </Button>
                  </Box>

                  <Box>
                    {isSubmitting && (
                      <Text size={Text.size.s} weight={Text.weight.normal}>
                        <br />
                        Generating the report. This might take some time.
                      </Text>
                    )}
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </div>
    </>
  );
};
