import * as authConstants from '~/data/constants/auth';
import * as constants from '~/data/constants/clients';
import LoadingProgress from '~/data/utils/reducers/loading';
import Pagination from '~/data/utils/reducers/pagination';
import { fromJS } from 'immutable';

export const itemsPagination = new Pagination('clients');
export const loadingProgress = new LoadingProgress('clients');
export const listLoadingProgress = new LoadingProgress('clientsList');
export const dashboardLoadingProgress = new LoadingProgress('dashboard');

export const downloadRequestProgress = new LoadingProgress('downloadRequest');
export const pollDownloadProgress = new LoadingProgress('pollDownload');
export const reportDownloadingProgress = new LoadingProgress('reportDownloading');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { clients },
    } = payload;
    newState.mergeIn(['entities'], fromJS(clients));
  });

const loadItems = (state, action) =>
  state.withMutations((newState) => {
    const { result, limit } = action.payload;
    const { count } = action;
    mergeData(newState, action.payload);
    itemsPagination.set(newState, count, limit, fromJS(result));
    listLoadingProgress.setLoaded(newState);
  });

const loadItem = (state, payload) =>
  state.withMutations((newState) => {
    mergeData(newState, payload);
    loadingProgress.setLoaded(newState);
  });

const loadDashboard = (state, payload) =>
  state.withMutations((newState) => {
    newState.set('dashboard', payload);
    dashboardLoadingProgress.setLoaded(newState);
  });

const loadDownloadRequest = (state, payload) =>
  state.withMutations((newState) => {
    const { reference } = payload;
    newState.set('reference', reference);
    downloadRequestProgress.setLoaded(newState);
  });

const loadDownloadStatus = (state, payload) =>
  state.withMutations((newState) => {
    const { percentComplete } = payload;
    newState.set('percentComplete', percentComplete);
    pollDownloadProgress.setLoaded(newState);
  });

const clearDownloadingProgress = (state) =>
  state.withMutations((newState) => {
    newState.set('percentComplete', 0);
    newState.set('reference', null);
    downloadRequestProgress.clear(state);
    pollDownloadProgress.clear(state);
    reportDownloadingProgress.clear(state);
  });

const initialState = fromJS({
  entities: {},
  dashboard: null,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadItems(state, action);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.DASHBOARD_LOAD_START:
      return dashboardLoadingProgress.setLoading(state);
    case constants.DASHBOARD_LOAD_SUCCESS:
      return loadDashboard(state, action.payload);
    case constants.DASHBOARD_LOAD_FAILED:
      return dashboardLoadingProgress.setLoadFailed(state);
    case constants.LOAD_START:
      return loadingProgress.setLoading(state);
    case constants.LOAD_SUCCESS:
    case constants.UPDATE_SUCCESS:
      return loadItem(state, action.payload);
    case constants.LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);

    case constants.DOWNLOAD_REQUEST_START:
      return downloadRequestProgress.setLoading(state);
    case constants.DOWNLOAD_REQUEST_SUCCESS:
      return loadDownloadRequest(state, action.payload);
    case constants.DOWNLOAD_REQUEST_FAILED:
      return downloadRequestProgress.setLoadFailed(state);

    case constants.POLL_STATUS_START:
      return pollDownloadProgress.setLoading(state);
    case constants.POLL_STATUS_SUCCESS:
      return loadDownloadStatus(state, action.payload);
    case constants.POLL_STATUS_FAILED:
      return pollDownloadProgress.setLoadFailed(state);

    case constants.REPORT_DOWNLOAD_START:
      return reportDownloadingProgress.setLoading(state);
    case constants.REPORT_DOWNLOAD_SUCCESS:
      return reportDownloadingProgress.setLoaded(state);
    case constants.REPORT_DOWNLOAD_FAILED:
      return reportDownloadingProgress.setLoadFailed(state);

    case constants.CLEAR_DOWNLOADING_PROGRESS:
      return clearDownloadingProgress(state);

    case constants.CLEAR_SINGLE:
      return loadingProgress.clear(state);
    case authConstants.SESSION_CLOSE:
    case constants.CLEAR:
      return initialState;
    default:
      return state;
  }
};
