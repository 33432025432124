import { AnyPropsType } from '~/AnyPropsType';
import { formatDate } from '~/app/utils/format-data';
import React from 'react';
import { Text } from '~/ui/index.js';
import { Box, Button } from '@mui/material';
import Modal from '~/ui/components/modal/index';
import { scopeTypeMapper } from '../../pages/auditor/clients/client/reports/UploadReportModal';
import dayjs from 'dayjs';

const RemoveReportPopup = ({ closeFunction, onRemove, report }: AnyPropsType) => (
  <Modal
    open
    closeIcon
    onClose={closeFunction}
    modalTitle={
      <Text weight={Text.weight.medium}>{`Remove report: ${
        report?.scopeType ? scopeTypeMapper[report.scopeType] : ''
      } - ${dayjs(report.periodEnd).format('DD-MM-YYYY')}`}</Text>
    }
    contentText={<Text size={Text.size.m}>Are you sure you want to remove this report and all of its files?</Text>}
  >
    <Box display="flex" justifyContent="flex-end">
      <Box marginRight="15px">
        <Button onClick={closeFunction} variant="outlined">
          Close
        </Button>
      </Box>
      <Button onClick={() => onRemove(report.id)} variant="contained" color="error">
        Remove
      </Button>
    </Box>
  </Modal>
);

export default RemoveReportPopup;
