import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ScopeType } from '~/data/openapi-client';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { z } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { zodDay } from '~/data/utils/validation';
import dayjs from 'dayjs';
import { reportKeys, useCreateReportScope } from '~/data/reports/queries';
import { useHistory, useParams } from 'react-router-dom';
import { reportFileUploadPath } from '~/app/constants/url/auditor';
import { useSelector } from '~/data';
import { scopes } from '~/data/selectors';
import { useQueryClient } from '@tanstack/react-query';

const Schema = z.object({
  scopeType: z.nativeEnum(ScopeType),
  periodEnd: zodDay,
});

type Values = z.infer<typeof Schema>;

const scopeTypeOptions: { value: ScopeType; label: string }[] = [
  { value: ScopeType.Bank, label: 'Bank' },
  { value: ScopeType.Consolidated, label: 'Consolidated' },
  { value: ScopeType.Group, label: 'Group' },
  { value: ScopeType.Ba610, label: 'BA 610' },
  { value: ScopeType.BankStatutory, label: 'Bank - Statutory' },
  { value: ScopeType.BankConsolidated, label: 'Bank - Consolidated' },
  { value: ScopeType.InsuranceStatutory, label: 'Insurance - Statutory' },
  { value: ScopeType.InsuranceConsolidated, label: 'Insurance - Consolidated' },
  { value: ScopeType.InsuranceBranch, label: 'Insurance - Branch' },
];

export const scopeTypeMapper = Object.fromEntries(
  Object.values(scopeTypeOptions.map((item) => [item.value, item.label])),
) as Record<ScopeType, string>;

const UploadReportModal = (
  props: Omit<DialogProps, 'onClose'> & {
    onClose: () => void;
  },
) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { clientId = '' } = useParams<{ clientId: string }>();
  const scopesList = useSelector(scopes.getItems)?.toJS() ?? [];

  const { control, handleSubmit } = useForm<Values>({
    resolver: zodResolver(Schema),
  });

  const scopesArray: string[] = scopesList.map((item) => item.name);

  const scopeOptions = scopeTypeOptions.filter((item) => scopesArray.includes(item.value));

  const { mutateAsync: createReportScope } = useCreateReportScope();

  const onSubmit = async (values: Values) => {
    const data = await createReportScope({
      clientId,
      body: {
        scopeType: values.scopeType,
        periodEnd: values.periodEnd.add(11, 'hours').toISOString(),
        name: `${scopeTypeMapper[values.scopeType]} - ${values.periodEnd.format('DD-MM-YYYY')}`,
      },
    });
    queryClient.invalidateQueries({ queryKey: reportKeys.reports(clientId), exact: false, type: 'all' });
    if (data.clientId && data.id) {
      history.push(reportFileUploadPath(data.clientId, data.id));
    }
  };

  return (
    <Dialog
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit(onSubmit),
      }}
      {...props}
    >
      <DialogTitle className="p-0 flex items-center justify-between text-[20px]">
        <Typography fontSize="inherit" className="text-dark">
          Upload regulatory report
        </Typography>
        <IconButton aria-label="close" onClick={props.onClose}>
          <CloseIcon fontSize="inherit" className="text-light" />
        </IconButton>
      </DialogTitle>
      <DialogContent className="p-0">
        <Controller
          control={control}
          name="scopeType"
          render={({ field, fieldState: { error } }) => (
            <FormControl error={!!error} className="w-full" sx={{ my: 1 }}>
              <InputLabel>Scope</InputLabel>
              <Select {...field} label="Scope">
                {scopeOptions.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{error?.message ?? ''}</FormHelperText>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="periodEnd"
          render={({ field, fieldState: { error } }) => (
            <FormControl error={!!error} className="w-full" sx={{ my: 1 }}>
              <DatePicker
                {...field}
                format="DD/MM/YYYY"
                onChange={(value) => {
                  field.onChange(dayjs(value?.toDate()));
                }}
                label="Period end"
                className="w-full mt-2"
              />
              <FormHelperText>{error?.message ?? ''}</FormHelperText>
            </FormControl>
          )}
        />
      </DialogContent>
      <DialogActions className="p-0 mt-6 flex">
        <Button className="py-3 basis-1/2" onClick={props.onClose} variant="outlined">
          Cancel
        </Button>
        <Button className="py-3 basis-1/2" type="submit" variant="contained">
          Next
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadReportModal;
