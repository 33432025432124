import Breadcrumbs from '~/app/components/core/breadcrumbs/index';
import { taxonomiesPath } from '~/app/constants/url/auditor';
import { actions, selectors } from '~/data/index.js';
import React, { useEffect } from 'react';
import { Row, Text } from '~/ui/index.js';
import { useAppDispatch, useAppSelector } from '~/data/utils/hooks';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import TableRowsLoader from '~/ui/components/mui-table/table-loader';

import s from './styles.module.scss';

const TaxonomiesContainer = () => {
  const dispatch = useAppDispatch();
  const taxonomies = useAppSelector(selectors.taxonomies.getList);
  const isLoaded = useAppSelector(selectors.taxonomies.itemsLoaded);

  const fetchTaxonomies = () => dispatch(actions.taxonomies.fetchTaxonomies());

  useEffect(() => {
    fetchTaxonomies();
  }, []);

  return (
    <>
      <Breadcrumbs.Anchor
        path={taxonomiesPath}
        link={taxonomiesPath}
        component={<span title="Taxonomy versions">Taxonomy versions</span>}
      />
      <div className={s.taxonomies}>
        <Row className={s.taxonomiesRow}>
          <Text size={Text.size.xxl}>Taxonomy versions</Text>
        </Row>
        <Row className={s.taxonomiesCards}>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="100%" key="versions">
                      <Text size={Text.size.l}>Taxonomy versions</Text>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoaded ? (
                    taxonomies.map((taxonomy: { value: string; key: string; isLatest: boolean }) => (
                      <TableRow key={taxonomy.value}>
                        <TableCell width="100%">
                          <Text size={Text.size.s} weight={taxonomy.isLatest ? Text.weight.medium : Text.weight.normal}>
                            {taxonomy.value}
                          </Text>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRowsLoader colsNum={1} rowsNum={40} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Row>
      </div>
    </>
  );
};

export default TaxonomiesContainer;
