import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import type { ColumnInfo, VisualExcelSheet } from '~/app/components/visual-excel/db';
import cx from '~/data/utils/helpers/cx';

interface CellPosition {
  row: number;
  col: number;
}

export interface CellRange {
  start: CellPosition;
  end: CellPosition;
}

export interface WorksheetHandle {
  focusCell: (position: CellPosition) => void;
  focusRange: (range: CellRange) => void;
}

export interface SheetProps {
  sheetNames: string[];
  worksheet: VisualExcelSheet;
  activeSheet: string;
  selectedRange?: CellRange | null;
  className?: string;
  onRangeSelect?: (range: CellRange) => void;
}

const isInRange = (position: CellPosition, range: CellRange | undefined | null): boolean => {
  if (!range) return false;

  const minRow = Math.min(range.start.row, range.end.row);
  const maxRow = Math.max(range.start.row, range.end.row);
  const minCol = Math.min(range.start.col, range.end.col);
  const maxCol = Math.max(range.start.col, range.end.col);

  return position.row >= minRow && position.row <= maxRow && position.col >= minCol && position.col <= maxCol;
};

const DEFAULT_COLUMN_SIZE = 100;
const MAX_COLUMNS = 702; // A to ZZ (26 + 26²)

// Generate Excel column names (A, B, C, ..., Z, AA, AB, ..., ZZ)
function generateColumnName(index: number): string {
  let name = '';
  if (index >= 26) {
    name += String.fromCharCode(64 + Math.floor(index / 26));
  }
  name += String.fromCharCode(65 + (index % 26));
  return name;
}

// Generate all possible column names
const ALL_COLUMNS = Array.from({ length: MAX_COLUMNS }, (_, i) => generateColumnName(i));

export const Worksheet = forwardRef<WorksheetHandle, SheetProps>(
  ({ worksheet, sheetNames, activeSheet, selectedRange, className = '' }, ref) => {
    const tableRef = useRef<HTMLDivElement>(null);

    const rows: string[][] = useMemo(() => JSON.parse(worksheet.rows), [worksheet.rows, activeSheet]);
    const dataColumns: string[] = useMemo(() => JSON.parse(worksheet.columns), [worksheet.columns, activeSheet]);
    const colInfo: ColumnInfo[] = useMemo(
      () => JSON.parse(worksheet.column_info),
      [worksheet.column_info, activeSheet],
    );

    const lastDataColumnIndex = dataColumns.length - 1;
    const visibleColumns = ALL_COLUMNS.slice(0, Math.max(26, lastDataColumnIndex + 5)); // Show at least 26 columns or 5 more than data

    const getColumnWidth = (colIndex: number): number => {
      return colInfo[colIndex]?.width || DEFAULT_COLUMN_SIZE;
    };

    // Calculate total table width
    const totalWidth = useMemo(() => {
      return visibleColumns.reduce((total, _, index) => total + getColumnWidth(index), 0) + 64;
    }, [visibleColumns, colInfo]);

    const scrollToCell = (position: CellPosition) => {
      if (!tableRef.current) return;

      const cell = tableRef.current.querySelector(`[data-row="${position.row}"][data-col="${position.col}"]`);
      cell?.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });

      const sheet = tableRef.current.querySelector(`[data-sheet="${activeSheet}"]`);

      sheet?.scrollIntoView({ block: 'nearest', inline: 'start', behavior: 'smooth' });
    };

    useImperativeHandle(ref, () => ({
      focusCell: (position: CellPosition) => {
        scrollToCell(position);
      },
      focusRange: (range: CellRange) => {
        scrollToCell(range.start);
      },
    }));

    const getCellClassName = (rowIndex: number, colIndex: number): string => {
      const isSelected = isInRange({ row: rowIndex, col: colIndex }, selectedRange);
      const isRangeStart = selectedRange?.start.row === rowIndex && selectedRange?.start.col === colIndex;
      const isRangeEnd = selectedRange?.end.row === rowIndex && selectedRange?.end.col === colIndex;

      return cx(
        'border-r border-b border-gray-200 px-3 py-2 text-sm',
        isSelected && 'bg-blue-50',
        isRangeStart ? 'border-l-2 border-t-2 border-blue-500' : '',
        isRangeEnd ? 'border-r-2 border-b-2 border-blue-500' : '',
      );
    };

    return (
      <div className={className} ref={tableRef}>
        <ul className="flex space-x-1 px-2 pt-2 bg-gray-50 border-b border-gray-200 overflow-hidden overflow-x-auto">
          {sheetNames.map((sheetName) => (
            <li
              data-sheet={sheetName}
              key={sheetName}
              className={`px-2 py-3 text-sm font-medium rounded-t-lg whitespace-nowrap
                ${
                  activeSheet === sheetName
                    ? 'bg-blue-50 border-t-2 border-x-2 border-blue-500 -mb-px'
                    : 'text-gray-500'
                }`}
            >
              {sheetName}
            </li>
          ))}
        </ul>

        <div className="flex-1 overflow-auto max-h-[80vh]">
          <div style={{ width: `${totalWidth}px` }}>
            <table className="w-full table-fixed border-collapse">
              <colgroup>
                <col className="w-16" />
                {visibleColumns.map((_, index) => (
                  <col key={index} style={{ width: `${getColumnWidth(index)}px` }} />
                ))}
              </colgroup>
              <thead className="bg-gray-50 sticky top-0 z-10">
                <tr>
                  <th className="sticky left-0 z-20 bg-gray-50 px-3 py-2 text-sm font-semibold text-gray-900 border-b border-r border-gray-200">
                    {''}
                  </th>
                  {visibleColumns.map((col) => (
                    <th
                      key={col}
                      className="px-3 py-2 text-sm font-semibold text-gray-900 border-b border-r border-gray-200"
                    >
                      {col}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="sticky left-0 z-10 bg-gray-50 px-3 py-2 text-sm font-semibold text-gray-900 border-r border-gray-200">
                      {rowIndex + 1}
                    </td>
                    {visibleColumns.map((_, colIndex) => {
                      const cellData = row[colIndex];
                      return (
                        <td
                          key={colIndex}
                          data-row={rowIndex}
                          data-col={colIndex}
                          title={cellData}
                          className={getCellClassName(rowIndex, colIndex)}
                        >
                          <div className="truncate">{cellData || ''}</div>
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  },
);
