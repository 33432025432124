import * as constants from '~/data/constants/users';
import * as selectors from '~/data/selectors/users';
import type { ThunkArguments } from '~/data/store';

export const fetchUsers =
  (filter) =>
  async (dispatch, _, { api, schema, normalize }: ThunkArguments) => {
    const newFilter = filter;
    if (newFilter) {
      Object.keys(newFilter).forEach((key) => {
        if (newFilter[key] === undefined) {
          delete newFilter[key];
        }
      });
    }
    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const res = await api.get(`admin/users`, {
        params: newFilter,
        baseURL: api.prefixedURL,
      });
      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        count: res.data.count,
        payload: normalize(res.data.items, [schema.user]),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
    }
  };

export const fetchUser =
  (userId) =>
  async (dispatch, _, { api, schema, normalize }: ThunkArguments) => {
    dispatch({ type: constants.LOAD_START });
    try {
      const res = await api.get(`admin/users/${userId}`, { baseURL: api.prefixedURL });

      dispatch({
        type: constants.LOAD_SUCCESS,
        payload: normalize(res.data, schema.user),
      });
    } catch (e) {
      dispatch({ type: constants.LOAD_FAILED });
    }
  };

export const editUser =
  (values, actions, userId) =>
  async (dispatch, _, { api, schema, normalize }: ThunkArguments) => {
    dispatch({ type: constants.UPDATE_START });
    actions.setSubmitting(true);
    try {
      const res = await api.patch(`admin/users/${userId}`, values, { baseURL: api.prefixedURL });
      actions.setSubmitting(false);

      dispatch({
        type: constants.UPDATE_SUCCESS,
        payload: normalize(res.data, schema.user),
      });

      return res.data;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.UPDATE_FAILED });
      actions.setSubmitting(false);
      if (e.response && e.response.data) {
        actions.setErrors({ message: e.response.data.message });
      }
      throw e;
    }
  };

export const deleteUser =
  (userId) =>
  async (dispatch, _, { api }: ThunkArguments) => {
    dispatch({ type: constants.DELETE_USER_START, id: userId });
    try {
      await api.delete(`/admin/users/${userId}`, { baseURL: api.prefixedURL });

      dispatch({
        type: constants.DELETE_USER_SUCCESS,
        id: userId,
      });
      return true;
    } catch (e) {
      dispatch({ type: constants.DELETE_USER_FAILED });
      return false;
    }
  };

export const addUser =
  (values, actions) =>
  async (dispatch, _, { api }: ThunkArguments) => {
    dispatch({ type: constants.CREATION_START });
    actions.setSubmitting(true);
    try {
      const res = await api.post(`admin/users`, values, { baseURL: api.prefixedURL });
      actions.setSubmitting(false);
      actions.resetForm();
      dispatch({
        type: constants.CREATION_SUCCESS,
      });

      return res.data.id;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.CREATION_FAILED });
      actions.setSubmitting(false);
      if (e.response && e.response.data) {
        actions.setErrors({ message: e.response.data.message });
      }

      throw e;
    }
  };

export const fetchConnectedUsers =
  (filter, clientId) =>
  async (dispatch, _, { api, schema, normalize }: ThunkArguments) => {
    const newFilter = filter;
    if (newFilter) {
      Object.keys(newFilter).forEach((key) => {
        if (newFilter[key] === undefined) {
          delete newFilter[key];
        }
      });
    }
    dispatch({ type: constants.CONNECTED_USERS_LOAD_START });
    try {
      const res = await api.get(`admin/clients/${clientId}/users/connected`, {
        params: newFilter,
        baseURL: api.prefixedURL,
      });
      dispatch({
        type: constants.CONNECTED_USERS_LOAD_SUCCESS,
        count: res.data.count,
        payload: normalize(res.data.items, [schema.user]),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.CONNECTED_USERS_LOAD_FAILED });
    }
  };

export const fetchAvailableUsers =
  (filter, clientId) =>
  async (dispatch, _, { api, schema, normalize }: ThunkArguments) => {
    const newFilter = filter;
    if (newFilter) {
      Object.keys(newFilter).forEach((key) => {
        if (newFilter[key] === undefined) {
          delete newFilter[key];
        }
      });
    }
    dispatch({ type: constants.AVAILABLE_USERS_LOAD_START });
    try {
      const res = await api.get(`admin/clients/${clientId}/users/available`, {
        params: newFilter,
        baseURL: api.prefixedURL,
      });
      dispatch({
        type: constants.AVAILABLE_USERS_LOAD_SUCCESS,
        count: res.data.count,
        payload: normalize(res.data.items, [schema.user]),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.AVAILABLE_USERS_LOAD_FAILED });
    }
  };

export const deleteConnectedUser =
  (clientId, userId) =>
  async (dispatch, _, { api }: ThunkArguments) => {
    dispatch({ type: constants.DELETE_CONNECTED_USER_START, id: userId });
    try {
      await api.delete(`/admin/clients/${clientId}/users/${userId}`, { baseURL: api.prefixedURL });

      dispatch({
        type: constants.DELETE_CONNECTED_USER_SUCCESS,
        id: userId,
      });
      return true;
    } catch (e) {
      dispatch({ type: constants.DELETE_CONNECTED_USER_FAILED });
      return false;
    }
  };

export const connectUser =
  (clientId, email) =>
  async (dispatch, _, { api }: ThunkArguments) => {
    dispatch({ type: constants.CONNECT_USER_START });
    try {
      await api.post(`/admin/clients/${clientId}/users`, { email }, { baseURL: api.prefixedURL });

      dispatch({
        type: constants.CONNECT_USER_SUCCESS,
      });

      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.CONNECT_USER_FAILED });

      throw e;
    }
  };

export const connectUsers =
  (clientId, values) =>
  async (dispatch, _, { api }: ThunkArguments) => {
    dispatch({ type: constants.CONNECT_USERS_START }); //
    try {
      await api.post(`/admin/clients/${clientId}/users`, values, { baseURL: api.prefixedURL });

      dispatch({
        type: constants.CONNECT_USERS_SUCCESS,
      });

      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.CONNECT_USERS_FAILED }); //

      throw e;
    }
  };

export const changeUserRole =
  (userId, clientId) =>
  async (dispatch, getState, { api }: ThunkArguments) => {
    dispatch({ type: constants.CHANGE_USER_START });
    try {
      const isAdmin = selectors.getItem(getState(), userId).get('isAdmin');
      await api.patch(
        `/admin/users/${userId}/clients/${clientId}`,
        {
          isAdmin: !isAdmin,
        },
        {
          baseURL: api.prefixedURL,
        },
      );

      dispatch({
        type: constants.CHANGE_USER_SUCCESS,
        userId,
      });

      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.CHANGE_USER_FAILED });

      throw e;
    }
  };

export const clear = () => ({ type: constants.CLEAR });

export const clearSingle = () => ({ type: constants.CLEAR_SINGLE });
