import * as duckdb from '@duckdb/duckdb-wasm';
import duckdb_wasm from '@duckdb/duckdb-wasm/dist/duckdb-mvp.wasm?url';
import mvp_worker from '@duckdb/duckdb-wasm/dist/duckdb-browser-mvp.worker.js?url';
import duckdb_wasm_eh from '@duckdb/duckdb-wasm/dist/duckdb-eh.wasm?url';
import eh_worker from '@duckdb/duckdb-wasm/dist/duckdb-browser-eh.worker.js?url';

// EOS signal for Arrow IPC streaming format
import type { CompiledQuery } from 'kysely';

export const EOS = new Uint8Array([255, 255, 255, 255, 0, 0, 0, 0]);

const MANUAL_BUNDLES: duckdb.DuckDBBundles = {
  mvp: {
    mainModule: duckdb_wasm,
    mainWorker: mvp_worker,
  },
  eh: {
    mainModule: duckdb_wasm_eh,
    mainWorker: eh_worker,
  },
};
// Select a bundle based on browser checks
const bundle = await duckdb.selectBundle(MANUAL_BUNDLES);
// Instantiate the asynchronus version of DuckDB-wasm
const worker = new Worker(bundle.mainWorker!);
const logger = new duckdb.ConsoleLogger();
export const duckDB = new duckdb.AsyncDuckDB(logger, worker);
await duckDB.instantiate(bundle.mainModule, bundle.pthreadWorker);

export const connection = await duckDB.connect();

export async function executeCompiledQuery(query: CompiledQuery, connection: duckdb.AsyncDuckDBConnection) {
  const q = await connection.prepare(query.sql);
  const result = await q.query(...query.parameters);
  return result;
}
