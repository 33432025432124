import React from 'react';

class BreadcrumbsProvider extends React.Component<any> {
  // static propTypes = {
  //   children: PropTypes.node.isRequired,
  //   context: PropTypes.shape({
  //     Provider: PropTypes.object.isRequired,
  //     Consumer: PropTypes.object.isRequired,
  //   }).isRequired,
  // };
  state: any;
  constructor(props) {
    super(props);
    this.state = {
      routes: [],
    };
  }

  register = (route) => {
    this.setState((state: any) => ({ routes: [...state.routes, route] }));
  };

  unregister = (route) => {
    this.setState((state: any) => ({ routes: state.routes.filter((rt) => rt.path !== route.path) }));
  };

  update = (route) => {
    this.setState((state: any) => ({ routes: state.routes.map((rt) => (rt.path === route.path ? route : rt)) }));
  };

  render() {
    const {
      context: { Provider },
      children,
    } = this.props;
    const { routes } = this.state;
    return (
      <Provider value={{ routes, register: this.register, unregister: this.unregister, update: this.update }}>
        {children}
      </Provider>
    );
  }
}

export default BreadcrumbsProvider;
