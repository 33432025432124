const prefix = (type) => `dazzler/comparisonrows/${type}`;

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');

export const DELETE_START = prefix('DELETE_START');
export const DELETE_SUCCESS = prefix('DELETE_SUCCESS');
export const DELETE_FAILED = prefix('DELETE_FAILED');

export const CREATE_START = prefix('CREATE_START');
export const CREATE_SUCCESS = prefix('CREATE_SUCCESS');
export const CREATE_FAILED = prefix('CREATE_FAILED');

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const CLEAR = prefix('CLEAR');

export const CLEAR_SINGLE = prefix('CLEAR_SINGLE');
