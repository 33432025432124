import { refreshLoading, sessionLoading } from '~/data/reducers/auth';

export const getState = (state) => state.auth;

export const getSession = (state) => getState(state);

export const getSessionUser = (state) => getState(state).get('user');

export const getSessionToken = (state) => getState(state).get('auth_token');

export const getSessionRole = (state) => getState(state).get('role');

export const isLoggedIn = (state) => Boolean(getSessionToken(state));

export const getSessionLoading = (state) => sessionLoading.getLoading(getState(state));

export const getSessionLoaded = (state) => sessionLoading.getLoaded(getState(state));

export const getSessionLoadFailed = (state) => sessionLoading.getLoadFailed(getState(state));

export const getRefreshLoading = (state) => refreshLoading.getLoading(getState(state));
