import React, { useEffect, useState } from 'react';
import { Text } from '~/ui/index.js';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { actions, selectors, useDispatch, useSelector } from '~/data/index.js';
import { useHistory, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Search from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import { useDebounceValue } from '~/app/utils/debounce';
import Loader from '~/app/components/core/preloader';
import { userConnectedClientsPath } from '~/app/constants/url/auditor';
import ThreeDotsLoader from '~/ui/components/three-dots-loader';
import Modal from '~/ui/components/modal/index';

import s from './styles.module.scss';

interface AvailableClientsPopupProps {
  closeFunction: () => void;
}

const AvailableClientsPopup = ({ closeFunction }: AvailableClientsPopupProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId } = useParams<{ userId: string }>();

  const availableClients = useSelector(selectors.clientsAdmin.getAvailableClients);
  const isAvailableClientsLoaded = useSelector(selectors.clientsAdmin.availableClientsLoaded);
  const totalItems = useSelector(selectors.clientsAdmin.getTotalAvailableClients);

  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const [selectedClients, setSelectedClients] = useState<string[]>([]);
  const [error, setError] = useState('');

  const debouncedSearchText = useDebounceValue(search);

  const searchParams = {
    search: debouncedSearchText,
    sort: 'Name',
    direction: 'ASC',
    limit: 10,
    offset,
  };

  const fetchAvailableClients = (filter) => dispatch(actions.clientsAdmin.fetchAvailableClients(filter, userId));
  const clear = () => dispatch(actions.clientsAdmin.clear());
  const connectClients = (values) => dispatch(actions.clientsAdmin.connectClients(userId, values, setError));

  useEffect(() => {
    if (!isAvailableClientsLoaded) {
      fetchAvailableClients({ ...searchParams, offset: 0 });
      setOffset(offset + 10);
    }
    return () => {
      clear();
    };
  }, []);

  const fetchNext = () => {
    fetchAvailableClients(searchParams);
    setOffset(offset + 10);
  };

  const handleOnSearch = () => {
    clear();
    setOffset(10);
    fetchAvailableClients({ ...searchParams, offset: 0 });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = availableClients.map((n) => n.get('id')).toJS();
      setSelectedClients(newSelected);
      return;
    }
    setSelectedClients([]);
  };

  const isSelected = (id: string) => selectedClients.indexOf(id) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selectedClients.indexOf(id);
    let newSelected: string[] = [];

    switch (selectedIndex) {
      case -1:
        newSelected = newSelected.concat(selectedClients, id);
        break;

      case 0:
        newSelected = newSelected.concat(selectedClients.slice(1));
        break;

      case selectedClients.length - 1:
        newSelected = newSelected.concat(selectedClients.slice(0, -1));
        break;

      default:
        newSelected = newSelected.concat(
          selectedClients.slice(0, selectedIndex),
          selectedClients.slice(selectedIndex + 1),
        );
        break;
    }

    setSelectedClients(newSelected);
  };

  const handleConnectClients = async () => {
    if (!selectedClients.length) {
      setError('Please select client');
    } else {
      const res = await connectClients({ clientIds: selectedClients });
      // @ts-expect-error
      if (res) {
        history.push(userConnectedClientsPath(userId));
      }
    }
  };

  return (
    <Modal
      open
      closeIcon
      maxWidth="md"
      onClose={closeFunction}
      modalTitle={<Text weight={Text.weight.medium}>Add client access</Text>}
    >
      <Box marginBottom="20px" display="flex">
        <Box marginRight="20px" display="flex" flexGrow="1">
          <TextField
            placeholder="Search client"
            InputProps={{
              startAdornment: <Search htmlColor={Text.color.light} />,
            }}
            onChange={({ target: { value } }) => setSearch(value)}
            value={search}
            size="small"
            color="primary"
            fullWidth
          />
        </Box>
        <Button variant="contained" onClick={handleOnSearch}>
          Search
        </Button>
      </Box>
      <Box id="scrollableDiv" style={{ height: 400, overflow: 'auto' }}>
        <TableContainer sx={{ maxHeight: 390 }}>
          <InfiniteScroll
            dataLength={availableClients?.size || 0}
            hasMore={availableClients?.size !== totalItems}
            next={() => fetchNext()}
            loader={<ThreeDotsLoader />}
            height={390}
            style={{ width: '100%' }}
            scrollableTarget="scrollableDiv"
          >
            {!isAvailableClientsLoaded && !availableClients?.size ? (
              <Loader />
            ) : (
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell width="10%" key="checkbox">
                      <Checkbox
                        size="small"
                        color="primary"
                        indeterminate={selectedClients.length > 0 && selectedClients.length < availableClients?.size}
                        checked={availableClients?.size > 0 && selectedClients.length === availableClients?.size}
                        onChange={handleSelectAllClick}
                      />
                    </TableCell>

                    <TableCell key="clientName" width="40%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                        Client name
                      </Text>
                    </TableCell>

                    <TableCell key="VATNumber" width="40%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                        VAT Number
                      </Text>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {availableClients?.map((client) => {
                    const isItemSelected = isSelected(client.get('id'));
                    return (
                      <TableRow
                        tabIndex={-1}
                        key={client.id}
                        onClick={(event) => handleClick(event, client.get('id'))}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox size="small" color="primary" checked={isItemSelected} />
                        </TableCell>

                        <TableCell>
                          <Text size={Text.size.s}>{client.get('name')}</Text>
                        </TableCell>
                        <TableCell>
                          <Text size={Text.size.s}>{client.get('vatNumber')}</Text>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </InfiniteScroll>
        </TableContainer>
      </Box>
      <Box padding="0 16px">
        <Text size={Text.size.xs} color={Text.color.red}>
          {error}
        </Text>
      </Box>
      <Box className={s.formButtonsWrap}>
        <Button onClick={closeFunction} variant="outlined">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleConnectClients}>
          Select
        </Button>
      </Box>
    </Modal>
  );
};

export default AvailableClientsPopup;
