import React, { useEffect, useState } from 'react';
import { useDebounceValue } from '~/app/utils/debounce';
import { onPaginationHelper } from '~/app/utils/pagination-helpers';
import { onSortingHelper } from '~/app/utils/sorting-helpers';
import { actions, Roles, selectors } from '~/data/index.js';
import { useAppDispatch, useAppSelector } from '~/data/utils/hooks';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { manageClientPagePath, manageClientPath } from '~/app/constants/url/auditor';
import { Row, SelectInput, Text } from '~/ui/index.js';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import Search from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from '@mui/material';
import TableRowsLoader from '~/ui/components/mui-table/table-loader';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import TablePagination from '~/ui/components/table-pagination/index';
import { ChangeRolePopup, DeleteUserPopup, EditClientPopUp, AvailableUsersPopup } from '../actions/index';

import s from './styles.module.scss';

const options = [
  { value: { key: 'LastUpdated', direction: 'DESC' }, label: 'Recent update' },
  { value: { key: 'FamilyName', direction: 'ASC' }, label: 'Alphanumeric' },
];

const ConnectedUsers = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { page, clientId } = useParams<{ page: string; clientId: string }>();

  const connectedUsers = useAppSelector(selectors.users.getConnectedUsers);
  const isConnectedUsersLoaded = useAppSelector(selectors.users.connectedUsersLoaded);
  const totalItems = useAppSelector(selectors.users.getTotalConnectedUsers);
  const isRoleChanging = useAppSelector(selectors.users.getRoleChanging);
  const role = useAppSelector(selectors.auth.getSessionRole);
  const client = useAppSelector((state) => selectors.clientsAdmin.getItem(state, clientId));

  const fetchConnectedUsers = (filter) => dispatch(actions.users.fetchConnectedUsers(filter, clientId));
  const clear = () => dispatch(actions.users.clear());
  const deleteConnectedUser = (userId) => dispatch(actions.users.deleteConnectedUser(clientId, userId));
  const changeUserRole = (userId) => dispatch(actions.users.changeUserRole(userId, clientId));
  const editClient = (values, formikActions) => dispatch(actions.clientsAdmin.editClient(values, formikActions));

  const [inputValue, setInputValue] = useState('');
  const [sort, setSort] = useState({ key: 'FamilyName', direction: 'asc' });
  const [perPage, setPerPage] = useState(10);
  const [userToDelete, setUserToDelete] = useState(null);
  const [isChangingRoleShown, setIsChangingRoleShown] = useState(false);
  const [userIdToChangeRole, setUserIdToChangeRole] = useState(null);
  const [isEditClientPopupShown, setIsEditClientPopupShown] = useState(false);
  const [showAvailableUsersPopup, setShowAvailableUsersPopup] = useState(false);

  const debouncedSearchText = useDebounceValue(inputValue);

  const queryParams = {
    search: debouncedSearchText,
    sort: sort.key,
    direction: sort.direction,
    limit: perPage,
    offset: Number(page) > 1 ? (Number(page) - 1) * perPage : 0,
  };

  useEffect(() => {
    fetchConnectedUsers(queryParams);

    return () => {
      clear();
    };
  }, [debouncedSearchText, sort, page, perPage]);

  const onSearch = (search) => {
    setInputValue(search);
  };

  const onPagination = (e) => {
    setPerPage(e.target.value);
    onPaginationHelper(e.target);
  };

  const onSort = (newSort) => {
    setSort(newSort);
    onSortingHelper(newSort.key, newSort.direction);
  };

  const handleDelete = (user) => {
    setUserToDelete(user);
  };

  const toggleDelete = () => {
    setUserToDelete(null);
  };

  const onDelete = async (userId) => {
    if (await deleteConnectedUser(userId)) {
      history.push(manageClientPath(clientId));
    }
  };

  const handleChangingRole = (userId) => {
    setIsChangingRoleShown(true);
    setUserIdToChangeRole(userId);
  };

  const toggleChangingRole = () => {
    setIsChangingRoleShown(!isChangingRoleShown);
  };

  const changeRole = async (userId) => {
    if (await changeUserRole(userId)) {
      toggleChangingRole();
    }
  };

  if (isConnectedUsersLoaded && connectedUsers.size === 0 && Number(page) > 1) {
    return <Redirect to={manageClientPagePath(clientId, Math.ceil(totalItems / perPage))} />;
  }

  const isSuperAdmin = role === Roles.ADMIN;

  return (
    <div className={s.clients}>
      <Row className={s.clientsRow}>
        <Box display="flex" alignItems="center">
          <Text size={Text.size.xl} weight={Text.weight.semiMedium} color={Text.color.colorTextDark}>
            <strong> {client.get('name')}</strong>
          </Text>
          <Button
            className={s.editButton}
            onClick={() => setIsEditClientPopupShown(true)}
            color="primary"
            startIcon={<BorderColorRoundedIcon />}
            size="medium"
          >
            <Text color={Text.color.orange} size={Text.size.s} weight={Text.weight.medium}>
              EDIT
            </Text>
          </Button>
        </Box>
      </Row>
      <Box marginBottom="18px">
        <Text>Connected users</Text>
      </Box>

      <Box display="flex" justifyContent="space-between" marginBottom="14px">
        <Box display="flex">
          <Box width={345} marginRight="10px">
            <TextField
              placeholder="Search user"
              InputProps={{
                startAdornment: <Search htmlColor={theme.palette.secondary.light} />,
              }}
              onChange={({ target: { value } }) => onSearch(value)}
              value={inputValue}
              size="small"
              color="primary"
              fullWidth
            />
          </Box>
          <Box width={250}>
            <SelectInput
              name="sort-clients"
              options={options}
              value={options.filter((option) => option.value.key === sort.key)}
              onChange={(e) => onSort(e.value)}
            />
          </Box>
        </Box>

        {isSuperAdmin && (
          <Button
            variant="contained"
            onClick={() => setShowAvailableUsersPopup(true)}
            startIcon={<AddCircleOutlineOutlinedIcon />}
          >
            Add user
          </Button>
        )}
      </Box>

      <Box>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="25%">
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      Name
                    </Text>
                  </TableCell>
                  <TableCell width="25%">
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      Family name
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      Email
                    </Text>
                  </TableCell>

                  {isSuperAdmin && (
                    <TableCell>
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                        Admin role
                      </Text>
                    </TableCell>
                  )}

                  <TableCell>
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      Action
                    </Text>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isConnectedUsersLoaded && !isRoleChanging ? (
                  connectedUsers.map((user) => (
                    <TableRow key={user.get('id')}>
                      <TableCell width="25%">
                        <Text size={Text.size.s} font={Text.color.colorTextMedium} weight={Text.weight.semiMedium}>
                          {user.get('firstName') ? user.get('firstName') : 'No name'}
                        </Text>
                      </TableCell>
                      <TableCell width="25%">
                        <Text size={Text.size.s} font={Text.color.colorTextMedium} weight={Text.weight.semiMedium}>
                          {user.get('familyName') ? user.get('familyName') : 'No family name'}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text size={Text.size.s} font={Text.color.colorTextMedium} weight={Text.weight.semiMedium}>
                          {user.get('email')}
                        </Text>
                      </TableCell>

                      {isSuperAdmin && (
                        <TableCell>
                          <Switch
                            checked={user.get('isAdmin')}
                            id={user.get('id')}
                            onChange={() => handleChangingRole(user.get('id'))}
                            size="small"
                          />
                        </TableCell>
                      )}

                      <TableCell>
                        <Button
                          variant="text"
                          onClick={() => handleDelete(user)}
                          disabled={user.get('clientUsers').first().get('type') !== 'Manual'}
                          className={s.clientsRowButton}
                        >
                          <Text size={Text.size.s}>Remove</Text>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRowsLoader rowsNum={perPage} colsNum={isSuperAdmin ? 5 : 4} />
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {isConnectedUsersLoaded && !!connectedUsers.size && (
            <div className={s.clientsPagination}>
              <TablePagination
                totalItems={totalItems}
                perPage={perPage}
                page={page || 1}
                handleChangePage={(p) => {
                  history.push(manageClientPagePath(clientId, p + 1));
                }}
                onRowsPerPageChange={onPagination}
              />
            </div>
          )}
        </Paper>
      </Box>

      {isChangingRoleShown && (
        <ChangeRolePopup closeFunction={toggleChangingRole} handleChangeRole={() => changeRole(userIdToChangeRole)} />
      )}

      {userToDelete && (
        <DeleteUserPopup
          closeFunction={toggleDelete}
          // @ts-expect-error - Property 'get' does not exist on type 'never'.
          userName={userToDelete.get('name')}
          // @ts-expect-error - Property 'get' does not exist on type 'never'.
          handleDeleteUser={() => onDelete(userToDelete.get('id'))}
        />
      )}

      {isEditClientPopupShown && (
        <EditClientPopUp handleClose={() => setIsEditClientPopupShown(false)} editClient={editClient} client={client} />
      )}

      {showAvailableUsersPopup && (
        <AvailableUsersPopup
          closeFunction={() => {
            setShowAvailableUsersPopup(false);
            history.push(manageClientPath(clientId));
          }}
        />
      )}
    </div>
  );
};

export default ConnectedUsers;
