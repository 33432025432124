import { useMemo } from 'react';
import {
  Button,
  Chip,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { Link, useHistory, useParams } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { Show } from '~/app/components/core/show';
import { clientCompareFilesPath, comparisonDetails } from '~/app/constants/url/auditor';
import { SorterDirectionParams } from '~/data/query-params';
import Row from '~/ui/components/grid/row';
import dayjs from 'dayjs';
import { useListClientComparisons } from '~/data/comparisons/queries';
import cx from '~/data/utils/helpers/cx';

const Comparison = () => {
  const { clientId } = useParams<{ clientId?: string }>();
  const history = useHistory();

  const [params, setParams] = useQueryParams({
    limit: withDefault(NumberParam, 5),
    offset: withDefault(NumberParam, 0),
    Sort: withDefault(StringParam, 'inserted'),
    Direction: withDefault(SorterDirectionParams, 'DESC'),
  });

  const { data: comparisonList, isInitialLoading: isComparisonsLoading } = useListClientComparisons(
    clientId ?? '',
    {
      ...params,
      direction: params.Direction ?? undefined,
    },
    { refetchOnMount: true },
  );

  const comparisons = comparisonList?.items ?? [];

  const skeletonRows = useMemo(
    () =>
      new Array(params.limit).fill(undefined).map(() => (
        <TableRow>
          <>
            {new Array(5).fill(undefined).map(() => (
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
            ))}
          </>
        </TableRow>
      )),
    [params.limit],
  );

  return (
    <div>
      <Row className="justify-between">
        <Typography fontSize={24} component="h1" className="text-dark">
          Comparison
        </Typography>
        <Button
          size="large"
          variant="outlined"
          onClick={() => {
            history.push(clientCompareFilesPath(clientId));
          }}
        >
          Compare files
        </Button>
      </Row>
      <Paper classes={{ root: 'rounded-lg mt-8' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="32%" key="file1">
                  <Typography fontSize={16} className="text-medium ml-8">
                    File 1
                  </Typography>
                </TableCell>
                <TableCell width="32%" key="file2">
                  <Typography fontSize={16} className="text-medium ml-8">
                    File 2
                  </Typography>
                </TableCell>
                <TableCell width="12%" key="status">
                  <Typography fontSize={16} className="text-medium ml-8">
                    Status
                  </Typography>
                </TableCell>
                <TableCell width="12%" key="createdBy">
                  <Typography fontSize={16} className="text-medium ml-8">
                    Created by
                  </Typography>
                </TableCell>
                <TableCell width="12%" key="date">
                  <Typography fontSize={16} className="text-medium ml-8">
                    Date
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {comparisons.length === 0 && !isComparisonsLoading && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography fontSize={16} className="ml-8">
                      no records found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {comparisons.map((item) => (
                <TableRow hover={true} component={Link} to={comparisonDetails(clientId, item.id)}>
                  <TableCell style={{ maxWidth: 400 }}>
                    <Tooltip title={item.leftFile?.name}>
                      <Typography fontSize={16} className="text-medium ml-8 truncate">
                        {item.leftFile?.name}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ maxWidth: 400 }}>
                    <Tooltip title={item.rightFile?.name}>
                      <Typography fontSize={16} className="text-medium ml-8 truncate">
                        {item.rightFile?.name}
                      </Typography>
                    </Tooltip>
                  </TableCell>

                  <TableCell>
                    <Typography fontSize={16} className="text-medium ml-8">
                      <Chip
                        label={item.status}
                        className={cx(
                          (() => {
                            if (item.status === 'Success') return 'bg-status-success';
                            if (item.status === 'Error') return 'bg-status-error';
                            return 'bg-grey';
                          })(),
                          'text-white',
                        )}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={16} className="text-medium ml-8 truncate">
                      {`${item.creator?.firstName} ${item.creator?.familyName}`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={16} className="text-medium ml-8">
                      {dayjs(new Date(item.inserted ?? 0)).format('DD/MM/YYYY')}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
              <Show when={isComparisonsLoading}>
                <>{skeletonRows}</>
              </Show>
            </TableBody>
          </Table>
        </TableContainer>

        <Show when={params.limit < (comparisonList?.count ?? 0) || isComparisonsLoading}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={comparisonList?.count ?? 0}
            rowsPerPage={params.limit}
            page={params.offset / params.limit}
            onPageChange={(e, page) => {
              setParams({
                offset: page * params.limit,
              });
            }}
            onRowsPerPageChange={(e) => {
              setParams({
                limit: +e.target.value,
              });
            }}
          />
        </Show>
      </Paper>
    </div>
  );
};

export default Comparison;
