import { DummyDriver, Kysely, SqliteAdapter, SqliteIntrospector, SqliteQueryCompiler } from 'kysely';
import type * as visualExcel from '~/app/components/visual-excel/db';
import type * as comparisons from '~/app/components/pages/auditor/clients/client/comparisons/[comparisonId]/db';

export interface Database {
  [key: comparisons.TableName]: comparisons.ComparisonRowsTable;
  [key: visualExcel.TableName]: visualExcel.VisualExcelSheet;
}
export const db = new Kysely<Database>({
  dialect: {
    createAdapter() {
      return new SqliteAdapter();
    },
    createDriver() {
      return new DummyDriver();
    },
    createIntrospector(db: Kysely<unknown>) {
      return new SqliteIntrospector(db);
    },
    createQueryCompiler() {
      return new SqliteQueryCompiler();
    },
  },
});
