import React from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDownloadComparisonLogs } from '~/data/downloads/queries';
import { DownloadProgress, Options as KyOptions } from 'ky';
import { useGetComparison } from '~/data/comparisons/queries';
import { Match, Switch } from '~/app/components/core/switch';

function ComparisonLogsPage() {
  const { clientId, comparisonId } = useParams<{ clientId: string; comparisonId: string }>();

  const { data: comparison, isInitialLoading: isLoading } = useGetComparison(clientId, comparisonId);
  const { mutate: downloadLogs, isLoading: isDownloading } = useDownloadComparisonLogs();

  const logDownloadProgress: KyOptions['onDownloadProgress'] = (progress: DownloadProgress, _: Uint8Array) => {
    console.log({ progress });
  };

  return (
    <section data-name="comparison-file-logs-page">
      <Typography fontSize={24} component="h1" className="mb-5">
        Processing logs
      </Typography>

      <div>
        <Typography className="mb-4">
          Comparison between {comparison?.leftFile?.name} and {comparison?.rightFile?.name}
        </Typography>
        <Switch>
          <Match when={isDownloading === true}>
            <Typography>
              <CircularProgress size={16} className="mr-1" /> Downloading
            </Typography>
          </Match>
          <Match when={isDownloading === false}>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              className="mb-4"
              onClick={() => downloadLogs({ clientId, comparisonId, onDownloadProgress: logDownloadProgress })}
            >
              Download logs
            </LoadingButton>
          </Match>
        </Switch>
      </div>
    </section>
  );
}

export default ComparisonLogsPage;
