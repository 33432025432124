import React from 'react';
import { AnyPropsType } from '~/AnyPropsType';
import { Text } from '~/ui/index.js';
import { Box, Button } from '@mui/material';
import Modal from '~/ui/components/modal/index';

const ChangeRolePopup = ({ closeFunction, handleChangeRole }: AnyPropsType) => (
  <Modal
    open
    closeIcon
    onClose={closeFunction}
    modalTitle={<Text weight={Text.weight.medium}>Change user role </Text>}
    contentText={<Text size={Text.size.m}>Are you sure you want to change user role?</Text>}
  >
    <Box display="flex" justifyContent="flex-end">
      <Box marginRight="15px">
        <Button onClick={closeFunction} variant="outlined">
          Close
        </Button>
      </Box>
      <Button onClick={handleChangeRole} variant="contained">
        Change
      </Button>
    </Box>
  </Modal>
);

export default ChangeRolePopup;
