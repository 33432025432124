import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetComparison } from '~/data/comparisons/queries';
import {
  ComparisonField,
  toDisplayedRows,
  useDownloadComparisonRows,
  useDownloadDisplayedRows,
  useFetchComparisonRows,
  useGridParameters,
} from './hooks';
import * as db from './db';
import { ComparisonStatus, Severity } from '~/data/openapi-client/index';

import hoursToMilliseconds from 'date-fns/hoursToMilliseconds';
import Breadcrumbs from '~/app/components/core/breadcrumbs/index';
import { comparisonDetails as comparisonDetailsPath } from '~/app/constants/url/auditor';
import Row from '~/ui/components/grid/row';
import Typography from '@mui/material/Typography';
import { Show } from '~/app/components/core/show';
import { Alert, AlertTitle } from '@mui/material';
import ComparisonDetailsProgress from '~/app/components/pages/auditor/clients/client/report/report/ComparisonDetailsProgress';
import { Match, Switch } from '~/app/components/core/switch';
import { Grid, GridColumn as Column, type GridDataStateChangeEvent, type GridHandle } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { DataResult } from '@progress/kendo-data-query';
import '@progress/kendo-theme-default/dist/all.css';
import { filterIcon } from '@progress/kendo-svg-icons';
import { Downloads } from './downloads';
import { ColumnMenu } from './column-menu';

function ComparisonDetailsPage() {
  const { clientId, comparisonId } = useParams<{ clientId?: string; comparisonId?: string }>();
  const { data: comparisonDetails } = useGetComparison(clientId ?? '', comparisonId ?? '');
  const { isSuccess: isParquetLoaded, isInitialLoading: isParquetLoading } = useDownloadComparisonRows(
    clientId ?? '',
    comparisonId ?? '',
    {
      enabled: !!clientId && !!comparisonId && comparisonDetails?.status === ComparisonStatus.Success,
    },
  );

  const exportRef = React.useRef<ExcelExport | null>(null);
  const gridRef = React.useRef<GridHandle | null>(null);

  const locale = window.navigator.language;
  const formatter = useMemo(() => new Intl.NumberFormat(locale), [locale]);

  const { mutate: downloadDisplayedRows } = useDownloadDisplayedRows();

  const [{ offset, limit, sort, direction, filter }, setParameters] = useGridParameters();

  const queryFilters = {
    offset,
    limit,
    sort,
    direction,
    filter,
  };

  const exportToExcel = () => {
    if (!exportRef.current) return;

    const columns = gridRef.current?.columns.map((c) => ({
      ...c,
      width: Number(c),
    }));

    downloadDisplayedRows({
      comparisonId: comparisonId!,
      filters: queryFilters,
      formatter,
      saveExcel: (rows) => exportRef.current?.save(rows, columns),
    });
  };

  const {
    data: comparisonRows,
    isInitialLoading: isDataLoading,
    isSuccess: isRowsLoaded,
  } = useFetchComparisonRows(clientId!, comparisonId ?? '', queryFilters, {
    keepPreviousData: true,
    staleTime: hoursToMilliseconds(8),
    enabled: isParquetLoaded,
  });

  const rows = comparisonRows != null ? toDisplayedRows(comparisonRows.items, formatter) : [];

  const gridData = {
    data: rows ?? [],
    total: comparisonRows?.count ?? 0,
  } satisfies DataResult;

  const handleDataStateChange = (event: GridDataStateChangeEvent) => {
    setParameters({
      offset: event.dataState.skip,
      limit: event.dataState.take,
      sort: event.dataState.sort?.at(0)?.field,
      direction: event.dataState.sort?.at(0)?.dir,
      filter: event.dataState.filter,
    });
  };

  return (
    <section data-page="comparison-details">
      <Breadcrumbs.Anchor
        path={comparisonDetailsPath(clientId ?? '', comparisonId ?? '')}
        link={comparisonDetailsPath(clientId, comparisonId)}
        component={<span>Details</span>}
      />
      <div className="flex items-center justify-between">
        <div>
          <Row className="justify-between">
            <Typography fontSize={24} component="h1" className="text-dark">
              Compare files
            </Typography>
          </Row>
          <Typography fontSize={14} className="text-light">
            Comparison between 2 versions of the same type of report
          </Typography>
        </div>
        <Show when={comparisonDetails?.status === ComparisonStatus.Success}>
          <Downloads clientId={clientId!} comparisonId={comparisonId!} downloadDisplayedRows={exportToExcel} />
        </Show>
      </div>
      <div className="flex w-full mt-4 gap-4 items-center">
        <div className="basis-1/2">
          <Typography fontSize={14} className="text-light">
            Left file
          </Typography>
          <div className="p-5 bg-white rounded-md">
            <li className="bg-secondary-main justify-between items-center px-4 py-2 rounded-md grid grid-cols-[1fr_auto] w-full">
              <span className="truncate">{comparisonDetails?.leftFile?.name}</span>
            </li>
          </div>
        </div>
        <div className="basis-1/2">
          <Typography fontSize={14} className="text-light">
            Right File
          </Typography>
          <div className="p-5 bg-white rounded-md flex items-center gap-2">
            <li className="bg-secondary-main justify-between items-center px-4 py-2 rounded-md grid grid-cols-[1fr_auto] w-full">
              <span className="truncate">{comparisonDetails?.rightFile?.name}</span>
            </li>
          </div>
        </div>
      </div>
      <Switch>
        <Match when={comparisonDetails?.status === ComparisonStatus.Processing}>
          <ComparisonDetailsProgress />
        </Match>
        <Match when={comparisonDetails?.status === ComparisonStatus.Error}>
          <Alert severity="error" className="mt-5 text-status-error">
            <AlertTitle>Processing comparison failed due to the following reasons:</AlertTitle>
            <ul>
              {Array.from(
                new Set(
                  comparisonDetails?.processingLogs
                    ?.filter((log) => log.severity === Severity.Error)
                    .map((log) => log.message),
                ),
              ).map((msg) => (
                <li>{msg}</li>
              ))}
            </ul>
          </Alert>
        </Match>
        <Match when={comparisonDetails?.status === ComparisonStatus.Success}>
          <div className="pt-3">
            <ExcelExport ref={exportRef} />
            <Grid
              ref={gridRef}
              data={gridData}
              pageable={true}
              resizable={true}
              sortable={{
                mode: 'single',
              }}
              sort={[
                {
                  field: sort!,
                  dir: direction as db.Direction,
                },
              ]}
              filter={filter}
              skip={offset}
              take={limit}
              total={gridData.total}
              onDataStateChange={handleDataStateChange}
              columnMenuIcon={filterIcon}
            >
              <Column columnMenu={ColumnMenu} field={ComparisonField('TableCode')} title="Table" width={140} />
              <Column columnMenu={ColumnMenu} field={ComparisonField('RowCode')} title="Row" width={140} />
              <Column columnMenu={ColumnMenu} field={ComparisonField('ColumnCode')} title="Column" width={140} />
              <Column columnMenu={ColumnMenu} field={ComparisonField('ZAxis')} title="zAxis" />
              <Column
                columnMenu={ColumnMenu}
                field={ComparisonField('LeftValue')}
                title={comparisonDetails?.leftFile?.name ?? 'Left file'}
                filter="numeric"
                minResizableWidth={140}
              />
              <Column
                columnMenu={ColumnMenu}
                field={ComparisonField('RightValue')}
                title={comparisonDetails?.rightFile?.name ?? 'Right file'}
                filter="numeric"
                minResizableWidth={140}
              />
              <Column
                columnMenu={ColumnMenu}
                field={ComparisonField('RelativeDifference')}
                title="Difference (R - L)"
                filter="numeric"
                minResizableWidth={140}
              />
              <Column
                columnMenu={ColumnMenu}
                field={ComparisonField('DifferencePercent')}
                title="%"
                filter="numeric"
                minResizableWidth={140}
              />
            </Grid>
          </div>
        </Match>
      </Switch>
    </section>
  );
}

export default ComparisonDetailsPage;
