import React, { useEffect } from 'react';
import Breadcrumbs from '~/app/components/core/breadcrumbs/index';
import Loader from '~/app/components/core/preloader';
import { manageClientPagePath, manageClientPath } from '~/app/constants/url/auditor';
import { actions, selectors } from '~/data/index.js';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import ConnectedUsers from './ConnectedUsers';
import { useAppDispatch, useAppSelector } from '~/data/utils/hooks';

const ReportsRoute = () => {
  const dispatch = useAppDispatch();
  const { clientId = '' } = useParams<{ clientId: string }>();

  const client = useAppSelector((state) => selectors.clientsAdmin.getItem(state, clientId));
  const isClientLoaded = useAppSelector(selectors.clientsAdmin.itemLoaded);

  const fetchClient = () => dispatch(actions.clientsAdmin.fetchClient(clientId));
  const clear = () => dispatch(actions.clientsAdmin.clear());

  useEffect(() => {
    if (!isClientLoaded) {
      fetchClient();
    }
    return () => {
      clear();
    };
  }, []);

  return isClientLoaded ? (
    <>
      <Breadcrumbs.Anchor
        path={manageClientPath(':clientId')}
        link={manageClientPath(clientId)}
        component={<span title="Client">{isClientLoaded ? client?.get('name') : `Client ${clientId}`}</span>}
      />

      <Switch>
        <Route path={manageClientPagePath(':clientId', ':page([1-9]\\d*)')} component={ConnectedUsers} />
        <Redirect from={manageClientPath(clientId)} to={manageClientPagePath(clientId, 1)} />
      </Switch>
    </>
  ) : (
    <Loader />
  );
};

export default ReportsRoute;
