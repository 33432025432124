import cx from 'classnames';
import React from 'react';
import { Icon } from '~/ui/components/icon/index';

import { Cell } from './cell';
import s from './styles.module.scss';

type headerCellProps = {
  className?: string;
  name?: string;
  children?: any;
  onSort?: (name, direction: 'desc' | 'asc') => any;
  width?: string;
  sort?: {
    key: string;
    direction: string;
  };
};

class HeaderCell extends React.Component<headerCellProps> {
  static defaultProps = {
    className: null,
    children: null,
  };

  handleSortClick = (name, direction) => {
    const { onSort } = this.props;
    if (onSort) {
      onSort(name, direction);
    }
  };

  render() {
    const { className, children, name, sort, ...props } = this.props;
    const asc = sort && sort.direction === 'asc' && sort.key === name;
    const desc = sort && sort.direction === 'desc' && sort.key === name;

    return (
      <Cell
        className={cx(s.tableHeaderCell, className)}
        onClick={() => this.handleSortClick(name, asc ? 'desc' : 'asc')}
        {...props}
      >
        <div className={s.tableHeaderCellContentText}>{children}</div>
        {sort && (
          <div className={cx(s.tableHeaderCellContentSquare, !name && s.tableHeaderCellContentSquareHide)}>
            <Icon
              icon={Icon.icons.chevronUp}
              className={cx(
                s.tableHeaderCellContentSquareIcon,
                asc && s.tableHeaderCellContentSquareIconActive,
                !name && s.tableHeaderCellContentSquareHide,
              )}
            />
            <Icon
              icon={Icon.icons.chevronDown}
              className={cx(
                s.tableHeaderCellContentSquareIcon,
                desc && s.tableHeaderCellContentSquareIconActive,
                !name && s.tableHeaderCellContentSquareHide,
              )}
            />
          </div>
        )}
      </Cell>
    );
  }
}

export { HeaderCell };
