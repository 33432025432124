import { AnyPropsType } from '~/AnyPropsType';
import cx from 'classnames';
import React from 'react';

import s from '../styles.module.scss';

const HeaderMenu = ({ className, style, children, ...props }: AnyPropsType) => (
  <div {...props} className={cx(s.footerTopNavMenu, className)} style={style}>
    {children}
  </div>
);

HeaderMenu.defaultProps = {
  style: null,
  className: null,
  children: null,
};

// HeaderMenu.propTypes = {
//   /* eslint-disable-next-line */
//   style: PropTypes.object,
//   className: PropTypes.string,
//   children: PropTypes.node,
// };

export default HeaderMenu;
