import API from '~/data/api';
import reducers from '~/data/reducers';
import { jwtCheck } from '~/data/utils/jwt-middleware';
import * as schema from '~/data/utils/schemas';
import Immutable from 'immutable';
import { normalize } from 'normalizr';
import { applyMiddleware, createStore as reduxCreateStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import { api } from '~/data/dazzler-api';
import { baseURL } from '~/config';

const composeEnhancers = composeWithDevTools({
  serialize: {
    immutable: Immutable,
  },
} as any);

export type ThunkArguments = {
  schema: typeof schema;
  normalize: typeof normalize;
  api: API;
};

const thunkExtraArguments: ThunkArguments = {
  schema,
  normalize,
  api: undefined!,
};

const actionTimestamp = () => (next) => (action) =>
  next({
    ...action,
    timestamp: new Date(),
  });

const store = reduxCreateStore(
  reducers,
  composeEnhancers(
    applyMiddleware(jwtCheck, thunk.withExtraArgument(thunkExtraArguments), actionTimestamp) as any,
  ) as any,
);

thunkExtraArguments.api = api;
api.attachReduxStore(store);
api.setAxiosURL(baseURL.toString());

export type AppDispatch = typeof store.dispatch;

export default store;
