import history from '../history';

export const onPaginationHelper = (value: any) => {
  const params = new URLSearchParams(window.location.search);
  params.set('perPage', value.value);
  history.push({ search: params.toString() });
};

export const initPagination = (component: React.Component<any, any>) => {
  const params = new URLSearchParams(window.location.search);
  const s: any = component.state;
  s.perPage = Number(params.get('perPage') || component.state.perPage || 10);
};

export const onTablePaginationHelper = (value: number) => {
  const params = new URLSearchParams(window.location.search);
  params.set('perPage', value.toString());
  history.push({ search: params.toString() });
};
