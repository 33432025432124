const prefix = (type) => `dazzler/client/${type}`;

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');

export const UPDATE_START = prefix('UPDATE_START');
export const UPDATE_SUCCESS = prefix('UPDATE_SUCCESS');
export const UPDATE_FAILED = prefix('UPDATE_FAILED');

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const LIST_UPDATE_START = prefix('LIST_UPDATE_START');
export const LIST_UPDATE_SUCCESS = prefix('LIST_UPDATE_SUCCESS');
export const LIST_UPDATE_FAILED = prefix('LIST_UPDATE_FAILED');

export const DASHBOARD_LOAD_START = prefix('DASHBOARD_LOAD_START');
export const DASHBOARD_LOAD_SUCCESS = prefix('DASHBOARD_LOAD_SUCCESS');
export const DASHBOARD_LOAD_FAILED = prefix('DASHBOARD_LOAD_FAILED');

export const REPORT_DOWNLOAD_START = prefix('REPORT_DOWNLOAD_START');
export const REPORT_DOWNLOAD_SUCCESS = prefix('REPORT_DOWNLOAD_SUCCESS');
export const REPORT_DOWNLOAD_FAILED = prefix('REPORT_DOWNLOAD_FAILED');

export const DOWNLOAD_REQUEST_START = prefix('DOWNLOAD_REQUEST_START');
export const DOWNLOAD_REQUEST_SUCCESS = prefix('DOWNLOAD_REQUEST_SUCCESS');
export const DOWNLOAD_REQUEST_FAILED = prefix('DOWNLOAD_REQUEST_FAILED');

export const POLL_STATUS_START = prefix('POLL_STATUS_START');
export const POLL_STATUS_SUCCESS = prefix('POLL_STATUS_SUCCESS');
export const POLL_STATUS_FAILED = prefix('POLL_STATUS_FAILED');

export const CLEAR_DOWNLOADING_PROGRESS = prefix('CLEAR_DOWNLOADING_PROGRESS');

export const CLEAR = prefix('CLEAR');

export const CLEAR_SINGLE = prefix('CLEAR_SINGLE');
