import { reportComparisonsPagePath } from '~/app/constants/url/auditor';
import { useDebounceValue } from '~/app/utils/debounce';
import { onFilterHelper } from '~/app/utils/filter-helpers';
import { onPaginationHelper } from '~/app/utils/pagination-helpers';
import { onSortingHelper } from '~/app/utils/sorting-helpers';
import { actions, selectors } from '~/data/index.js';
import { useAppDispatch, useAppSelector } from '~/data/utils/hooks';
import { ComparisonStatus } from '~/data/utils/types';
import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { ComparisonRows } from './ComparisonsTable';

const ComparisonsList = () => {
  const dispatch = useAppDispatch();
  const { clientId, reportId, comparisonId, page } = useParams<{
    clientId: string;
    reportId: string;
    comparisonId: string;
    page: string;
  }>();

  const client = useAppSelector((state) => selectors.clients.getItem(state, clientId));
  const isClientLoaded = useAppSelector(selectors.clients.itemLoaded);
  const report = useAppSelector((state) => selectors.reports.getItem(state, reportId));
  const isReportLoaded = useAppSelector(selectors.reports.itemLoaded);
  const isComparisonLoaded = useAppSelector(selectors.comparisons.itemLoaded);
  const isComparisonRowsLoaded = useAppSelector(selectors.comparisonrows.itemsLoaded);
  const comparison = useAppSelector((state) => selectors.comparisons.getItem(state, comparisonId));
  const count = useAppSelector(selectors.comparisonrows.getFilteredItems);
  const totalCount = useAppSelector(selectors.comparisonrows.getTotalItems);
  const comparisonRows = useAppSelector(selectors.comparisonrows.getItems);

  const fetchComparison = () => dispatch(actions.comparisons.fetchComparison(comparisonId));
  const fetchComparisonRows = (filter) => dispatch(actions.comparisons.fetchComparisonRows(comparisonId, filter));
  const downloadComparisonExcel = (id, name) => {
    throw new Error('Use client comparisons instead');
  };

  const clearComparisons = () => dispatch(actions.comparisons.clear());
  const clearRows = () => dispatch(actions.comparisons.clearRows());

  let pollingHandle: number;

  const [sort, setSort] = useState({ key: 'TableCode', direction: 'asc' });
  const [perPage, setPerPage] = useState(10);
  const [filters, setFilters] = useState({ minDifference: null, tableCode: null, columnCode: null, rowCode: null });

  const debouncedFilters = useDebounceValue(filters);

  const queryParams = {
    sort: sort.key,
    direction: sort.direction,
    limit: perPage,
    offset: Number(page) > 1 ? (Number(page) - 1) * perPage : 0,
    minDifference: debouncedFilters.minDifference,
    tableCode: debouncedFilters.tableCode,
    columnCode: debouncedFilters.columnCode,
    rowCode: debouncedFilters.rowCode,
  };

  const pollStatus = () => {
    if (
      comparison &&
      comparison?.get('status') !== ComparisonStatus.Finished &&
      comparison?.get('status') !== ComparisonStatus.Failed &&
      !comparison?.getIn(['statusInfo', 'isFailed'])
    ) {
      fetchComparison();
      fetchComparisonRows(queryParams);
    }
  };

  useEffect(() => {
    fetchComparison();
    fetchComparisonRows(queryParams);
    pollingHandle = window.setInterval(pollStatus, 30000);
    return () => {
      clearComparisons();
      clearRows();

      if (pollingHandle) {
        window.clearInterval(pollingHandle);
        pollingHandle = 0 as any;
      }
    };
  }, []);

  useEffect(() => {
    fetchComparisonRows(queryParams);
  }, [page, sort, perPage, debouncedFilters]);

  const onSort = (key) => {
    setSort({ key, direction: sort.direction === 'desc' ? 'asc' : 'desc' });
    onSortingHelper(key, sort.direction === 'desc' ? 'asc' : 'desc');
  };

  const onPagination = (e) => {
    setPerPage(e.target.value);
    onPaginationHelper(e.target);
  };

  const onFilter = (key: string, value: string, id?: string) => {
    setFilters((f) => ({ ...f, [key]: value }));
    onFilterHelper(key, value, id);
  };

  const onDownloadExcel = (id, name) => {
    downloadComparisonExcel(id, name);
  };

  if (isComparisonRowsLoaded && comparisonRows.size === 0 && Number(page) > 1) {
    return <Redirect to={reportComparisonsPagePath(clientId, reportId, comparisonId, Math.ceil(count / perPage))} />;
  }

  return (
    <ComparisonRows
      report={report}
      client={client}
      isReportLoaded={isReportLoaded}
      isClientLoaded={isClientLoaded}
      isComparisonLoaded={isComparisonLoaded}
      isComparisonRowsLoaded={isComparisonRowsLoaded}
      comparison={comparison}
      comparisonRows={comparisonRows}
      totalCount={totalCount}
      count={count}
      page={page}
      perPage={perPage}
      sort={sort}
      onSort={onSort}
      onPagination={onPagination}
      onFilter={onFilter}
      onDownloadExcel={onDownloadExcel}
      filters={filters}
    />
  );
};

export default ComparisonsList;
