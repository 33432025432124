import cx from 'classnames';
import React from 'react';

import s from './styles.module.scss';

// import PropTypes from 'prop-types';
class TextArea extends React.Component<any> {
  textarea: any;

  handleResize = (...args) => {
    const { field } = this.props;
    this.textarea.style.height = 'inherit';

    const computed = window.getComputedStyle(this.textarea);
    const height =
      this.textarea.scrollHeight -
      parseInt(computed.getPropertyValue('padding-top'), 10) -
      parseInt(computed.getPropertyValue('padding-bottom'), 10);

    this.textarea.style.height = `${height}px`;
    field.onChange(...args);
  };

  bindRef = (textarea) => {
    const { innerRef } = this.props;
    if (innerRef) innerRef(textarea);
    this.textarea = textarea;
  };

  render() {
    const { innerRef, form, autoresize, minHeight, error, ...props } = this.props;

    return (
      <textarea
        ref={this.bindRef}
        onChange={this.handleResize}
        {...props}
        className={cx(s.textarea, error && s.textareaError)}
        style={{ minHeight: `${minHeight}px` }}
      />
    );
  }
}

export { TextArea };
