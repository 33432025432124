import * as constants from '~/data/constants/internal';
import LoadingProgress from '~/data/utils/reducers/loading';
import { fromJS } from 'immutable';

export const particlesPackagesProgress = new LoadingProgress('particlesPackages');
export const jobProgress = new LoadingProgress('job');
export const logListProgress = new LoadingProgress('logList');
export const clientUsageProgress = new LoadingProgress('clientUsage');

const initialState = fromJS({});

export default (state: any = initialState, action) => {
  switch (action.type) {
    case constants.UPDATE_SUCCESS:
      return state.withMutations((ns) => {
        ns.set('updatePackagesJobStatus', action.payload);
        particlesPackagesProgress.setLoaded(ns);
      });
    case constants.UPDATE_START:
      return particlesPackagesProgress.setLoading(state);
    case constants.UPDATE_FAILED:
      return particlesPackagesProgress.setLoadFailed(state);

    case constants.JOB_FETCH_SUCCESS:
      return state.withMutations((ns) => {
        ns.set('jobStatus', action.payload);
        jobProgress.setLoaded(ns);
      });
    case constants.JOB_FETCH_START:
      return jobProgress.setLoading(state);
    case constants.JOB_FETCH_FAILED:
      return jobProgress.setLoadFailed(state);

    case constants.LOG_FETCH_SUCCESS:
      return state.withMutations((ns) => {
        ns.set('logs', {
          items: action.payload?.map((x) => ({ ...x, context: JSON.parse(x.contextJson) })),
          count: action.count,
        });
        logListProgress.setLoaded(ns);
      });
    case constants.LOG_FETCH_START:
      return logListProgress.setLoading(state);
    case constants.LOG_FETCH_FAILED:
      return logListProgress.setLoadFailed(state);

    case constants.DOWNLOAD_USAGE_SUCCESS:
      return clientUsageProgress.setLoaded(state);
    case constants.DOWNLOAD_USAGE_START:
      return clientUsageProgress.setLoading(state);
    case constants.DOWNLOAD_USAGE_FAILED:
      return clientUsageProgress.setLoadFailed(state);

    case constants.CLEAR:
      return fromJS({});

    default:
      return state;
  }
};
