import { baseAuditorPath } from '~/app/constants/url/auditor';
import { clientId } from '~/config';
import { api } from '~/data/dazzler-api';

export const logout = async () => {
  await api.get(`/auth/logout`, {
    params: {
      clientId,
      refreshToken: window.localStorage.getItem('refresh'),
    },
  });
  window.sessionStorage.removeItem('auth');
  window.localStorage.removeItem('refresh');
  window.sessionStorage.removeItem('expiresIn');
  window.location.replace(baseAuditorPath);
};
