import {
  importDownloadProgress,
  importUploadProgress,
  itemsPagination,
  listLoadingProgress,
} from '~/data/reducers/imports';
import * as schemas from '~/data/utils/schemas';
import { Map } from 'immutable';
import { denormalize } from 'normalizr';

const getState = (store) => store.imports;

const getEntities = (store) => getState(store).get('entities');

export const getItems = (store) => {
  const entities = Map({
    imports: getEntities(store),
  });
  return denormalize(itemsPagination.getPageItems(getState(store)), [schemas.ebaImport], entities);
};

export const itemsLoaded = (store) => listLoadingProgress.getLoaded(getState(store));

export const getTotalItems = (store) => itemsPagination.getTotalItems(getState(store));

export const getUploaded = (store) => importUploadProgress.getLoaded(getState(store));
export const getUploading = (store) => importUploadProgress.getLoading(getState(store));
export const getUploadingFailed = (store) => importUploadProgress.getLoadFailed(getState(store));

export const getDownloaded = (store) => importDownloadProgress.getLoaded(getState(store));
export const getDownloading = (store) => importDownloadProgress.getLoading(getState(store));
export const getDownloadingFailed = (store) => importDownloadProgress.getLoadFailed(getState(store));
