import React from 'react';
import { AnyPropsType } from '~/AnyPropsType';
import { Text } from '~/ui/index.js';
import { Box, Button } from '@mui/material';
import Modal from '~/ui/components/modal/index';

const DeleteUserPopup = ({ closeFunction, isLoading, userName = '', handleDeleteUser, text = '' }: AnyPropsType) => (
  <Modal
    open
    closeIcon
    onClose={closeFunction}
    modalTitle={<Text weight={Text.weight.medium}>Remove user</Text>}
    contentText={
      <Text size={Text.size.m}>
        {text || `Are you sure you want to remove access for ${userName ? <strong>{userName}</strong> : 'this user'}?`}
      </Text>
    }
  >
    <Box display="flex" justifyContent="flex-end">
      <Box marginRight="15px">
        <Button onClick={closeFunction} variant="outlined">
          Close
        </Button>
      </Box>
      <Button disabled={isLoading} onClick={handleDeleteUser} variant="contained">
        Remove
      </Button>
    </Box>
  </Modal>
);

export default DeleteUserPopup;
