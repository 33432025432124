import { Box, MenuItem, Paper, Select, Skeleton, Tab, Tabs, Typography } from '@mui/material';
import * as pbi from 'powerbi-client';
import { scopeLabels } from '~/app/constants/app/options';
import { actions, selectors } from '~/data/index.js';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DownloadButton, Row, Text } from '~/ui/index.js';

import { useAppDispatch, useAppSelector } from '~/data/utils/hooks';

import s from './styles.module.scss';
import { useGetSuccessfullyProcessedScopeTypes } from '~/data/clients/queries';
import { Show } from '~/app/components/core/show';
import { useGetReport } from '~/data/reports/queries';
import { useGetPowerBiExplanationSections } from '~/data/powerbi-explanation/queries';
import { ScopeType } from '~/data/openapi-client';
import { sanitize } from '~/app/utils/sanitize';
import { toast } from 'react-toastify';

const ClientUploadContainer = () => {
  const dispatch = useAppDispatch();
  const { clientId, reportId } = useParams<{ clientId: string; reportId?: string }>();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [sectionValue, setSectionValue] = useState<string | undefined>(undefined);

  const { data: report, isInitialLoading: isReportLoading } = useGetReport(clientId, reportId ?? '');
  const {
    data: scopeTypes,
    isSuccess: isScopesLoaded,
    isLoading: isScopeTypesLoading,
  } = useGetSuccessfullyProcessedScopeTypes(clientId);

  const reportScopeType = report?.scopeType;

  const type = reportScopeType ?? scopeTypes?.[activeTab];

  const { data: sections } = useGetPowerBiExplanationSections(type as ScopeType);

  const sectionOptions = sections?.map((item) => ({ value: item.id, label: item.title }));

  const selectedSection = sections?.find((item) => item.id === sectionValue);

  let pollingHandle: any;
  let embedContainer: HTMLDivElement;
  const powerBi: pbi.service.Service = new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory,
  );

  const client = useAppSelector((state) => selectors.clients.getItem(state, clientId));
  const isClientLoaded = useAppSelector(selectors.clients.itemLoaded);
  const isDashboardLoaded = useAppSelector(selectors.clients.dashboardLoaded);
  const dashboard = useAppSelector(selectors.clients.getDashboard);
  const percentComplete: number = useAppSelector(selectors.clients.getPercentComplete);
  const isFullDownloadingProcessFailed = useAppSelector(selectors.clients.getFullDownloadingProcessFailed);

  const [downloadingStatus, setDownloadingStatus] = useState(null);
  const [warningActive, setWarningActive] = useState(false);

  const fetchDashboardUrl = () => dispatch(actions.clients.fetchDashboardUrl(clientId, type));
  const startDownloadRequest = () => dispatch(actions.clients.startDownloadRequest(clientId, type));
  const downloadReport = (fileName) => dispatch(actions.clients.downloadReport(clientId, type, fileName));
  const pollDownloadStatus = () => dispatch(actions.clients.pollDownloadStatus(clientId, type));
  const clearDownloadingProgress = () => dispatch(actions.clients.clearDownloadingProgress());

  const options = [
    {
      value: 'ALMM - Breakdown',
      label: 'ALMM - Breakdown',
    },
  ];

  const [value, setValue] = useState(options[0].value);

  useEffect(() => {
    if (warningActive) {
      toast.warn(
        'Not all recently uploaded data is available on this dashboard yet. It may take up to 2 hours to be updated.',
      );
    }
  }, [warningActive]);

  useEffect(() => {
    if (!!sectionOptions?.length && !sectionValue) {
      setSectionValue(sectionOptions[0].value);
    }
  }, [sectionOptions]);

  useEffect(() => {
    if (dashboard) {
      const { id, embedToken, embedUrl, isAllDataLoaded } = dashboard;
      const config = {
        accessToken: embedToken,
        embedUrl,
        id,
        tokenType: 1,
      };
      powerBi.embed(embedContainer, config);

      if (!isAllDataLoaded) {
        setWarningActive(!isAllDataLoaded);
      }
    }
  }, [dashboard]);

  useEffect(() => {
    if (isFullDownloadingProcessFailed) {
      if (pollingHandle) {
        clearInterval(pollingHandle);
        pollingHandle = 0;
      }
      clearDownloadingProgress();
    }
  }, [isFullDownloadingProcessFailed]);

  useEffect(() => {
    if (type) {
      fetchDashboardUrl();
    }
  }, [type]);

  const onDownload = async () => {
    if (await startDownloadRequest()) {
      // @ts-expect-error - Argument of type '"started"' is not assignable to parameter of type 'SetStateAction<null>'.
      setDownloadingStatus('started');
      pollDownloadStatus();
    } else {
      // @ts-expect-error - Argument of type '"finished"' is not assignable to parameter of type 'SetStateAction<null>'.
      setDownloadingStatus('finished');
    }
  };

  useEffect(() => {
    if (downloadingStatus === 'started') {
      pollingHandle = setInterval(() => {
        const fileName = `${client.get('name')}(${type})`;
        if (percentComplete < 100) {
          pollDownloadStatus();
        } else {
          if (pollingHandle) {
            clearInterval(pollingHandle);
            pollingHandle = 0;
          }
          const download = async () => {
            const result = await downloadReport(fileName);
            if (result) {
              // @ts-expect-error - Argument of type '"finished"' is not assignable to parameter of type 'SetStateAction<null>'.
              setDownloadingStatus('finished');
            }
          };
          download();
        }
      }, 4000);
    }
    return () => {
      if (pollingHandle) {
        clearInterval(pollingHandle);
      }
    };
  }, [downloadingStatus, percentComplete]);

  const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const dashboardOptions = isScopesLoaded
    ? scopeTypes.map((scopeType) => ({ value: scopeType, label: scopeLabels[scopeType] }))
    : [];

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <Box>
          <Text size={Text.size.xl} weight={Text.weight.semiMedium} color={Text.color.colorTextDark}>{`${
            type ? scopeLabels[type] || type : ''
          } dashboard (${client?.get('name')})`}</Text>
        </Box>
        <Show when={!!reportScopeType}>
          <DownloadButton
            onClick={onDownload}
            percentComplete={percentComplete}
            downloadingStatus={downloadingStatus}
            isFullDownloadingProcessFailed={isFullDownloadingProcessFailed}
            className={s.downloadBtn}
            label="Download"
          />
        </Show>
      </div>

      <Show when={!reportScopeType && !!scopeTypes?.length}>
        <Row className="mb-3">
          <>
            <Tabs value={activeTab} onChange={handleTabsChange} className={s.tabs}>
              {isScopesLoaded &&
                dashboardOptions.map((option, i) => <Tab label={option.label} value={i} className={s.tabsTab} />)}
            </Tabs>

            <DownloadButton
              onClick={onDownload}
              percentComplete={percentComplete}
              downloadingStatus={downloadingStatus}
              isFullDownloadingProcessFailed={isFullDownloadingProcessFailed}
              className={s.downloadBtn}
              label="Download"
            />
          </>
        </Row>
      </Show>

      {isClientLoaded && isDashboardLoaded ? (
        <>
          <div className="flex gap-2">
            <div className="grow">
              <div className={s.clientFrame}>
                <Paper
                  className="embedContainer p-0"
                  // eslint-disable-next-line react/no-unknown-property
                  powerbi-type="report"
                  style={{ aspectRatio: '15 / 9' }}
                  ref={(div) => {
                    if (div) {
                      embedContainer = div;
                    }
                  }}
                />
              </div>
            </div>
            <Paper className="max-w-[250px]">
              <Select
                className="w-full"
                value={sectionValue ?? sectionOptions?.[0]?.value}
                onChange={(e) => {
                  setSectionValue(e.target.value);
                }}
              >
                {sectionOptions?.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
              <div
                className="mt-2"
                dangerouslySetInnerHTML={{ __html: sanitize(selectedSection?.htmlContent ?? '') }}
              />
            </Paper>
          </div>
        </>
      ) : (
        <Show
          when={!isScopeTypesLoading && !scopeTypes}
          fallback={
            <div className="flex gap-2 h-[700px]">
              <Skeleton className="grow h-full rounded-md" variant="rectangular" />
              <Skeleton className="w-[250px] h-full rounded-md" variant="rectangular" />
            </div>
          }
        >
          <Typography>No successfully processed scope types</Typography>
        </Show>
      )}
    </>
  );
};

export default ClientUploadContainer;
