class Pagination {
  totalItemsKey: string;
  pageItemsKey: string;
  loadedItemsKey?: string;
  constructor(name) {
    this.totalItemsKey = `${name}TotalItems`;
    this.pageItemsKey = `${name}PageItems`;
    this.loadedItemsKey = `${name}LoadedItems`;
  }

  setTotalItems = (state, value) => state.set(this.totalItemsKey, value);

  getTotalItems = (state) => state.get(this.totalItemsKey);

  setPageItems = (state, value) => state.set(this.pageItemsKey, value);

  getPageItems = (state) => state.get(this.pageItemsKey);

  getLoadedItems = (state) => state.get(this.loadedItemsKey);

  setLoadedItems = (state, value) => {
    const loadedItems = this.getLoadedItems(state);
    if (loadedItems) {
      state.set(this.loadedItemsKey, loadedItems.concat(value));
    } else {
      state.set(this.loadedItemsKey, value);
    }
  };

  removePageItem = (state, id) =>
    state.set(
      this.pageItemsKey,
      state.get(this.pageItemsKey).filter((itemId) => itemId !== id),
    );

  set = (state, totalItems, itemsPerPage, pageItems) =>
    state.withMutations((myState) => {
      this.setTotalItems(myState, totalItems);
      this.setPageItems(myState, pageItems);
      this.setLoadedItems(myState, pageItems);
    });

  clear = (state) =>
    state.withMutations((myState) => {
      myState.delete(this.totalItemsKey);
      // myState.delete(this.itemsPerPageKey);
      myState.delete(this.pageItemsKey);
    });
}

export default Pagination;
