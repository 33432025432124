import { fromThrowable, ok, type Result } from 'neverthrow';
import { filter, first, map, pipe, sort, split } from 'remeda';

export function downloadFileByURL(url: string, filename: string) {
  try {
    const link = document.createElement('a');

    link.style.display = 'none'; // to prevent unwanted visual changes

    link.href = url;
    link.download = filename;

    // some browsers require appending the element to the body to work
    document.body.appendChild(link);

    link.click();

    // Cleanup
    setTimeout(() => {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, 100);
  } catch (error) {
    console.error(`Error saving file "${filename}"; Error = `, error);
    throw new Error(`Failed to save file: ${filename}`);
  }
}

export function downloadFile(file: File) {
  const url = window.URL.createObjectURL(file);
  downloadFileByURL(url, file.name);
}

/**
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition
 */
const isUTF8Filename = (directive: string) => directive.trim().startsWith('filename*');

const safeURIDecode = fromThrowable(decodeURIComponent, (e) => e);

/**
 * https://datatracker.ietf.org/doc/html/rfc5987#section-3.2.1
 * UTF8''file%20name.jpg -> file%20name.jpg
 */
const removeCharset = (attribute: string) => {
  const charsetEnd = attribute.indexOf("''");
  if (charsetEnd === -1) return attribute;

  return attribute.slice(charsetEnd + 2); // 2 -> ''
};

export function getFilename(contentDisposition: string | undefined | null, fallback: string): string {
  if (contentDisposition == null) return fallback;

  /**
   * https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition
   */
  const directive = pipe(
    contentDisposition,
    split(';'),
    map((x) => x.trim()),
    filter((x) => x.startsWith('filename')), // filename=(ASCII) or filename*=UTF-8'' (UTF-8)
    // filename*= has higher priority
    sort((a, b) => (a.startsWith('filename*=') ? -1 : 1)),
    first(),
  );

  if (directive == null) return fallback;

  let [_, name] = directive.split('='); // filename, "myfile.txt"

  if (name == null || name.trim().length == 0) return fallback;

  // "myfile.txt" => myfile.txt
  name = name.replaceAll('"', '').trim();

  if (name == '') return fallback;

  let nameResult: Result<string, unknown>;
  if (isUTF8Filename(directive)) {
    nameResult = safeURIDecode(removeCharset(name));
  } else {
    nameResult = ok(name);
  }

  return nameResult.unwrapOr(fallback);
}
