import React from 'react';
import { Text } from '~/ui/index.js';
import { Box, Button } from '@mui/material';
import Modal from '~/ui/components/modal/index';
import { getSupportAddress } from '~/data/index.js';

import s from './styles.module.scss';

const SupportPopup = ({ closeFunction }) => (
  <Modal
    open
    onClose={closeFunction}
    closeIcon
    modalTitle={<Text weight={Text.weight.semiMedium}>Need help?</Text>}
    contentText={
      <Box marginBottom="16px">
        Please send an email to{' '}
        <a className={s.headerSupportLink} href={`mailto:${getSupportAddress()}`}>
          {getSupportAddress()}{' '}
        </a>
        .
      </Box>
    }
  >
    <div className={s.headerSupportButtons}>
      <Button onClick={closeFunction} variant="outlined">
        Close
      </Button>
    </div>
  </Modal>
);

export default SupportPopup;
