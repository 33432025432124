import type { CompiledQuery, Kysely } from 'kysely';

export type ColumnInfo = {
  width: number;
  key: string;
};

export type VisualExcelSheet = {
  id: string; // uuid
  sheet_name: string;
  columns: string; // Array<string>
  rows: string; // Array<Array<string>>
  column_info: string; // Array<ColumnInfo>
};

export type VisualExcelApacheTable = {
  id: string[]; // uuid
  sheet_name: string[];
  columns: string[]; // Array<string>
  rows: string[]; // Array<Array<string>>
  column_info: string[]; // Array<Array<ColumnInfo>>
};

export type TableName = `visual_excels_${string}`;

export function createTableName(reportFileId: string): TableName {
  reportFileId = reportFileId.replaceAll('-', '_');
  return `visual_excels_${reportFileId}`;
}

export function dropTable(db: Kysely<any>, name: string): CompiledQuery {
  return db.schema.dropTable(name).ifExists().compile();
}
