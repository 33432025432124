import { useMutation, type UseMutationOptions } from '@tanstack/react-query';
import ky, { Options as KyOptions } from 'ky';
import { baseURL } from '~/config';
import { api } from '~/data/dazzler-api';
import { downloadFile } from '~/data/downloads/file';

export function useDownloadVisualExcel(
  options?: UseMutationOptions<
    void,
    Error,
    {
      resourceURI: string;
      filename: string;
      signal?: AbortSignal;
      onDownloadProgress?: KyOptions['onDownloadProgress'];
    }
  >,
) {
  return useMutation({
    mutationFn: async function downloadVisualExcel({ resourceURI, filename, signal, onDownloadProgress }) {
      const endpoint = new URL(resourceURI, baseURL);

      const res = await ky(endpoint, {
        headers: {
          Authorization: api.getAuthorizationHeader() ?? '',
        },
        retry: {
          limit: 3,
        },
        signal,
        onDownloadProgress,
      });

      const blob = await res.blob();
      const file = new File([blob], filename, {
        type: res.headers.get('content-type') ?? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      downloadFile(file);
    },
    ...options,
  });
}
