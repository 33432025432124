import * as constants from '~/data/constants/auth';
import LoadingProgress from '~/data/utils/reducers/loading';
import { Map } from 'immutable';

export const sessionLoading = new LoadingProgress('session');
export const refreshLoading = new LoadingProgress('refresh');

const sessionLoaded = (state, action) =>
  state.withMutations((newState) => {
    const { role, ...user } = action.payload;

    sessionLoading.setLoaded(newState);
    newState.set('auth_token', window.sessionStorage.getItem('auth'));
    newState.set('role', role);
    newState.set('user', user);
  });

const refreshLoaded = (state) =>
  state.withMutations((newState) => {
    refreshLoading.setLoaded(newState);
    newState.set('auth_token', window.sessionStorage.getItem('auth'));
  });

const initialState = Map({
  auth_token: window.sessionStorage.getItem('auth'),
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.SESSION_LOADING:
      return sessionLoading.setLoading(state);
    case constants.SESSION_LOADED:
      return sessionLoaded(state, action);
    case constants.SESSION_LOAD_FAILED:
      return sessionLoading.setLoadFailed(state);
    case constants.REFRESH_LOADING:
      return refreshLoading.setLoading(state);
    case constants.REFRESH_LOADED:
      return refreshLoaded(state);
    case constants.REFRESH_LOAD_FAILED:
      return refreshLoading.setLoadFailed(state);
    case constants.SESSION_CLOSE:
      return initialState;
    default:
      return state;
  }
};
