import { Button, LinearProgress, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import cx from '~/data/utils/helpers/cx';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useDownloadComparisonVisualExcel } from '~/data/comparisons/queries';

export type DownloadsProps = {
  clientId: string;
  comparisonId: string;
  downloadDisplayedRows: () => void;
};

const sequence =
  (...funcs: Function[]) =>
  () =>
    funcs.forEach((f) => f());

export function Downloads({ clientId, comparisonId, downloadDisplayedRows }: DownloadsProps) {
  const [downloadProgress, setDownloadProgress] = useState(0);

  const { mutate: downloadVisualExcel } = useDownloadComparisonVisualExcel(clientId, comparisonId);
  const [downloadMenuAnchor, setDownloadMenuAnchor] = useState<HTMLElement | null>(null);

  const isOpen = Boolean(downloadMenuAnchor);

  const close = () => setDownloadMenuAnchor(null);
  const open = (e: React.MouseEvent<HTMLButtonElement>) => setDownloadMenuAnchor(e.currentTarget);

  const downloadExcel = () => {
    downloadVisualExcel({
      onDownloadProgress: (progress) => {
        if (progress.percent >= 1) {
          setDownloadProgress(0);
          return;
        }
        setDownloadProgress(progress.percent);
      },
    });
  };

  return (
    <div>
      <Button
        id="download-button"
        aria-controls={isOpen ? 'download-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        variant="outlined"
        size="large"
        disableElevation
        onClick={open}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <div className="flex gap-1 items-center">
          <div
            className={cx(
              'absolute w-full bottom-0 left-0 rounded-b-md',
              downloadProgress > 0 && downloadProgress < 1 ? 'block' : 'hidden',
            )}
          >
            <LinearProgress value={downloadProgress * 100} variant="determinate" />
          </div>
          <Typography>Download</Typography>
        </div>
      </Button>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="download-menu"
        MenuListProps={{
          'aria-labelledby': 'download-button',
        }}
        anchorEl={downloadMenuAnchor}
        open={isOpen}
        onClose={close}
      >
        <MenuItem onClick={sequence(downloadExcel, close)} disableRipple>
          Visual Excel
        </MenuItem>
        <MenuItem onClick={sequence(downloadDisplayedRows, close)} disableRipple>
          Data table
        </MenuItem>
      </Menu>
    </div>
  );
}
