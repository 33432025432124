import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { clients } from '~/data/selectors/index';
import Row from '~/ui/components/grid/row';
import { Uploader } from './uploader';
import { reportKeys, useGetReportingFrameworks, useListReportFileUploadProgresses } from '~/data/reports/queries';
import { Button, Skeleton } from '@mui/material';
import { type ReportFileUploadProgressRepresentation } from '~/data/openapi-client/index';
import { reportPath } from '~/app/constants/url/auditor';
import { useQueryClient } from '@tanstack/react-query';
import { logger } from '~/logger';

function ReportFilesUploadPage() {
  const history = useHistory();
  const { clientId = '', reportId = '' } = useParams<{ clientId?: string; reportId?: string }>();

  const { data: frameworksRes } = useGetReportingFrameworks(clientId, reportId);

  const { frameworks } = frameworksRes ?? {};

  const queryClient = useQueryClient();
  const client = useSelector((state) => clients.getItem(state, clientId));

  const { data: fileUploadProgresses, isInitialLoading: areFileProgressesLoading } = useListReportFileUploadProgresses(
    clientId,
    reportId,
  );

  const invalidateFiles = async () => {
    await queryClient.invalidateQueries({
      queryKey: reportKeys.fileList(clientId, reportId),
      exact: false,
      type: 'all',
    });
    await queryClient.invalidateQueries({
      queryKey: reportKeys.listReportFileUploadProgresses(clientId, reportId),
      type: 'all',
    });
  };

  const reportingFrameworkFile = new Map<string, ReportFileUploadProgressRepresentation>();
  for (const file of fileUploadProgresses ?? []) {
    reportingFrameworkFile.set(file.reportingFramework, file);
  }

  const formatAccept = (accept: string) => {
    if (accept.includes('zip')) return ['application/zip', []];
    if (accept.includes('xbrl')) return ['application/xbrl', ['.xbrl']];
    return [`application/${accept}`, [`.${accept}`]];
  };

  useEffect(() => {
    const ac = new AbortController();
    window.addEventListener(
      'beforeunload',
      (event) => {
        const activeFileUploads = queryClient.isMutating({ mutationKey: reportKeys.uploadFile() });
        logger.info('There are %d active report file uploads', activeFileUploads);
        if (activeFileUploads > 0) {
          event.preventDefault();
        }
      },
      { signal: ac.signal },
    );
    return () => {
      ac.abort();
    };
  }, []);

  function navigateToNextPage() {
    const activeFileUploads = queryClient.isMutating({ mutationKey: reportKeys.uploadFile() });
    logger.info('There are %d active report file uploads', activeFileUploads);
    if (activeFileUploads > 0) {
      window.alert('Please wait for the file to finish uploading');
      return;
    }

    history.push(reportPath(clientId, reportId));
  }

  return (
    <div>
      <Row>
        <Typography component="h1" fontSize={24} className="text-dark">
          {client?.get('name')}
        </Typography>
      </Row>
      <Row className="flex gap-1">
        <Typography fontSize={16} className="text-light">
          {client?.get('vatNumber')}
        </Typography>
      </Row>
      <Paper className="mt-8">
        <Typography component="h1" fontSize={24} className="text-dark">
          Upload at least one document to start
        </Typography>
        <div className="grid grid-cols-3 gap-3 mt-4 items-scretch">
          {!areFileProgressesLoading
            ? Object.entries(frameworks ?? {}).map(([framework, extensions]) => {
                const file = reportingFrameworkFile.get(framework);

                return (
                  <Uploader
                    framework={framework}
                    extensions={extensions}
                    options={{
                      accept: Object.fromEntries(Object.values(extensions.map((item) => formatAccept(item)))),
                    }}
                    defaultFileData={file ? { name: file.name ?? '', id: file.reportFileId } : undefined}
                    onSettled={invalidateFiles}
                  />
                );
              })
            : new Array(3).fill(<Skeleton variant="rectangular" height={200} />)}
        </div>
        <div className="mt-4 flex justify-end w-full">
          <Button variant="contained" onClick={navigateToNextPage}>
            Next
          </Button>
        </div>
      </Paper>
    </div>
  );
}

export default ReportFilesUploadPage;
