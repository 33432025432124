import { reportComparisonsPath } from '~/app/constants/url/auditor';
import { actions, createDropzoneUrl, selectors } from '~/data/index.js';
import { useAppDispatch, useAppSelector } from '~/data/utils/hooks';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '~/app/components/core/preloader';
import { Row, Text } from '~/ui/index.js';
import { Box } from '@mui/material';

import s from './styles.module.scss';

const Comparison = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { clientId, reportId, fileId } = useParams<{ clientId: string; reportId: string; fileId: string }>();

  const [dropzoneUrl, setDropzoneUrl] = useState<string>('');
  const [iFrameHeight, setIFrameHeight] = useState(500);
  const comparisonId = useRef(null);

  const isClientLoaded = useAppSelector(selectors.clients.itemLoaded);
  const isReportLoaded = useAppSelector(selectors.reports.itemLoaded);
  const report = useAppSelector((state) => selectors.reports.getItem(state, reportId));
  const currentFile =
    report &&
    report
      .get('files')
      .toJS()
      .find((f) => f.id === fileId);

  const createComparison = () => dispatch(actions.comparisons.createComparison(fileId));

  const handleIframeTask = ({ data }) => {
    if (data) {
      if (data.sent) {
        history.push(reportComparisonsPath(clientId, reportId, comparisonId.current));
      }
      if (data.height) {
        setIFrameHeight(data.height);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleIframeTask);
    createComparison().then((response) => {
      const { dropzoneApplicationId, dropzoneEngagementId, dropzoneEntityId, id } = response.data;
      setDropzoneUrl(
        createDropzoneUrl({
          applicationId: dropzoneApplicationId,
          engagementId: dropzoneEngagementId,
          entityId: dropzoneEntityId,
        }),
      );
      comparisonId.current = id;
    });
  }, []);

  return isClientLoaded && isReportLoaded ? (
    <div className={s.comparison}>
      <Row className={s.comparisonTitle}>
        <Text size={Text.size.xl} weight={Text.weight.semiMedium} color={Text.color.colorTextDark}>
          Compare files
        </Text>
      </Row>
      <Box className={s.comparisonWrap}>
        <Text className={s.comparisonWrapTitle} weight={Text.weight.semiMedium}>
          Base file
        </Text>
        <Box display="flex" flexDirection="row" marginLeft="16px">
          <Box className={s.comparisonWrapItem}>
            <Text className={s.comparisonWrapItemTitle}>File name</Text>
            <Text className={s.comparisonWrapItemDesc}>{currentFile?.name}</Text>
          </Box>
        </Box>
      </Box>
      {dropzoneUrl && (
        <Box className={s.comparisonWrap}>
          <Text className={s.comparisonWrapTitle} weight={Text.weight.semiMedium}>
            Comparing files
          </Text>
          <iframe
            frameBorder="0"
            src={dropzoneUrl}
            className={s.comparisonDropzone}
            title="Dropzone"
            height={`${iFrameHeight}px`}
          />
        </Box>
      )}
    </div>
  ) : (
    <Loader />
  );
};

export default Comparison;
