import React, { useMemo } from 'react';

/**
 * Render based on several conditions
 * @description https://www.solidjs.com/docs/latest/api#switchmatch
 */
type SwitchProps = {
  fallback?: React.ReactNode;
  children: React.ReactNode;
};

export function Switch(props: SwitchProps) {
  const conditions = useMemo(
    () => React.Children.toArray(props.children).map((x: any) => x.props) as any as MatchProps<unknown>[],
    [props.children],
  );

  for (const condition of conditions) {
    if (condition.when != null && condition.when !== false) {
      if (typeof condition.children === 'function') {
        return condition.children(condition.when);
      } else {
        return condition.children;
      }
    }
  }

  return props.fallback ?? null;
}

type MatchProps<T> = {
  when: T | undefined | null | false;
  children: React.ReactNode | ((item: T) => React.ReactNode);
};

export function Match<T>(props: MatchProps<T>): React.ReactNode {
  return props as any as React.ReactNode;
}
