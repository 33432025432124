import { AnyPropsType } from '~/AnyPropsType';
import { clientsPath } from '~/app/constants/url/auditor';
import { connect, Roles, selectors } from '~/data/index.js';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const mapStateToProps = (state) => ({
  isLoggedIn: selectors.auth.isLoggedIn(state),
  role: selectors.auth.getSessionRole(state),
});

const ProtectedRoute = ({
  component: Component,
  isLoggedIn,
  role,
  allowedRoles = [Roles.ADMIN, Roles.CLIENT_ADMIN],
  ...rest
}: AnyPropsType) => {
  const isAllowed = allowedRoles.includes(role);

  return <Route {...rest} render={(props) => (isAllowed ? <Component {...props} /> : <Redirect to={clientsPath} />)} />;
};

// ProtectedRoute.propTypes = {
//   isLoggedIn: PropTypes.bool,
//   role: PropTypes.string.isRequired,
//   component: PropTypes.element.isRequired,
//   allowedRoles: PropTypes.arrayOf(PropTypes.string),
// };
ProtectedRoute.defaultProps = {
  isLoggedIn: false,
  allowedRoles: [Roles.ADMIN, Roles.CLIENT_ADMIN],
};
export default connect(mapStateToProps, null)(ProtectedRoute);
