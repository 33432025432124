export const typeOptions = [
  { label: 'RFV', value: 'RFV' },
  { label: 'RFQ', value: 'RFQ' },
];

export const envelopeTypeOptions = [
  { label: 'Closed', value: 'closed' },
  { label: 'Open', value: 'open' },
];

export const requestTypeOptions = [
  { label: 'RFQ and RFV', value: 'all' },
  { label: 'RFV', value: 'RFV' },
  { label: 'RFQ', value: 'RFQ' },
];

export const requestSortOptions = [
  { label: 'Recent activity', value: 'recent' },
  { label: 'Alphanumeric', value: 'alphanumeric' },
  { label: 'Suppliers', value: 'suppliers' },
  { label: 'Time remaining', value: 'remaining' },
  { label: 'Creation date', value: 'creation' },
];

export const suppliersSortOptions = [
  { label: 'Recent activity', value: 'recent' },
  { label: 'Alphanumeric', value: 'alphanumeric' },
  { label: 'Requests', value: 'requests' },
];

export const scopeLabels = {
  // Particles
  BankConsolidated: 'Bank - Consolidated',
  BankStatutory: 'Bank - Statutory',
  InsuranceConsolidated: 'Insurance - Consolidated',
  InsuranceStatutory: 'Insurance - Statutory',
  InsuranceBranch: 'Insurance - Branch',
  // South Africa
  Bank: 'Bank',
  Consolidated: 'Consolidated',
  Group: 'Group',
  BA610: 'BA610',
};

export const userStatusOptions = [
  { label: 'Creation requested', value: 'ApimCreationRequested' },
  { label: 'Created', value: 'Created' },
  { label: 'Failed', value: 'Failed' },
  { label: 'Failed to add to group', value: 'FailedToAddToGroup' },
];
