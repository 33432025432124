import { dropzoneFrontendUrl } from '~/config';

export function createDropzoneUrl({ applicationId, engagementId, entityId }): string {
  const idToken = window.sessionStorage.getItem('auth');
  const url = new URL(
    `./auditor/applications/${applicationId}/engagements/${engagementId}/entities/${entityId}/dropzones`,
    dropzoneFrontendUrl,
  );

  url.searchParams.set('mode', 'integration');
  url.searchParams.set('isSendOnly', 'true');

  url.hash = `id_token=${idToken}`;

  return url.toString();
}
