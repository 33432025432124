import cx from 'classnames';
import React from 'react';
import { Icon } from '~/ui/components/icon/index';

import s from './styles.module.scss';

const { icons } = Icon;
type iconButtonProps = {
  shared?: boolean;
  type?: 'submit' | 'reset' | 'button';
  className?: string;
  children: React.ReactNode;
  icon?: keyof typeof icons;
};

export const IconButton = ({ type, className, children, shared, icon, ...props }: iconButtonProps) => (
  <button type={type} className={cx(s.button, className)} {...props}>
    <Icon
      icon={icon}
      key={icon}
      style={{
        width: '15px',
        height: '14',
        marginRight: '10px',
      }}
    />
    {children}
  </button>
);

IconButton.defaultProps = {
  type: 'IconButton',
  className: null,
  shared: false,
  icon: Icon.icons.chevronDown,
};
