import * as Yup from 'yup';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { z } from 'zod';

export const zodDay = z.custom<Dayjs>((val) => val instanceof dayjs, 'Invalid date');

export const ClientSchema = Yup.object().shape({
  vatNumber: Yup.string().required('Required'),
});

export const UserSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  familyName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
});

export const ClientCreationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  vatNumber: Yup.string().required('Required'),
  countryCode: Yup.string().required('Required'),
});

export const ReportRejectSchema = Yup.object().shape({
  rejectionMessage: Yup.string().required('Required'),
});

export const ClientMaterialitySchema = Yup.object().shape({
  materiality: Yup.object().shape({
    bank: Yup.number()
      .positive('Must be a positive number')
      .typeError('Must be a number')
      .nullable()
      .required('Required'),
    controllingCompany: Yup.number().positive('Must be a positive number').typeError('Must be a number').nullable(),
    consolidated: Yup.number().positive('Must be a positive number').typeError('Must be a number').nullable(),
  }),
});
