import React from 'react';
import { TablePagination as MUITablePagination } from '@mui/material';

const TablePagination = ({
  totalItems,
  perPage,
  page,
  handleChangePage,
  onRowsPerPageChange,
  hideRowsOptions = false,
}) => (
  <MUITablePagination
    rowsPerPageOptions={totalItems > perPage && !hideRowsOptions ? [10, 15, 20] : []}
    component="div"
    count={totalItems}
    rowsPerPage={perPage}
    page={Number(page - 1)}
    onPageChange={(_, newPage) => handleChangePage(newPage)}
    onRowsPerPageChange={onRowsPerPageChange}
  />
);

export default TablePagination;
