const prefix = (type) => `dazzler/clients-admin/${type}`;

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');

export const UPDATE_START = prefix('UPDATE_START');
export const UPDATE_SUCCESS = prefix('UPDATE_SUCCESS');
export const UPDATE_FAILED = prefix('UPDATE_FAILED');

export const CREATION_START = prefix('CREATION_START');
export const CREATION_SUCCESS = prefix('CREATION_SUCCESS');
export const CREATION_FAILED = prefix('CREATION_FAILED');

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const CONNECTED_CLIENTS_LOAD_START = prefix('CONNECTED_CLIENTS_LOAD_START');
export const CONNECTED_CLIENTS_LOAD_SUCCESS = prefix('CONNECTED_CLIENTS_LOAD_SUCCESS');
export const CONNECTED_CLIENTS_LOAD_FAILED = prefix('CONNECTED_CLIENTS_LOAD_FAILED');

export const AVAILABLE_CLIENTS_LOAD_START = prefix('AVAILABLE_CLIENTS_LOAD_START');
export const AVAILABLE_CLIENTS_LOAD_SUCCESS = prefix('AVAILABLE_CLIENTS_LOAD_SUCCESS');
export const AVAILABLE_CLIENTS_LOAD_FAILED = prefix('AVAILABLE_CLIENTS_LOAD_FAILED');

export const DELETE_CONNECTED_CLIENT_START = prefix('DELETE_CONNECTED_CLIENT_START');
export const DELETE_CONNECTED_CLIENT_SUCCESS = prefix('DELETE_CONNECTED_CLIENT_SUCCESS');
export const DELETE_CONNECTED_CLIENT_FAILED = prefix('DELETE_CONNECTED_CLIENT_FAILED');

export const CONNECT_CLIENT_START = prefix('CONNECT_CLIENT_START');
export const CONNECT_CLIENT_SUCCESS = prefix('CONNECT_CLIENT_SUCCESS');
export const CONNECT_CLIENT_FAILED = prefix('CONNECT_CLIENT_FAILED');

export const CONNECT_CLIENTS_START = prefix('CONNECT_CLIENTS_START');
export const CONNECT_CLIENTS_SUCCESS = prefix('CONNECT_CLIENTS_SUCCESS');
export const CONNECT_CLIENTS_FAILED = prefix('CONNECT_CLIENTS_FAILED');

export const CHANGE_USER_START = prefix('CHANGE_USER_START');
export const CHANGE_USER_SUCCESS = prefix('CHANGE_USER_SUCCESS');
export const CHANGE_USER_FAILED = prefix('CHANGE_USER_FAILED');

export const DELETE_CLIENT_START = prefix('DELETE_CLIENT_START');
export const DELETE_CLIENT_SUCCESS = prefix('DELETE_CLIENT_SUCCESS');
export const DELETE_CLIENT_FAILED = prefix('DELETE_CLIENT_FAILED');

export const CLEAR = prefix('CLEAR');

export const CLEAR_SINGLE = prefix('CLEAR_SINGLE');
