import Breadcrumbs from '~/app/components/core/breadcrumbs/index';
import {
  reportComparisonNewPath,
  reportComparisonPath,
  reportComparisonsPath,
  reportDashboardPath,
  reportDataCheckPath,
  reportDataDownloadsPath,
  reportFileUploadPath,
  reportPagePath,
  reportPath,
  reportVersionReadonlyCheckPath,
} from '~/app/constants/url/auditor';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';

import { Comparison } from './comparison/index';
import { ReportComparisonsRouter } from './comparisons/index';
import ReportNext from './report/ReportNext';
import LatestChecksPage from '~/app/components/pages/auditor/clients/client/report/[reportId]/latest-checks/page';
import { useGetReport } from '~/data/reports/queries';
import ReportProcessingLogsPage from '~/app/components/pages/auditor/clients/client/report/[reportId]/logs/page';
import DownloadsPage from '~/app/components/pages/auditor/clients/client/report/[reportId]/downloads/page';
import { Dashboard } from '../dashboard';
import ReadonlyChecksPage from '~/app/components/pages/auditor/clients/client/report/[reportId]/readonly-checks/page';
import { supportPaths } from '~/app/constants/url/shared';
import ReportFilesUploadPage from './[reportId]/upload/page';
import { Chip } from '@mui/material';
import { ReportLifecycleStatus, ReportProcessingOutcome } from '~/data/openapi-client';
import cx from '~/data/utils/helpers/cx';
import ComparisonNext from './report/Comparison';

const transformStatus = (status: ReportProcessingOutcome) => {
  if (status === ReportProcessingOutcome.NotProcessed) return 'Draft';
  return status;
};

const ReportRouter = () => {
  const { clientId = '', reportId = '' } = useParams<{ clientId?: string; reportId?: string }>();

  const { data: report, isSuccess: isReportLoaded } = useGetReport(clientId, reportId);

  return (
    <>
      <Breadcrumbs.Anchor
        path={reportPath(':clientId', ':reportId')}
        link={reportPath(clientId, reportId)}
        component={
          <span title="Report" className="flex items-center gap-1">
            {isReportLoaded ? report.name : 'Report'}
            {report && (
              <Chip
                size="small"
                label={
                  report.lifecycleStatus === ReportLifecycleStatus.Locked
                    ? report.lifecycleStatus
                    : transformStatus(report.processingOutcome)
                }
                className={cx(
                  (() => {
                    if (report.lifecycleStatus === ReportLifecycleStatus.Locked) return 'bg-status-warning';
                    if (report.processingOutcome === ReportProcessingOutcome.NotProcessed) return 'bg-grey';
                    if (report.processingOutcome === ReportProcessingOutcome.Processing) return 'bg-primary-blue';
                    if (report.processingOutcome === ReportProcessingOutcome.Succeeded) return 'bg-status-success';
                    if (report.processingOutcome === ReportProcessingOutcome.Failed) return 'bg-status-error';
                    return 'bg-grey';
                  })(),
                  'text-white',
                )}
              />
            )}
          </span>
        }
      />
      <Switch>
        <Route exact path={reportPath(':clientId', ':reportId')} component={ReportNext} />
        <Route exact path={reportFileUploadPath(':clientId', ':reportId')}>
          <Breadcrumbs.Anchor
            key="Upload"
            path={reportFileUploadPath(':clientId', ':reportId')}
            link={reportFileUploadPath(':clientId', ':reportId')}
            component={<span>Upload</span>}
          />
          <ReportFilesUploadPage />
        </Route>
        <Route exact path={supportPaths.report(':clientId', ':reportId')}>
          <Breadcrumbs.Anchor
            key="Logs"
            path={supportPaths.report(':clientId', ':reportId')}
            link={supportPaths.report(':clientId', ':reportId')}
            component={<span>Logs</span>}
          />
          <ReportProcessingLogsPage />
        </Route>
        <Route exact path={reportDataCheckPath(':clientId', ':reportId')}>
          <Breadcrumbs.Anchor
            key="Review checks"
            path={reportDataCheckPath(':clientId', ':reportId')}
            link={reportDataCheckPath(':clientId', ':reportId')}
            component={<span>Review checks</span>}
          />
          <LatestChecksPage />
        </Route>
        <Route exact path={reportVersionReadonlyCheckPath(':clientId', ':reportId', ':versionId')}>
          <ReadonlyChecksPage />
        </Route>
        <Route exact path={reportDashboardPath(':clientId', ':reportId')}>
          <Breadcrumbs.Anchor
            key="Dashboard"
            path={reportDashboardPath(':clientId', ':reportId')}
            link={reportDashboardPath(':clientId', ':reportId')}
            component={<span>Dashboard</span>}
          />
          <Dashboard />
        </Route>
        <Route exact path={reportDataDownloadsPath(':clientId', ':reportId')}>
          <Breadcrumbs.Anchor
            key="Downloads"
            path={reportDataDownloadsPath(':clientId', ':reportId')}
            link={reportDataDownloadsPath(':clientId', ':reportId')}
            component={<span>Downloads</span>}
          />
          <DownloadsPage />
        </Route>
        <Route
          path={reportComparisonsPath(':clientId', ':reportId', ':comparisonId')}
          component={ReportComparisonsRouter}
        />
        <Route exact path={reportComparisonPath(':clientId', ':reportId', ':fileId')} component={Comparison} />
        <Route exact path={reportComparisonNewPath(':clientId', ':reportId')}>
          <Breadcrumbs.Anchor
            key="Comparisons"
            path={reportComparisonNewPath(clientId, reportId)}
            link={reportComparisonNewPath(clientId, reportId)}
            component={<span>Comparisons</span>}
          />
          <ComparisonNext />
        </Route>
        <Redirect from={reportPath(clientId, reportId)} to={reportPagePath(clientId, reportId, 1)} />
      </Switch>
    </>
  );
};

export default ReportRouter;
