import FooterComponent from '~/app/components/core/footer/index';
import { Menu, MenuItem } from '~/app/components/core/footer/menu/index';
import { idleTimer, refreshTimer } from '~/app/constants/app/idle-timer';
import { privacyPath, taxonomiesPath } from '~/app/constants/url/auditor';
import ErrorBoundary from '~/app/utils/error-boundary/index';
import React, { useEffect, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Text } from '~/ui/index.js';
import { Box, Button, Container } from '@mui/material';
import Modal from '~/ui/components/modal/index';

import s from './styles.module.scss';
import Breadcrumbs from '../breadcrumbs';
import cx from '~/data/utils/helpers/cx';

const useNotificationPermission = () => {
  const [permission, setPermission] = useState(Notification.permission);

  useEffect(() => {
    const requestPermission = async () => {
      if ('Notification' in window && Notification.permission !== 'denied') {
        const p = await Notification.requestPermission();
        setPermission(p);
      }
    };

    requestPermission();
  }, []);

  return permission;
};
export function showNotification(title, message) {
  const notification = new Notification(title, {
    icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADRklEQVRoge1Zy04UQRS9olETdeVr7YOFZr5A/QEDARR3grgiBseNLyIL0wmCyFLif2jkEfU3VEQyGOJCN/KKLn3ck6kOpzs9M/UacNEnOQkwc07d211V91YhUqJEiZjYp6wo+5VV5ahh1fytYr7zX+Gwckg5q9xS/m3BLfPdIaPdNZxUTotd0M2SmVIe38nAO6Q+JTYaBLUq9Sf83AQHzijnzGdFmnXliHJPu4M/oXxTEMAH5V3lKQuP08r7yo8FPgvSxrfRqVzJDbio7BG/JwdNn/JTzrOmPBsh3gwQ/Hca5LdyXLk/gjc8Joxn6o+xoiWBafOFzH8qL8cyJ3QZb34Tx0JNsWDfSjb4C5ZaPMEVQ9uneUn5i8abl8CFXZXstHF58mOkHXPQdUt2Ot1y0GaAfZ63ynFHfULaxFH7lLRr4rkzTZPJkvKAoz4R/wSwsHl3mnTUyxHJVtgeVwMJSwC4SvpN5SEX8U0Svxe/hZRIWALYQLjYDbqI50h4z2NwIJGwBICH5PHKVoR2l6ePTXtQhETCE+gkD2woe21EFRKteg4MJBKeAPCVfM7bCK6RYDZg4ETiJDBPPldsBHdIMBMwcEI+72T7dNaID6S4Yr8gn9s2A3MFnYiUgC1rBT6T9PmjnUxgcLcSiDWFUDsGZPtU1oqPJdIU6idByCKOBZzS0nj6bASxttFYcN5GUcg2SeRbyGKACxkO/laFDHhNQt9WIgZGKY6XLsIbJMSNQ9uvOwqAZm6R4rjuIsaNGU8jq8UTGdwRoA9yaqeBKTL4LO4HmhBgrCUa/4mPCY5x62QSUtRc8YzG/aE86ms0QkY4aHfFiK4FepV/aNzhEDMsJC4kuPK4GBhgM+SvVVBIgzcQTKWaZJPoDjUtQK9kg1+WgKmTB3qU/NUirj5iLOyDUp/zPG2+Kc9E8M4ASfCbALFT4JDR4eEHDfqu5Zwnfo8efArcVfIJKSVuDxodSPJAe4AKy0WK53y0adMIWFS47lsrCABE84Uk0QanrTJ+XpBsY8bEVjksO1zx8TZw2OCK7UpoUV/a/tSbASUeBxc0W43+7cTcMN8dEI/2oN1Au4ueHdeCOD2lB3YURCz2c+LQEpcoUaI1/gHN05bJgC8yEwAAAABJRU5ErkJggg==',
    body: message,
  });
  notification.onclick = () => {
    window.focus();
  };
}

type PopupPropsType = {
  refreshAndClose: () => void;
  logout: () => void;
  logoutDate: Date;
  open: boolean;
};
const SessionEndingPopup = ({ refreshAndClose, logout, logoutDate, open }: PopupPropsType) => {
  const [secondsRemaining, setRemaining] = useState(60);

  useEffect(() => {
    if (!open) return () => null;
    showNotification('Your session will end soon', 'Extend your Regulatory reporting insights session.');
    let interval: number;
    const timeout = window.setTimeout(() => {
      window.clearInterval(interval);
      logout();
    }, logoutDate.getTime() - new Date().getTime());

    interval = window.setInterval(() => {
      const msRemaining = logoutDate.getTime() - new Date().getTime();
      setRemaining(Math.round(msRemaining / 1000));
      if (msRemaining <= 0) {
        window.clearInterval(interval);
        window.clearTimeout(timeout);
        logout();
      }
    }, 1000);
    return () => {
      window.clearInterval(interval);
      window.clearTimeout(timeout);
    };
  }, [open]);

  return (
    <Modal
      open={open}
      closeIcon={false}
      modalTitle={<Text weight={Text.weight.semiMedium}>Your session will end soon</Text>}
      contentText={
        <Box marginBottom="16px">
          Your session will expire in {secondsRemaining < 0 ? 0 : secondsRemaining} seconds. Do you want to extend your
          session?
        </Box>
      }
    >
      <div>
        <Box marginRight="16px" display="inline-block">
          <Button onClick={refreshAndClose} variant="outlined">
            Stay logged in
          </Button>
        </Box>
        <Box display="inline-block">
          <Button onClick={logout} variant="outlined">
            Log out now
          </Button>
        </Box>
      </div>
    </Modal>
  );
};

type LayoutPropsType = {
  content?: JSX.Element;
  header?: JSX.Element;
  refresh: () => Promise<boolean>;
  logout: () => Promise<void>;
};
const Layout = ({ content, header, refresh, logout }: LayoutPropsType) => {
  const refreshIntervalRef = useRef<number>(0);
  const [isSessionPopupOpen, setSessionPopupOpen] = useState(false);
  const handleOnIdle = () => {
    setSessionPopupOpen(true);
  };
  const handleOnContinueSession = () => {
    refresh();
    setSessionPopupOpen(false);
  };
  const handleLogout = () => {
    logout();
    window.clearInterval(refreshIntervalRef.current);
    setSessionPopupOpen(false);
  };

  useEffect(() => {
    const interval = setInterval(() => refresh(), refreshTimer);
    // @ts-expect-error - Type 'Timeout' is not assignable to type 'number'.
    refreshIntervalRef.current = interval;
    return () => window.clearInterval(interval);
  }, []);

  useIdleTimer({
    timeout: idleTimer,
    onIdle: handleOnIdle,
    debounce: 250,
  });

  useNotificationPermission();

  return (
    <ErrorBoundary>
      <div className={cx(s.layout, 'overflow-hidden')}>
        {header}
        <div className="h-[calc(100vh_-_70px)] flex flex-col justify-between overflow-auto">
          <div className={s.layoutMain}>
            <Container classes={{ root: 'sm:px-10' }} maxWidth="xl">
              <Breadcrumbs className="py-4" />
              <div className="mt-4">{content}</div>
            </Container>
          </div>
          <FooterComponent>
            <Menu>
              <div className="flex flex-wrap">
                <MenuItem href={privacyPath}>Privacy statement</MenuItem> |
                <MenuItem href="https://www.pwc.be/en/online/legal.html" target="_blank">
                  Legal disclaimer
                </MenuItem>{' '}
                |
                <MenuItem href="https://www.pwc.be/en/online/cookie-information.html" target="_blank">
                  Cookie information
                </MenuItem>{' '}
                |<MenuItem href={taxonomiesPath}>Taxonomy versions</MenuItem>{' '}
              </div>
            </Menu>
          </FooterComponent>
        </div>
        <SessionEndingPopup
          open={isSessionPopupOpen}
          logoutDate={new Date(new Date().getTime() + 60 * 1000)}
          logout={handleLogout}
          refreshAndClose={handleOnContinueSession}
        />
      </div>
    </ErrorBoundary>
  );
};

export default Layout;
