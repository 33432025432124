/* eslint-disable import/no-unresolved */
import { AnyPropsType } from '~/AnyPropsType';
import {
  adminPath,
  clientComparisonsPath,
  clientDashboardPath,
  clientPath,
  clientsPagePath,
  clientsPath,
  manageClientsPath,
  reportComparisonNewPath,
  reportDashboardPath,
  reportDataCheckPath,
  reportDataDownloadsPath,
  reportPath,
  reportsPagePath,
  reportsPath,
  reportVersionReadonlyCheckPath,
  usersPath,
} from '~/app/constants/url/auditor';
import cx from 'classnames';
import Logo from '/logo.svg?url';
import React, { useState } from 'react';
import { Link, matchPath, NavLink, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import DownloadIcon from '~/ui/components/icon/icons/24px/download-outline.svg';
import MenuIcon from '@mui/icons-material/Menu';

import { Container, IconButton, Menu, MenuItem, SvgIcon, Typography } from '@mui/material';
import SupportPopup from '../popup/support';
import { Show } from '../show';
import { CountryCode, ReportLifecycleStatus } from '~/data/openapi-client';
import { useGetClient } from '~/data/clients/queries';
import { useGetReport, useGetVersions } from '~/data/reports/queries';

const showNewHeader = true;

const ClientHeader = () => {
  const { clientId = '' } = useParams<{ clientId?: string }>();
  const { data: client } = useGetClient(clientId);

  return (
    <>
      <NavLink
        to={reportsPath(clientId)}
        className={(isActive) => cx('text-white px-4 py-[20px]', isActive && 'border-b-[1.5px] pb-[18px] border-white')}
      >
        <Typography>Overview</Typography>
      </NavLink>
      <NavLink
        to={clientDashboardPath(clientId)}
        className={(isActive) => cx('text-white px-4 py-[20px]', isActive && 'border-b-[1.5px] pb-[18px] border-white')}
      >
        <Typography>Dashboard</Typography>
      </NavLink>

      <NavLink
        to={clientComparisonsPath(clientId)}
        className={(isActive) =>
          cx(
            'text-white px-4 py-[20px]',
            isActive && 'border-b-[1.5px] pb-[18px] border-white',
            client?.countryCode === CountryCode.Za && 'hidden',
          )
        }
      >
        <Typography>Comparison</Typography>
      </NavLink>
    </>
  );
};

const ReportHeader = () => {
  const { clientId = '', reportId = '' } = useParams<{ clientId?: string; reportId?: string }>();
  const { data: client } = useGetClient(clientId);
  const { data: report } = useGetReport(clientId, reportId);
  const { data: latestVersion } = useGetVersions(clientId, reportId, 'latest');

  return (
    <>
      <NavLink
        to={reportPath(clientId, reportId)}
        exact
        className={(isActive) => cx('text-white px-4 py-[20px]', isActive && 'border-b-[1.5px] pb-[18px] border-white')}
      >
        <Typography>Overview</Typography>
      </NavLink>
      <Show when={!!report}>
        <NavLink
          isActive={(match, location) =>
            !!matchPath(location.pathname, {
              path: [
                reportVersionReadonlyCheckPath(':clientId', ':reportId', ':versionId'),
                reportDataCheckPath(':clientId', ':reportId'),
              ],
            })
          }
          to={
            report?.lifecycleStatus === ReportLifecycleStatus.Locked && latestVersion?.id
              ? reportVersionReadonlyCheckPath(clientId, reportId, latestVersion?.id)
              : reportDataCheckPath(clientId, reportId)
          }
          className={(isActive) =>
            cx(
              'text-white px-4 py-[20px] text-nowrap',
              isActive && 'border-b-[1.5px] pb-[18px] border-white',
              client?.countryCode === CountryCode.Za && 'hidden',
            )
          }
        >
          <Typography>Review Checks</Typography>
        </NavLink>
      </Show>

      <NavLink
        to={reportDashboardPath(clientId, reportId)}
        className={(isActive) => cx('text-white px-4 py-[20px]', isActive && 'border-b-[1.5px] pb-[18px] border-white')}
      >
        <Typography>Dashboard</Typography>
      </NavLink>

      <NavLink
        to={reportComparisonNewPath(clientId, reportId)}
        className={(isActive) =>
          cx(
            'text-white px-4 py-[20px]',
            isActive && 'border-b-[1.5px] pb-[18px] border-white',
            client?.countryCode === CountryCode.Za && 'hidden',
          )
        }
      >
        <Typography>Comparison</Typography>
      </NavLink>

      <NavLink
        to={reportDataDownloadsPath(clientId, reportId)}
        className={(isActive) => cx('text-white px-4 py-[20px]', isActive && 'border-b-[1.5px] pb-[18px] border-white')}
      >
        <Typography>
          <SvgIcon component={DownloadIcon} fontSize="inherit" />
        </Typography>
      </NavLink>
    </>
  );
};

const Header = ({ logout, isAdmin, isSuperAdmin }: AnyPropsType) => {
  const location = useLocation();
  const history = useHistory();
  const [isSupportShown, setSupportShown] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const matchClientsPath = matchPath<{ clientId?: string }>(location.pathname, {
    path: clientsPath,
  });

  const matchUsersPath = matchPath<{ clientId?: string }>(location.pathname, {
    path: usersPath,
  });

  const matchManageClientsPath = matchPath<{ clientId?: string }>(location.pathname, {
    path: manageClientsPath,
  });

  const matchAdminPath = matchPath<{ clientId?: string }>(location.pathname, {
    path: adminPath,
  });

  if (showNewHeader)
    return (
      <header className="bg-dark">
        <Container
          classes={{ root: 'sm:px-10' }}
          maxWidth="xl"
          className="grid grid-cols-[1fr_auto_1fr] items-center h-16"
        >
          <div>
            <Link to="/">
              <img src={Logo} alt="Regulatory Reporting Insights" />
            </Link>
          </div>
          <nav>
            <div className="flex items-center justify-center">
              <Switch>
                <Route path={reportsPagePath(':clientId', ':page')}>
                  <ClientHeader />
                </Route>
                <Route path={reportPath(':clientId', ':reportId')}>
                  <ReportHeader />
                </Route>
                <Route path={clientsPagePath(':clientId')}></Route>
                <Route path={clientPath(':clientId')}>
                  <ClientHeader />
                </Route>
              </Switch>
            </div>
          </nav>
          <div className="flex justify-end">
            <IconButton
              color="inherit"
              aria-label="open menu"
              edge="end"
              id="burger-button"
              aria-controls={open ? 'menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleMenuClick}
            >
              <MenuIcon fontSize="inherit" className="text-white" />
            </IconButton>
            <Menu
              id="menu"
              classes={{ paper: '!p-0 [&_ul]:p-[5px] [&_ul]:border-l-4 [&_ul]:border-orange' }}
              className="mt-3"
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'burger-button',
              }}
              anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Show when={isAdmin}>
                <>
                  <MenuItem
                    selected={!!matchClientsPath}
                    onClick={() => {
                      history.push(clientsPath);
                      handleMenuClose();
                    }}
                  >
                    <Typography>Client reports</Typography>
                  </MenuItem>
                  <MenuItem
                    selected={!!matchUsersPath}
                    onClick={() => {
                      history.push(usersPath);
                      handleMenuClose();
                    }}
                  >
                    <Typography>Manage Users</Typography>
                  </MenuItem>
                </>
              </Show>
              {isSuperAdmin && (
                <>
                  <MenuItem
                    selected={!!matchManageClientsPath}
                    onClick={() => {
                      history.push(manageClientsPath);
                      handleMenuClose();
                    }}
                  >
                    <Typography>Manage Clients</Typography>
                  </MenuItem>
                  <MenuItem
                    selected={!!matchAdminPath}
                    onClick={() => {
                      history.push(adminPath);
                      handleMenuClose();
                    }}
                  >
                    <Typography>Admin panel</Typography>
                  </MenuItem>
                </>
              )}
              <MenuItem
                onClick={() => {
                  setSupportShown(true);
                  handleMenuClose();
                }}
              >
                <Typography>Help</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  logout();
                  handleMenuClose();
                }}
              >
                <Typography className="text-red">Log out</Typography>
              </MenuItem>
            </Menu>
          </div>
        </Container>
        {isSupportShown && <SupportPopup closeFunction={() => setSupportShown(false)} />}
      </header>
    );
};

Header.defaultProps = {
  isAdmin: false,
  isSuperAdmin: false,
};

export default Header;
