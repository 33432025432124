import { clientId } from '~/config';

function createAuthURL(authURL: URL): string {
  window.localStorage.removeItem('refresh');
  window.sessionStorage.removeItem('expiresIn');
  const url = new URL(authURL);
  // otherwise PwC sso doesn't work
  if (url.pathname.endsWith('/')) {
    url.pathname = url.pathname.slice(0, -1);
  }
  url.searchParams.append('response_type', 'code');
  url.searchParams.append('client_id', clientId);
  url.searchParams.append('redirect_uri', window.location.origin);
  url.searchParams.append('scope', 'openid email profile cloudEmail role country');
  return url.toString();
}

export { createAuthURL };
