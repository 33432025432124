import history from '../history';

export const onSortingHelper = (key: string, direction: 'asc' | 'desc') => {
  const params = new URLSearchParams(window.location.search);
  params.set('sort', key);
  params.set('direction', direction);
  history.push({ search: params.toString() });
};

export const initSorting = (component: React.Component<any, any>) => {
  const params = new URLSearchParams(window.location.search);
  const s: any = component.state;
  s.sort = {
    key: params.get('sort') || component.state.sort.key,
    direction: params.get('direction') || component.state.sort.direction,
  };
};
