import { baseURL } from '~/config';
import ky, { Options as KyOptions } from 'ky';
import { api } from '~/data/dazzler-api';
import { useMutation, type UseMutationOptions } from '@tanstack/react-query';
import { downloadFile, getFilename } from './file';
import { format } from 'date-fns';

export function useDownloadReportProcessingLogs(
  options?: UseMutationOptions<
    void,
    Error,
    {
      clientId: string;
      reportId: string;
      signal?: AbortSignal;
      onDownloadProgress?: KyOptions['onDownloadProgress'];
    }
  >,
) {
  return useMutation({
    mutationFn: async function downloadReportProcessingLogs({ clientId, reportId, signal, onDownloadProgress }) {
      const endpoint = new URL(`./clients/${clientId}/reports/${reportId}/processing-logs.json`, baseURL);
      const res = await ky(endpoint, {
        headers: {
          Authorization: api.getAuthorizationHeader() ?? '',
        },
        retry: {
          limit: 3,
        },
        signal,
        onDownloadProgress,
      });

      const blob = await res.blob();
      const fileName = getFilename(res.headers.get('Content-Disposition'), `report-${reportId}-logs.json`);

      const file = new File([blob], fileName, { type: 'application/json' });
      downloadFile(file);
    },
    ...options,
  });
}

export function useDownloadVersionProcessingLogs(
  options?: UseMutationOptions<
    void,
    Error,
    {
      clientId: string;
      reportId: string;
      versionId: string;
      signal?: AbortSignal;
      onDownloadProgress?: KyOptions['onDownloadProgress'];
    }
  >,
) {
  return useMutation({
    mutationFn: async function downloadVersionProcessingLogs({
      clientId,
      reportId,
      versionId,
      signal,
      onDownloadProgress,
    }) {
      const endpoint = new URL(
        `./clients/${clientId}/reports/${reportId}/versions/${versionId}/processing-logs.json`,
        baseURL,
      );
      const res = await ky(endpoint, {
        headers: {
          Authorization: api.getAuthorizationHeader() ?? '',
        },
        retry: {
          limit: 3,
        },
        signal,
        onDownloadProgress,
      });

      const blob = await res.blob();
      const fileName = getFilename(res.headers.get('Content-Disposition'), `version-${versionId}-logs.json`);

      const file = new File([blob], fileName, { type: 'application/json' });
      downloadFile(file);
    },
    ...options,
  });
}

export function useDownloadReportFileProcessingLogs(
  options?: UseMutationOptions<
    void,
    Error,
    {
      clientId: string;
      reportId: string;
      reportFileId: string;
      signal?: AbortSignal;
      onDownloadProgress?: KyOptions['onDownloadProgress'];
    }
  >,
) {
  return useMutation({
    mutationFn: async function downloadReportFileProcessingLogs({
      clientId,
      reportId,
      reportFileId,
      signal,
      onDownloadProgress,
    }) {
      const endpoint = new URL(
        `./clients/${clientId}/reports/${reportId}/files/${reportFileId}/processing-logs.json`,
        baseURL,
      );
      const res = await ky(endpoint, {
        headers: {
          Authorization: api.getAuthorizationHeader() ?? '',
        },
        retry: {
          limit: 3,
        },
        signal,
        onDownloadProgress,
      });

      const blob = await res.blob();
      const fileName = getFilename(res.headers.get('Content-Disposition'), `file-${reportFileId}-logs.json`);

      const file = new File([blob], fileName, { type: 'application/json' });
      downloadFile(file);
    },
    ...options,
  });
}

export function useDownloadComparisonFileLogs(
  options?: UseMutationOptions<
    void,
    Error,
    {
      clientId: string;
      fileId: string;
      signal?: AbortSignal;
      onDownloadProgress?: KyOptions['onDownloadProgress'];
    }
  >,
) {
  return useMutation({
    mutationFn: async function downloadComparisonFileLogs({ clientId, fileId, signal, onDownloadProgress }) {
      const endpoint = new URL(`./clients/${clientId}/comparisons/files/${fileId}/processing-logs.json`, baseURL);
      const res = await ky(endpoint, {
        headers: {
          Authorization: api.getAuthorizationHeader() ?? '',
        },
        retry: {
          limit: 3,
        },
        signal,
        onDownloadProgress,
      });

      const blob = await res.blob();
      const fileName = getFilename(res.headers.get('Content-Disposition'), `comparison-file-${fileId}-logs.json`);

      const file = new File([blob], fileName, { type: 'application/json' });
      downloadFile(file);
    },
    ...options,
  });
}

export function useDownloadComparisonLogs(
  options?: UseMutationOptions<
    void,
    Error,
    {
      clientId: string;
      comparisonId: string;
      signal?: AbortSignal;
      onDownloadProgress?: KyOptions['onDownloadProgress'];
    }
  >,
) {
  return useMutation({
    mutationFn: async function downloadComparisonLogs({ clientId, comparisonId, signal, onDownloadProgress }) {
      const endpoint = new URL(`./clients/${clientId}/comparisons/${comparisonId}/processing-logs.json`, baseURL);
      const res = await ky(endpoint, {
        headers: {
          Authorization: api.getAuthorizationHeader() ?? '',
        },
        retry: {
          limit: 3,
        },
        signal,
        onDownloadProgress,
      });

      const blob = await res.blob();
      const fileName = getFilename(res.headers.get('Content-Disposition'), `comparison-${comparisonId}-logs.json`);

      const file = new File([blob], fileName, { type: 'application/json' });
      downloadFile(file);
    },
    ...options,
  });
}

export function useDownloadClientUsageReport(
  options?: UseMutationOptions<
    void,
    Error,
    {
      start: Date;
      end: Date;
      signal?: AbortSignal;
      onDownloadProgress?: KyOptions['onDownloadProgress'];
    }
  >,
) {
  return useMutation({
    mutationFn: async function downloadClientUsageReport({ start, end, signal, onDownloadProgress }) {
      const endpoint = new URL('./admin/clients/usage-reports', baseURL);
      endpoint.searchParams.set('start', format(start, 'yyyy-MM-dd'));
      endpoint.searchParams.set('end', format(end, 'yyyy-MM-dd'));

      const res = await ky(endpoint, {
        headers: {
          Authorization: api.getAuthorizationHeader() ?? '',
        },
        retry: {
          limit: 3,
        },
        signal,
        onDownloadProgress,
      });

      const blob = await res.blob();
      const fileName = getFilename(res.headers.get('Content-Disposition'), 'client-usage-report.xlsx');

      const file = new File([blob], fileName, { type: 'text/csv' });
      downloadFile(file);
    },
    ...options,
  });
}

export function useDownloadEBATransparencyImport(
  options?: UseMutationOptions<
    void,
    Error,
    {
      importId: string;
      filename: string;
      signal?: AbortSignal;
      onDownloadProgress?: KyOptions['onDownloadProgress'];
    }
  >,
) {
  return useMutation({
    mutationFn: async function downloadEBATransparencyImport({ importId, filename, signal, onDownloadProgress }) {
      const endpoint = new URL(`./imports/eba-transparency/${importId}`, baseURL);
      const res = await ky(endpoint, {
        headers: {
          Authorization: api.getAuthorizationHeader() ?? '',
        },
        retry: {
          limit: 3,
        },
        signal,
        onDownloadProgress,
      });

      const blob = await res.blob();
      const fileName = getFilename(res.headers.get('Content-Disposition'), filename);

      const file = new File([blob], fileName, { type: 'application/zip' });

      downloadFile(file);
    },
    ...options,
  });
}
