import { Button } from '@mui/material';
import React from 'react';
import { Text } from '~/ui/index.js';
import Modal from '~/ui/components/modal/index';

import s from './styles.module.scss';

interface ForbiddenPopupProps {
  logout: () => void;
}

const ForbiddenPopup = ({ logout }: ForbiddenPopupProps) => (
  <Modal
    open
    maxWidth="xs"
    modalTitle={<Text weight={Text.weight.semiMedium}>Access denied</Text>}
    contentText="It appears that you don't have the necessary permissions to access Regulatory Reporting Insights. Close the
        application or login with another account."
  >
    <div className={s.errorForm}>
      <div className={s.errorFormButtons}>
        <Button onClick={logout} variant="outlined">
          Log out
        </Button>
      </div>
    </div>
  </Modal>
);

export default ForbiddenPopup;
