import React from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Typography } from '@mui/material';
import { useGetComparisonFile } from '~/data/comparisons/queries';
import { LoadingButton } from '@mui/lab';
import { useDownloadComparisonFileLogs } from '~/data/downloads/queries';
import { DownloadProgress, Options as KyOptions } from 'ky';
import { Match, Switch } from '~/app/components/core/switch';

function ComparisonFileLogsPage() {
  const { clientId, fileId } = useParams<{ clientId: string; fileId: string }>();

  const { data: comparisonFile, isInitialLoading: isLoading } = useGetComparisonFile(clientId, fileId);
  const { mutate: downloadLogs, isLoading: isDownloading } = useDownloadComparisonFileLogs();

  const logDownloadProgress: KyOptions['onDownloadProgress'] = (progress: DownloadProgress, _: Uint8Array) => {
    console.log({ progress });
  };

  return (
    <section data-name="comparison-file-logs-page">
      <Typography fontSize={24} component="h1" className="mb-5">
        Processing logs
      </Typography>

      <div>
        <Switch>
          <Match when={isDownloading === true}>
            <Typography>
              <CircularProgress size={16} className="mr-1" /> Downloading
            </Typography>
          </Match>
          <Match when={isDownloading === false}>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              className="mb-4"
              onClick={() => downloadLogs({ clientId, fileId, onDownloadProgress: logDownloadProgress })}
            >
              Download logs for file {comparisonFile?.name}
            </LoadingButton>
          </Match>
        </Switch>
      </div>
    </section>
  );
}

export default ComparisonFileLogsPage;
