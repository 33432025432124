import React from 'react';
import Select, { components } from 'react-select';
import { Icon } from '~/ui/components/icon/index';

import s from './styles.module.scss';

const customStyles = (isDisabled) => ({
  container: (base) => ({
    ...base,
    color: '#212121',
    display: 'flex',
    fontSize: 18,
    fontWeight: 500,
    zIndex: 10,
  }),
  clearIndicator: (base) => ({
    ...base,
    color: '#445A64',
    paddingRight: 0,
    '&:hover': {
      cursor: 'pointer',
      color: '#f44336',
    },
  }),
  control: (base, { selectProps }) => ({
    ...base,
    backgroundColor: isDisabled ? '#eceff1' : '#fff',
    cursor: selectProps.menuIsOpen && 'text',
    border: 'none',
    '&:hover': {
      cursor: 'default',
      borderColor: selectProps.menuIsOpen ? 'transparent' : '#cfd8dc',
    },
    borderRadius: 0,
    boxShadow: 'none',
    minHeight: 34,
    minWidth: 84,
  }),
  valueContainer: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 16,
    paddingRight: 0,
  }),
  singleValue: (base) => ({
    ...base,
    position: 'static',
    transform: 'none',
    textOverflow: 'none',
    overflow: 'none',
  }),
  menu: (base) => ({
    ...base,
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.2)',
    borderRadius: 0,
    marginTop: '1px',
    padding: 0,
    right: 0,
    zIndex: 100500,
    width: '160px',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 100500,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
  multiValue: () => ({
    display: 'none',
  }),
  option: (base, { isSelected }) => ({
    ...base,
    color: '#212121',
    backgroundColor: isSelected ? '#eceff1' : '#ffffff',
    fontSize: 18,
    fontWeight: 300,
    padding: '10px 0 10px 12px',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: '#cfd8dc',
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: '#90A4AE',
    fontSize: 18,
    fontWeight: 500,
  }),
  dropdownIndicator: (base, { selectProps }) => ({
    ...base,
    color: '#445A64',
    marginTop: 2,
    padding: selectProps.menuIsOpen ? '12px 25px 12px 16px' : '12px 16px 12px 25px',
    transform: selectProps.menuIsOpen && 'rotate(180deg)',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
});

const DropdownIndicator = (props) =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <Icon icon={Icon.icons.chevronDown} className={s.selectDropdown} />
    </components.DropdownIndicator>
  );

const ClearIndicator = (props) =>
  components.ClearIndicator && (
    <components.ClearIndicator {...props}>
      <Icon icon={Icon.icons.close} className={s.selectClear} />
    </components.ClearIndicator>
  );

class DropDown extends React.Component<any> {
  static defaultProps = {
    placeholder: 'Placeholder',
  };

  onDelete = (option) => {
    const { value, name, setFieldValue } = this.props;
    const newOptions = value.filter((o) => o !== option);
    setFieldValue(name, newOptions);
  };

  render() {
    const { value, placeholder, dataCy } = this.props;

    return (
      <div data-cy={dataCy}>
        <Select
          styles={customStyles(this.props.isDisabled)}
          noOptionsMessage={() => 'Not found'}
          loadingMessage={() => 'Loading...'}
          placeholder={placeholder}
          components={{ DropdownIndicator, ClearIndicator }}
          closeMenuOnSelect
          isSearchable={false}
          defaultValue={this.props.options && this.props.options[0]}
          {...this.props}
        />
      </div>
    );
  }
}

export { DropDown };
