/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
import React from 'react';
import { Skeleton, TableCell, TableRow } from '@mui/material';

type TableRowsLoaderProps = {
  rowsNum: number;
  colsNum: number;
  height?: number;
};

const TableRowsLoader = ({ rowsNum, colsNum, height }: TableRowsLoaderProps) => {
  return (
    <>
      {[...Array(rowsNum)].map((row, index) => (
        <TableRow key={index}>
          {[...Array(colsNum)].map((el) => (
            <TableCell component="th" scope="row">
              <Skeleton animation="wave" variant="text" height={height || 30} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};
export default TableRowsLoader;
