import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { Text } from '~/ui/index.js';
import { Field, Form, Formik } from 'formik';
import { UserSchema } from '~/data/index.js';
import FormField from '~/app/components/core/form/field';

import s from './styles.module.scss';
import Modal from '~/ui/components/modal/index';
import { CountryCode } from '~/data/openapi-client';
import sortBy from 'lodash/sortBy';

const AddUserPopUp = ({ handleClose, handleAddUser, isSuperAdmin }) => {
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
  const unsortedRegionOptions = Object.values(CountryCode).map((x) => ({
    value: x,
    label: regionNames.of(x),
  }));

  const regionOptions: typeof unsortedRegionOptions = sortBy(unsortedRegionOptions, (obj) => obj.label);

  const onSubmit = async (values, formikActions) => {
    const res = await handleAddUser(values, formikActions);
    if (res) {
      handleClose();
    }
  };
  return (
    <Modal open closeIcon onClose={handleClose} modalTitle={<Text weight={Text.weight.medium}>Create a new user</Text>}>
      <Formik
        onSubmit={onSubmit}
        validationSchema={UserSchema}
        initialValues={{
          firstName: '',
          familyName: '',
          email: '',
          countries: [],
        }}
        enableReinitialize
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form className={s.pageForm}>
            <Box>
              <Field
                component={FormField}
                id="firstName"
                name="firstName"
                placeholder="First name"
                label="First name"
                inputComponent={TextField}
                fullWidth
              />
            </Box>
            <Box>
              <Field
                component={FormField}
                id="familyName"
                name="familyName"
                placeholder="Family name"
                label="Family name"
                inputComponent={TextField}
                fullWidth
              />
            </Box>
            <Box>
              <Field
                component={FormField}
                id="email"
                name="email"
                placeholder="Email"
                label="Email"
                inputComponent={TextField}
                fullWidth
              />
            </Box>

            {isSuperAdmin && (
              <Box>
                <Autocomplete
                  className="mt-2"
                  multiple
                  options={regionOptions}
                  onChange={(e, value) => {
                    setFieldValue(
                      'countries',
                      value.map((v) => v.value),
                    );
                  }}
                  renderInput={(params) => <TextField {...params} label="Countries" placeholder="Countries" />}
                />
              </Box>
            )}

            <Box className={s.formButtonsWrap}>
              <Button variant="outlined" data-cy="clientEditCancel" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" data-cy="saveClient" type="submit" disabled={isSubmitting}>
                Add user
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddUserPopUp;
