import cx from 'classnames';
import React from 'react';

import s from './styles.module.scss';

type cellProps = {
  className?: string;
  children?: any;
  width?: string;
  height?: string;
  marginLeft?: boolean;
  onClick?: any;
};

const Cell = ({ className, children, width, height, marginLeft, ...props }: cellProps) => (
  <div
    className={cx(s.tableCell, className)}
    style={{
      width: `${width}`,
      height: `${height}`,
      // @ts-expect-error - Type 'string | null' is not assignable to type 'MarginLeft<string | number> | undefined'.
      marginLeft: marginLeft ? 'auto' : null,
    }}
    {...props}
  >
    {children}
  </div>
);

Cell.defaultProps = {
  className: null,
  children: null,
  width: '',
  height: '',
  marginLeft: false,
};

export { Cell };
