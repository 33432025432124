import React from 'react';
import s from './styles.module.scss';

const ThreeDotsLoader = () => (
  <div className={s.wrap}>
    <div className={s.snippet} data-title="dot-flashing">
      <div className={s.stage}>
        <div className={s.dotFlashing} />
      </div>
    </div>
  </div>
);

ThreeDotsLoader.Small = () => (
  <div className={s.wrapSmall}>
    <div className={s.snippet} data-title="dot-flashing">
      <div className={s.stage}>
        <div className={s.dotFlashing} />
      </div>
    </div>
  </div>
);

export default ThreeDotsLoader;
