import React, { useEffect, useState } from 'react';
import { Redirect, useParams, Link, useHistory } from 'react-router-dom';
import { useDebounceValue } from '~/app/utils/debounce';
import { onFilterHelper } from '~/app/utils/filter-helpers';
import { onPaginationHelper } from '~/app/utils/pagination-helpers';
import { Roles, actions, selectors } from '~/data/index.js';
import { useAppDispatch, useAppSelector } from '~/data/utils/hooks';
import { userConnectedClientsPath, usersPagePath, usersPath } from '~/app/constants/url/auditor';
import { Row, Text } from '~/ui/index.js';
import Search from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import TableRowsLoader from '~/ui/components/mui-table/table-loader';
import TablePagination from '~/ui/components/table-pagination/index';
import { AddUserPopUp, EditUserPopUp } from '../actions/index';
import { DeleteUserPopup } from '../../manage-clients/actions/index';

import s from './styles.module.scss';

const UsersContainer = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { page } = useParams<{ page: string }>();

  const users = useAppSelector(selectors.users.getItems);
  const isUsersLoaded = useAppSelector(selectors.users.itemsLoaded);
  const totalItems = useAppSelector(selectors.users.getTotalItems);
  const role = useAppSelector(selectors.auth.getSessionRole);

  const fetchUsers = (filter) => dispatch(actions.users.fetchUsers(filter));
  const clear = () => dispatch(actions.users.clear());
  const addUser = (values, formikActions) => dispatch(actions.users.addUser(values, formikActions));
  const editUser = (values, formikActions, userId) => dispatch(actions.users.editUser(values, formikActions, userId));
  const deleteUser = (userId) => dispatch(actions.users.deleteUser(userId));

  const [inputValue, setInputValue] = useState('');
  const [perPage, setPerPage] = useState(10);
  const [showAddUserPopup, setShowAddUserPopup] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [userToRemove, setUserToRemove] = useState(null);

  const debouncedSearchText = useDebounceValue(inputValue);

  const queryParams = {
    search: debouncedSearchText,
    sort: 'FamilyName',
    direction: 'asc',
    limit: perPage,
    offset: Number(page) > 1 ? (Number(page) - 1) * perPage : 0,
  };

  useEffect(() => {
    fetchUsers(queryParams);

    return () => {
      clear();
    };
  }, [debouncedSearchText, page, perPage]);

  const onSearch = (search) => {
    setInputValue(search);
    onFilterHelper('search', search);
  };

  const onPagination = (e) => {
    setPerPage(e.target.value);
    onPaginationHelper(e.target);
  };

  const handleDeleteUser = () => {
    // @ts-expect-error - 'userToRemove' is possibly 'null'.
    if (deleteUser(userToRemove.get('id'))) {
      history.push(usersPath);
    }
  };

  if (isUsersLoaded && users.size === 0 && Number(page) > 1) {
    return <Redirect to={usersPagePath(Math.ceil(totalItems / perPage))} />;
  }

  return (
    <div className={s.clients}>
      <Row className={s.clientsRow}>
        <Text size={Text.size.xl} weight={Text.weight.semiMedium} color={Text.color.colorTextDark}>
          Users
        </Text>
      </Row>
      <Box display="flex" justifyContent="space-between" marginBottom="14px">
        <Box display="flex">
          <Box width={345} marginRight="10px">
            <TextField
              placeholder="Search user"
              InputProps={{
                startAdornment: <Search htmlColor={theme.palette.secondary.light} />,
              }}
              onChange={({ target: { value } }) => onSearch(value)}
              value={inputValue}
              size="small"
              color="primary"
              fullWidth
            />
          </Box>
        </Box>

        <Button
          variant="contained"
          onClick={() => setShowAddUserPopup(true)}
          startIcon={<AddCircleOutlineOutlinedIcon />}
        >
          Add user
        </Button>
      </Box>

      <Box>
        <Box>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="50%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                        User name
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                        Email
                      </Text>
                    </TableCell>

                    <TableCell width="15%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                        Actions
                      </Text>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isUsersLoaded ? (
                    <>
                      {users.size ? (
                        users.map((user) => (
                          <TableRow
                            key={user.get('id')}
                            hover
                            component={Link}
                            to={userConnectedClientsPath(user.get('id'))}
                            className={s.userRow}
                          >
                            <TableCell>
                              <Text
                                size={Text.size.s}
                                font={Text.color.colorTextMedium}
                                weight={Text.weight.semiMedium}
                              >
                                {user.get('firstName') && user.get('familyName')
                                  ? `${user.get('firstName')} ${user.get('familyName')}`
                                  : 'No name'}
                              </Text>
                            </TableCell>

                            <TableCell>
                              <Text
                                size={Text.size.s}
                                font={Text.color.colorTextMedium}
                                weight={Text.weight.semiMedium}
                              >
                                {user.get('email')}
                              </Text>
                            </TableCell>

                            <TableCell>
                              <Box className={s.userRowButtonsWrap}>
                                <Button
                                  variant="text"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setUserToEdit(user);
                                  }}
                                  className={s.userRowButton}
                                >
                                  <Text size={Text.size.s}>Edit</Text>
                                </Button>
                                <div className={s.userRowButtonsWrapDivider} />
                                <Button
                                  variant="text"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setUserToRemove(user);
                                  }}
                                  className={s.userRowButton}
                                >
                                  <Text size={Text.size.s}>Remove</Text>
                                </Button>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <Text>No users found</Text>
                      )}
                    </>
                  ) : (
                    <TableRowsLoader rowsNum={perPage} colsNum={3} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {isUsersLoaded && !!users.size && (
              <div className={s.clientsPagination}>
                <TablePagination
                  totalItems={totalItems}
                  perPage={perPage}
                  page={page || 1}
                  handleChangePage={(p) => {
                    history.push(usersPagePath(p + 1));
                  }}
                  onRowsPerPageChange={onPagination}
                />
              </div>
            )}
          </Paper>
        </Box>
      </Box>

      {showAddUserPopup && (
        <AddUserPopUp
          handleClose={() => setShowAddUserPopup(false)}
          isSuperAdmin={role === Roles.ADMIN}
          handleAddUser={addUser}
        />
      )}

      {userToEdit && (
        <EditUserPopUp
          handleClose={() => setUserToEdit(null)}
          isSuperAdmin={role === Roles.ADMIN}
          handleEditUser={editUser}
          user={userToEdit}
        />
      )}

      {userToRemove && (
        <DeleteUserPopup closeFunction={() => setUserToRemove(null)} handleDeleteUser={handleDeleteUser} />
      )}
    </div>
  );
};

export default UsersContainer;
