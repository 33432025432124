import {
  availableClientsLoadingProgress,
  availableClientsPagination,
  changingRoleLoadingProgress,
  connectedClientsLoadingProgress,
  connectedClientsPagination,
  itemsPagination,
  listLoadingProgress,
  loadingProgress,
} from '~/data/reducers/clientsAdmin';
import * as schemas from '~/data/utils/schemas';
import { Map } from 'immutable';
import { denormalize } from 'normalizr';

const getState = (store) => store.clientsAdmin;

const getEntities = (store) => getState(store).get('entities');

export const getItem = (store, id) => getEntities(store).get(id);

export const getItemIsAdmin = (store, clientId, userId) =>
  getItem(store, clientId)
    .get('clientUsers')
    .find((user) => user.get('userId') === userId)
    .get('isAdmin');

export const getItems = (store) => {
  const entities = Map({
    clientsAdmin: getEntities(store),
  });
  return denormalize(itemsPagination.getPageItems(getState(store)), [schemas.clientsAdmin], entities);
};

export const getConnectedClients = (store) => {
  const entities = Map({
    clientsAdmin: getEntities(store),
  });
  return denormalize(connectedClientsPagination.getPageItems(getState(store)), [schemas.clientsAdmin], entities);
};

export const getAvailableClients = (store) => {
  const entities = Map({
    clientsAdmin: getEntities(store),
  });

  return denormalize(availableClientsPagination.getLoadedItems(getState(store)), [schemas.clientsAdmin], entities);
};

export const itemLoaded = (store) => loadingProgress.getLoaded(getState(store));

export const itemsLoaded = (store) => listLoadingProgress.getLoaded(getState(store));

export const getTotalItems = (store) => itemsPagination.getTotalItems(getState(store));

export const connectedClientsLoaded = (store) => connectedClientsLoadingProgress.getLoaded(getState(store));

export const getTotalConnectedClients = (store) => connectedClientsPagination.getTotalItems(getState(store));

export const availableClientsLoaded = (store) => availableClientsLoadingProgress.getLoaded(getState(store));

export const getTotalAvailableClients = (store) => availableClientsPagination.getTotalItems(getState(store));

export const getRoleChanged = (store) => changingRoleLoadingProgress.getLoaded(getState(store));
export const getRoleChanging = (store) => changingRoleLoadingProgress.getLoading(getState(store));
export const getRoleChangingFailed = (store) => changingRoleLoadingProgress.getLoadFailed(getState(store));
