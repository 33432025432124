import { AnyPropsType } from '~/AnyPropsType';
import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '~/ui/components/icon/index';

import s from './styles.module.scss';

/* eslint-disable */
const ButtonIconWrapper = ({ type, className, children, ...props }: AnyPropsType) => (
  <button type={type} className={cx(s.button, className)} {...props}>
    {children}
  </button>
);
/* eslint-enable */
ButtonIconWrapper.defaultProps = {
  type: 'button',
  className: null,
};

export const ButtonIcon = ({ iconClassName, icon, ...props }: AnyPropsType) => (
  <ButtonIconWrapper {...props}>
    <Icon icon={icon} className={cx(s.buttonIcon, iconClassName)} />
  </ButtonIconWrapper>
);

ButtonIcon.defaultProps = {
  iconClassName: null,
};

export const PrimaryButtonIcon = ({ iconClassName, icon, ...props }: AnyPropsType) => (
  <ButtonIconWrapper className={s.buttonPrimary} {...props}>
    <Icon icon={icon} className={cx(s.buttonIcon, s.buttonPrimaryIcon, iconClassName)} />
  </ButtonIconWrapper>
);

export const SecondaryButtonIcon = ({ iconClassName, icon, ...props }: AnyPropsType) => (
  <ButtonIconWrapper className={s.buttonSecondary} {...props}>
    <Icon icon={icon} className={cx(s.buttonIcon, s.buttonSecondaryIcon, iconClassName)} />
  </ButtonIconWrapper>
);

export const TertiaryButtonIcon = ({ iconClassName, icon, ...props }: AnyPropsType) => (
  <ButtonIconWrapper className={s.buttonTertiary} {...props}>
    <Icon icon={icon} className={cx(s.buttonIcon, s.buttonTertiaryIcon, iconClassName)} />
  </ButtonIconWrapper>
);

ButtonIcon.icons = Icon.icons;
PrimaryButtonIcon.icons = Icon.icons;
SecondaryButtonIcon.icons = Icon.icons;
TertiaryButtonIcon.icons = Icon.icons;
PrimaryButtonIcon.defaultProps = ButtonIcon.defaultProps;
SecondaryButtonIcon.defaultProps = ButtonIcon.defaultProps;
TertiaryButtonIcon.defaultProps = ButtonIcon.defaultProps;

const ButtonIconLinkWrapper = ({ to, className, children, ...props }: AnyPropsType) => (
  <Link to={to} className={cx(s.button, className)} {...props}>
    {children}
  </Link>
);

ButtonIconLinkWrapper.defaultProps = {
  className: null,
};

ButtonIcon.Link = ({ to, icon, ...props }: AnyPropsType) => (
  <ButtonIconLinkWrapper to={to} {...props}>
    <Icon icon={icon} className={cx(s.buttonIcon)} />
  </ButtonIconLinkWrapper>
);

PrimaryButtonIcon.Link = ({ to, icon, ...props }: AnyPropsType) => (
  <ButtonIconLinkWrapper to={to} className={s.buttonPrimary} {...props}>
    <Icon icon={icon} className={cx(s.buttonIcon, s.buttonPrimaryIcon)} />
  </ButtonIconLinkWrapper>
);

SecondaryButtonIcon.Link = ({ iconClassName, to, icon, ...props }: AnyPropsType) => (
  <ButtonIconWrapper to={to} className={s.buttonSecondary} {...props}>
    <Icon icon={icon} className={cx(s.buttonIcon, s.buttonSecondaryIcon, iconClassName)} />
  </ButtonIconWrapper>
);
