import cx from 'classnames';
import React from 'react';

import s from './styles.module.scss';

type buttonProps = {
  shared?: boolean;
  type?: 'submit' | 'reset' | 'button';
  className?: string;
  children: any;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: any;
};
const defaultProps = {
  type: 'button',
  className: null,
  shared: false,
  disabled: false,
  isLoading: false,
};

export const PrimaryButton = ({ type, className, children, ...props }: buttonProps) => (
  <button type={type} className={cx(s.button, s.buttonPrimary, className)} {...props}>
    {children}
  </button>
);

PrimaryButton.defaultProps = defaultProps;

PrimaryButton.Small = ({ className, ...props }: buttonProps) => (
  <PrimaryButton className={cx(s.button, s.buttonPrimarySmall, className)} {...props} />
);

export const SecondaryButton = ({ type = 'button', className, children, ...props }: buttonProps) => (
  <button type={type} className={cx(s.button, s.buttonSecondary, className)} {...props}>
    {children}
  </button>
);

SecondaryButton.defaultProps = defaultProps;

SecondaryButton.Small = ({ className, ...props }: buttonProps) =>
  (<SecondaryButton className={cx(s.button, s.buttonSecondarySmall, className)} {...props} />) as any;

(SecondaryButton.Small as any).defaultProps = defaultProps;

export const UnderlinedButton = ({ type = 'button', className, children, disabled, ...props }: buttonProps) => (
  <button type={type} className={cx(s.buttonUnderlined, className, disabled && s.buttonUnderlinedDisabled)} {...props}>
    {children}
  </button>
);

UnderlinedButton.defaultProps = defaultProps;
