import { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import { useGetComparison } from '~/data/comparisons/queries';
import minutesToMilliseconds from 'date-fns/minutesToMilliseconds';

const TIME_LIMIT = minutesToMilliseconds(30);
let dotsLength = 0;
const dotsString = '...';

const ComparisonDetailsProgress = () => {
  const { clientId, comparisonId } = useParams<{ clientId?: string; comparisonId?: string }>();
  const { data: comparisonDetails } = useGetComparison(clientId ?? '', comparisonId ?? '', {
    refetchInterval: 3000,
  });
  const [progress, setProgress] = useState(0);
  const startTime = comparisonDetails?.inserted;

  useEffect(() => {
    const timer = setInterval(() => {
      if (startTime) {
        const diff = new Date().getTime() - new Date(startTime).getTime();
        setProgress((diff * 100) / TIME_LIMIT);
        dotsLength = (dotsLength + 1) % 4;
      }
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="flex flex-col items-center mt-40">
      <Typography fontSize={24} className="text-light relative">
        Processing<span className="absolute">{dotsString.slice(0, dotsLength)}</span>
      </Typography>
      <div className="w-full">
        <LinearProgress className="h-2" variant="determinate" value={progress} />
      </div>
    </div>
  );
};

export default ComparisonDetailsProgress;
