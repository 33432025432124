import createBreadcrumbs from '~/app/utils/breadcrumbs';

import Typography from '@mui/material/Typography';
import { BreadcrumbsProps } from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';

const { Breadcrumbs: BreadcrumbsComponent, Provider, Anchor } = createBreadcrumbs();

export type itemProps = {
  item: {
    link: string;
    content: string;
    wrapperStyle?: any;
    component?: any;
  };
};

const Item = ({ item }: itemProps) => (
  <Link to={item.link} className="no-underline">
    <Typography fontSize={16} className="text-orange">
      {item.component}
    </Typography>
  </Link>
);

const Breadcrumbs = ({ ...props }: BreadcrumbsProps) => (
  <BreadcrumbsComponent
    {...props}
    components={{
      Item,
    }}
  />
);

Breadcrumbs.Provider = Provider;

Breadcrumbs.Anchor = Anchor;

export default Breadcrumbs;
