import React from 'react';
import { AnyPropsType } from '~/AnyPropsType';
import { Text } from '~/ui/index.js';
import { Box, Button } from '@mui/material';
import Modal from '~/ui/components/modal/index';

const DeleteClientPopup = ({ closeFunction, isLoading, clientName, handleDeleteClient }: AnyPropsType) => (
  <Modal
    open
    closeIcon
    onClose={closeFunction}
    modalTitle={<Text weight={Text.weight.medium}>Delete client</Text>}
    contentText={
      <Text size={Text.size.m}>
        Are you sure you want to delete <strong>{clientName}</strong> from clients list?
      </Text>
    }
  >
    <Box display="flex" justifyContent="flex-end">
      <Box marginRight="15px">
        <Button onClick={closeFunction} variant="outlined">
          Close
        </Button>
      </Box>
      <Button disabled={isLoading} onClick={handleDeleteClient} variant="contained">
        Remove
      </Button>
    </Box>
  </Modal>
);

export default DeleteClientPopup;
