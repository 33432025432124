import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ClientApi, ClientDto, ScopeType } from '~/data/openapi-client/index';
import { queryKeyBuilder } from '~/data/utils/helpers/query-key.builder';
import minutesToMilliseconds from 'date-fns/minutesToMilliseconds';
import { dazzler, type EndpointResponse } from '~/data/dazzler-api';

export const clientKeys = (() => {
  const base = 'clients';
  return {
    base: () => queryKeyBuilder(base),
    getClient: (clientId: string) => queryKeyBuilder(base, clientId, 'getClient'),
    successfullyProcessedScopeTypes: (clientId: string) =>
      queryKeyBuilder(base, clientId, 'successfullyProcessedScopeTypes'),
    getClientScopes: (clientId: string) => queryKeyBuilder(base, clientId, 'getClientScopes'),
  };
})();

export const useGetClient = (clientId: string, options?: Omit<UseQueryOptions<ClientDto>, 'queryKey' | 'queryFn'>) =>
  useQuery({
    queryFn: () => dazzler.client.get({ clientId }).then((res) => res.data),
    queryKey: clientKeys.getClient(clientId),
    staleTime: minutesToMilliseconds(15),
    enabled: !!clientId,
    ...options,
  });

export const useGetSuccessfullyProcessedScopeTypes = (
  clientId: string,
  options?: Omit<
    UseQueryOptions<EndpointResponse<ClientApi['listSuccessfullyProcessedScopeTypes']>>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery({
    queryFn: async function listSuccessfullyProcessedScopes({ signal }) {
      const response = await dazzler.client.listSuccessfullyProcessedScopeTypes(
        {
          clientId,
        },
        { signal },
      );

      return response.data;
    },
    queryKey: clientKeys.successfullyProcessedScopeTypes(clientId),
    staleTime: minutesToMilliseconds(5),
    ...options,
  });

export const useGetClientScopes = (
  clientId: string,
  options?: Omit<UseQueryOptions<ScopeType[]>, 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    queryFn: () => dazzler.client.listClientScopeTypes({ clientId }).then((res) => res.data),
    queryKey: clientKeys.getClientScopes(clientId),
    staleTime: minutesToMilliseconds(15),
    enabled: !!clientId,
    ...options,
  });
