import { AnyPropsType } from '~/AnyPropsType';
import cx from 'classnames';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '~/ui/components/icon/index';

import s from './styles.module.scss';

export const PrimaryLink = ({ className, children, disabled, to, ...props }: AnyPropsType) => (
  <RouterLink className={cx(s.link, disabled && s.linkDisabled, className)} to={to} {...props}>
    {children}
  </RouterLink>
);

PrimaryLink.defaultProps = {
  disabled: false,
  className: null,
  children: null,
};

// PrimaryLink.propTypes = {
//   disabled: PropTypes.bool,
//   className: PropTypes.string,
//   children: PropTypes.node,
// };

export const LinkIcon = ({
  className,
  children,
  disabled,
  shared,
  icon,
  contentFirst,
  iconClassName,
  to,
  ...props
}: AnyPropsType) => (
  <RouterLink className={cx(s.link, s.linkIcon, disabled && s.linkDisabled, className)} to={to} {...props}>
    {contentFirst && children}
    <Icon
      icon={icon}
      key={icon}
      className={iconClassName}
      style={{
        width: '15px',
        height: '15px',
        marginRight: !contentFirst && '10px',
        marginLeft: contentFirst && '10px',
      }}
    />
    {!contentFirst && children}
  </RouterLink>
);
// LinkIcon.propTypes = {
//   iconClassName: PropTypes.string,
//   contentFirst: PropTypes.bool,
//   disabled: PropTypes.bool,
//   className: PropTypes.bool,
//   shared: PropTypes.bool,
//   children: PropTypes.node,
//   icon: PropTypes.oneOf(Object.values(Icon.icons)),
// };

LinkIcon.defaultProps = {
  iconClassName: null,
  contentFirst: false,
  disabled: false,
  className: null,
  shared: false,
  children: null,
  icon: Icon.icons.arrowBack,
};

export const SecondaryLink = ({ className, children, disabled, to, ...props }) => (
  <RouterLink className={cx(s.link, s.linkSecondary, disabled && s.linkDisabled, className)} to={to} {...props}>
    {children}
  </RouterLink>
);

SecondaryLink.defaultProps = {
  disabled: false,
  className: null,
  children: null,
};

// SecondaryLink.propTypes = {
//   disabled: PropTypes.bool,
//   className: PropTypes.string,
//   children: PropTypes.node,
// };

export const TertiaryLink = ({ className, children, disabled, to, ...props }) => (
  <RouterLink className={cx(s.linkTertiary, disabled && s.linkDisabled, className)} to={to} {...props}>
    {children}
  </RouterLink>
);

TertiaryLink.defaultProps = {
  disabled: false,
  className: null,
  children: null,
};

// TertiaryLink.propTypes = {
//   disabled: PropTypes.bool,
//   className: PropTypes.string,
//   children: PropTypes.node,
// };

// Button links

// const buttonPropTypes = {
//   shared: PropTypes.bool,
//   disabled: PropTypes.bool,
//   className: PropTypes.string,
//   children: PropTypes.node.isRequired,
// };

export const ButtonLink = ({ className, children, disabled, shared, to, ...props }: AnyPropsType) => (
  <RouterLink className={cx(s.button, disabled && s.linkDisabled, className)} to={to} {...props}>
    {children}
  </RouterLink>
);

ButtonLink.defaultProps = {
  disabled: false,
  className: null,
  shared: false,
};

ButtonLink.Outlined = ({ className, disabled, ...props }: AnyPropsType) => (
  <ButtonLink className={cx(s.button, s.buttonOutlined, disabled && s.linkDisabled, className)} {...props} />
);

export const PrimaryButtonLink = ({ className, children, disabled, shared, to, ...props }: AnyPropsType) => (
  <RouterLink className={cx(s.button, s.buttonPrimary, disabled && s.linkDisabled, className)} to={to} {...props}>
    {children}
  </RouterLink>
);

PrimaryButtonLink.defaultProps = {
  disabled: false,
  className: null,
  shared: false,
};

PrimaryButtonLink.Outlined = ({ className, disabled, ...props }: AnyPropsType) => (
  <PrimaryButtonLink
    className={cx(s.button, s.buttonPrimaryOutlined, disabled && s.linkDisabled, className)}
    {...props}
  />
);

export const SecondaryButtonLink = ({ className, disabled, children, to, ...props }: AnyPropsType) => (
  <RouterLink className={cx(s.button, s.buttonSecondary, disabled && s.linkDisabled, className)} to={to} {...props}>
    {children}
  </RouterLink>
);

SecondaryButtonLink.defaultProps = {
  disabled: false,
  className: null,
  shared: false,
};

SecondaryButtonLink.Outlined = ({ className, disabled, ...props }: AnyPropsType) => (
  <SecondaryButtonLink
    className={cx(s.button, s.buttonSecondaryOutlined, disabled && s.linkDisabled, className)}
    {...props}
  />
);
