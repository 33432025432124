import * as constants from '~/data/constants/internal';
import type { ThunkArguments } from '~/data/store';

export const updatePackages =
  () =>
  async (dispatch, _, { api }: ThunkArguments) => {
    dispatch({ type: constants.UPDATE_START });
    try {
      const res = await api.post(`internal/atome-particles/update-packages`, undefined, {
        baseURL: api.prefixedURL,
      });

      dispatch({
        type: constants.UPDATE_SUCCESS,
        payload: res.data,
      });

      return res.data;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.UPDATE_FAILED });

      throw e;
    }
  };

export const fetchJobStatus =
  (jobId: string) =>
  async (dispatch, _, { api }: ThunkArguments) => {
    dispatch({ type: constants.JOB_FETCH_START });
    try {
      const res = await api.get(`/internal/jobs/${jobId}`, { baseURL: api.prefixedURL });

      dispatch({
        type: constants.JOB_FETCH_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (e) {
      dispatch({ type: constants.JOB_FETCH_FAILED });
      throw e;
    }
  };

export const fetchAuditLogs =
  (filter) =>
  async (dispatch, _, { api }: ThunkArguments) => {
    const newFilter = filter;
    if (newFilter) {
      Object.keys(newFilter).forEach((key) => {
        if (newFilter[key] === undefined) {
          delete newFilter[key];
        }
      });
    }
    dispatch({ type: constants.LOG_FETCH_START });
    try {
      const res = await api.get(`internal/audit-logs`, {
        params: newFilter,
        baseURL: api.prefixedURL,
      });
      dispatch({
        type: constants.LOG_FETCH_SUCCESS,
        count: res.data.count,
        payload: res.data.items,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LOG_FETCH_FAILED });
      throw e;
    }
  };

export const clear = () => ({ type: constants.CLEAR });
