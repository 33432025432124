import cx from 'classnames';
import React from 'react';
import colors from '~/ui/styles/colors.module.scss';
import fonts from '~/ui/styles/fonts.module.scss';
import sizes from '~/ui/styles/sizes.module.scss';
import weights from '~/ui/styles/weights.module.scss';

import s from './styles.module.scss';

type colorsType = {
  success: any;
  black: any;
  focus: any;
  darkgrey: any;
  lightgrey: any;
  white: any;
  orange: any;
  red: any;
  rose: any;
  burgundy: any;
  maroon: any;
  grey: any;
  switcherDividers: any;
  borderGrey: any;
  colorTextDark: any;
  colorTextMedium: any;
  statusError: any;
  statusSuccess: any;
  light: any;
  orangeLight: any;
  textDarkGrey: any;
  yellow: any;
};

type sizesType = {
  xxs: any;
  xs: any;
  s: any;
  m: any;
  l: any;
  xl: any;
  xxl: any;
};

type weightsType = {
  medium: any;
  semiMedium: any;
  normal: any;
};

type fontsType = {
  primary: any;
  secondary: any;
};

type textProps = {
  style?: any;
  color?: keyof colorsType;
  weight?: keyof weightsType;
  font?: keyof fontsType;
  size?: keyof sizesType;
  height?: keyof sizesType;
  className?: string;
  children: any;
  dataCy?: string;
  dataTip?: string;
};

const Text = ({ color, size, weight, height, font, className, children, style, dataCy, dataTip }: textProps) => (
  <span
    data-cy={dataCy}
    data-tip={dataTip}
    className={cx(s.text, color, size, weight, height, font, className)}
    style={style}
  >
    {children}
  </span>
);

Text.color = Object.entries(colors).reduce((obj, [colorName]) => {
  // eslint-disable-next-line
  obj[colorName] = s[`textColor__${colorName}`];
  return obj;
}, {} as colorsType);

Text.size = Object.entries(sizes).reduce((obj, [sizeName]) => {
  // eslint-disable-next-line
  obj[sizeName] = s[`textSize__${sizeName}`];
  return obj;
}, {} as sizesType);

Text.height = Object.entries(sizes).reduce((obj, [heightName]) => {
  // eslint-disable-next-line
  obj[heightName] = s[`textHeight__${heightName}`];
  return obj;
}, {} as sizesType);

Text.weight = Object.entries(weights).reduce((obj, [weightName]) => {
  // eslint-disable-next-line
  obj[weightName] = s[`textWeight__${weightName}`];
  return obj;
}, {} as weightsType);

Text.font = Object.entries(fonts).reduce((obj, [fontName]) => {
  // eslint-disable-next-line
  obj[fontName] = s[`textFont__${fontName}`];
  return obj;
}, {} as fontsType);

Text.defaultProps = {
  style: null,
  weight: Text.weight.normal,
  size: Text.size.l,
  color: s.textColor,
  height: s.textHeight,
  font: s.textFont,
  className: null,
  dataTip: null,
};

export { Text };
