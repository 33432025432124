import React from 'react';
import { AnyPropsType } from '~/AnyPropsType';
import { SelectInput, Text } from '~/ui/index.js';
import { Box, Button, TextField } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { ClientCreationSchema } from '~/data/index.js';
import FormField from '~/app/components/core/form/field';

import s from './styles.module.scss';
import AnimateHeight from 'react-animate-height';
import Modal from '~/ui/components/modal/index';
import { ClientCreateValidator, CountryCode } from '~/data/openapi-client/api';
import sortBy from 'lodash/sortBy';

const AddClientPopup = ({ closeFunction, addClient, user, isSuperAdmin }: AnyPropsType) => {
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
  const unsortedRegionOptions = Object.values(CountryCode).map((x) => ({
    value: x,
    label: regionNames.of(x),
  }));

  const regionOptions = sortBy(unsortedRegionOptions, (obj) => obj.label);

  const handleAddClient = async (values, formikActions) => {
    const res = await addClient(values, formikActions);
    if (res) {
      closeFunction();
    }
  };

  return (
    <Modal open closeIcon onClose={closeFunction} modalTitle={<Text weight={Text.weight.medium}>Add client</Text>}>
      <Formik<Partial<ClientCreateValidator>>
        onSubmit={handleAddClient}
        validationSchema={ClientCreationSchema}
        initialValues={{
          countryCode: undefined,
        }}
        enableReinitialize
      >
        {({ values, isSubmitting, errors }) => {
          return (
            <Form>
              {isSuperAdmin && (
                <Field
                  inputComponent={SelectInput}
                  options={regionOptions}
                  component={FormField}
                  id="countryCode"
                  name="countryCode"
                  placeholder="Country"
                  label="Country"
                  onChangeAdapter={(value) => value.value}
                  onBlurAdapter={(value) => value}
                  valueAdapter={(value) => regionOptions.find((x) => x.value === value)}
                  className={s.formField}
                />
              )}
              <Field
                inputComponent={TextField}
                component={FormField}
                id="name"
                name="name"
                placeholder="Client name"
                label="Client name"
                className={s.formField}
                fullWidth
              />
              <Field
                inputComponent={TextField}
                component={FormField}
                id="vatNumber"
                name="vatNumber"
                placeholder="Client VAT number"
                label="Client VAT number"
                className={s.formField}
                fullWidth
              />

              <Box className={s.formButtonsWrap}>
                <Button variant="outlined" data-cy="clientEditCancel" onClick={closeFunction}>
                  Cancel
                </Button>

                <Button variant="contained" data-cy="saveClient" type="submit" disabled={isSubmitting}>
                  Add client
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddClientPopup;
