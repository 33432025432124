import axios, { Axios, AxiosInstance, type CreateAxiosDefaults } from 'axios';
import { createAuthURL } from '~/data/index.js';
import * as apiConstants from '~/data/constants/api';
import * as authConstants from '~/data/constants/auth';
import { HTTPError } from '~/data/utils/errors';
import { getQueryStringParams } from '~/data/utils/query-string';
import * as redux from 'redux';
import { authUrl } from '~/config';
import { toast } from 'react-toastify';
import { logger } from '~/logger';

const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

class API {
  axios: AxiosInstance;
  store: redux.Store;

  constructor(config?: CreateAxiosDefaults) {
    this.errorInterceptor = this.errorInterceptor.bind(this);
    this.successInterceptor = this.successInterceptor.bind(this);
    this.setAuthorizationHeader = this.setAuthorizationHeader.bind(this);
    this.removeAuthorizationHeader = this.removeAuthorizationHeader.bind(this);

    this.axios = axios.create(config);
    this.axios.interceptors.response.use(this.successInterceptor, this.errorInterceptor);

    if (window) (window as any).api = this;
  }

  #axiosURL: string;

  /**
   * @deprecated as well as the entire redux integration
   * Needed for axios in redux actions
   */
  get prefixedURL() {
    return this.#axiosURL;
  }

  setAxiosURL(url: string) {
    this.#axiosURL = url;
  }

  /**
   * @deprecated the HTTP client should not know anything about the redux
   * @param store
   */
  attachReduxStore(store: redux.Store) {
    this.store = store;
  }

  static noRefresh = 'No token';

  successInterceptor(response) {
    this.store.dispatch({ type: apiConstants.SUCCESS });

    return response;
  }

  errorInterceptor(error) {
    if (error.message.match(/cancel/)) {
      return Promise.reject(error);
    }
    if (error.response) {
      switch (error.response.status) {
        case 503:
          toast.error(error.response.data.message ?? error.message);
          throw new HTTPError('', error.response.status);
        case 400:
          toast.error(error.response.data.message ?? error.message);
          logger.error(error.response.data.message ?? error.message);
          throw new HTTPError('Unexpected error', error);
        case 401:
          toast.error(error.response.data.message ?? error.message);
          window.sessionStorage.removeItem('auth');
          this.removeAuthorizationHeader();
          if (getQueryStringParams(window.location.search).mode === 'integration') {
            window.parent.postMessage({ code: '401' }, '*');
          } else {
            window.location.replace(createAuthURL(authUrl));
          }
          break;
        case 403:
          toast.error(error.response.data.message ?? error.message);
          throw new HTTPError('Forbidden', error.response.status);
        case 500:
          toast.error(`Internal server error: ${error.response.data.message ?? error.message}`);
          logger.error(error.response.data.message ?? error.message);
          throw new HTTPError('Server Error', error.response.status);
        default:
          break;
      }
    } else {
      toast.error(error.message ?? 'Unknown Network Error Occurred');
      if (error.config) {
        return wait(3001).then(() => Promise.reject(error) /* this.axios.request(reqConfig) */);
      }
    }
    return Promise.reject(error);
  }

  setAuthorizationHeader = (apiKey) => {
    this.axios.defaults.headers.common.Authorization = `Bearer ${apiKey}`;
  };

  getAuthorizationHeader() {
    return this.axios.defaults.headers.common.Authorization as string | undefined;
  }

  setRefresh(refresh) {
    this.axios.defaults.headers.common['x-refresh-token'] = refresh;
  }

  removeAuthorizationHeader() {
    delete this.axios.defaults.headers.common.Authorization;
  }

  get: Axios['get'] = (...args) => this.axios.get.apply(this, args);

  post: Axios['post'] = (...args) => this.axios.post.apply(this, args);

  patch: Axios['patch'] = (...args) => this.axios.patch.apply(this, args);

  put: Axios['put'] = (...args) => this.axios.put.apply(this, args);

  delete: Axios['delete'] = (...args) => this.axios.delete.apply(this, args);

  request: Axios['request'] = (...args) => this.axios.request.apply(this, args);
}

export default API;
