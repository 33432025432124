import { clientId } from '~/config';
import { api } from '~/data/dazzler-api';

export const refresh = async () => {
  const res = await api.post(`/auth/tokens`, {
    grantType: 'refresh_token',
    refreshToken: window.localStorage.getItem('refresh'),
    redirectUri: window.location.origin,
    clientId,
  });
  const { idToken, expiresIn, refreshToken } = res.data;

  api.setAuthorizationHeader(idToken);
  window.sessionStorage.setItem('auth', idToken);
  window.localStorage.setItem('refresh', refreshToken);
  window.sessionStorage.setItem('expiresIn', new Date().getTime() / 1000 + expiresIn);
};
