import React from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { adminPath, clientUsageReportPath, ebaImportsPagePath, particlesPagePath } from '~/app/constants/url/auditor';
import { Row, Text } from '~/ui/index.js';
import cx from 'classnames';

import s from './styles.module.scss';
import { EbaImports } from './eba-transparency-imports/EbaImports';
import { ParticlesUpdates } from './particles-updates/ParticlesUpdates';
import { ClientUsageReport } from './client-usage-report/ClientUsageReport';

export const AdminPanelRouter = () => (
  <div className={s.admin}>
    <Row className={s.adminRow}>
      <Text size={Text.size.xl} color={Text.color.colorTextDark}>
        Admin panel
      </Text>

      <div className={s.adminHeaderContentWrap}>
        <>
          <NavLink
            to={particlesPagePath(1)}
            className={(isActive) =>
              isActive
                ? cx(s.adminHeaderContentTopContentNavigationItem, s.adminHeaderContentTopContentNavigationItemActive)
                : s.adminHeaderContentTopContentNavigationItem
            }
          >
            <Text size={Text.size.s}>BR-AG Particles Updates</Text>
          </NavLink>

          <NavLink
            to={ebaImportsPagePath(1)}
            className={(isActive) =>
              isActive
                ? cx(s.adminHeaderContentTopContentNavigationItem, s.adminHeaderContentTopContentNavigationItemActive)
                : s.adminHeaderContentTopContentNavigationItem
            }
          >
            <Text size={Text.size.s}>EBA Transparency Imports</Text>
          </NavLink>

          <NavLink
            to={clientUsageReportPath}
            className={(isActive) =>
              isActive
                ? cx(s.adminHeaderContentTopContentNavigationItem, s.adminHeaderContentTopContentNavigationItemActive)
                : s.adminHeaderContentTopContentNavigationItem
            }
          >
            <Text size={Text.size.s}>Client Usage Report</Text>
          </NavLink>
        </>
      </div>
    </Row>
    <Switch>
      <Route path={particlesPagePath(':page([1-9]\\d*)')} component={ParticlesUpdates} />
      <Route path={ebaImportsPagePath(':page([1-9]\\d*)')} component={EbaImports} />
      <Route path={clientUsageReportPath} component={ClientUsageReport} />
      <Redirect from={adminPath} to={particlesPagePath(1)} />
    </Switch>
  </div>
);
