import React from 'react';
import { Text, UnderlinedButtonLink } from '~/ui/index.js';

import s from './styles.module.scss';

class ErrorBoundary extends React.Component<any> {
  // static propTypes = {
  //   children: PropTypes.node.isRequired,
  // };

  state: any;
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(e) {
    console.log(e);
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div className={s.warning}>
          <div className={s.warningAlert}>
            {/* <img className={s.warningAlertIcon} src={errorIcon} alt="Error" /> */}

            <Text className={s.warningAlertText} size={Text.size.l}>
              Oops, something went wrong
            </Text>
            <UnderlinedButtonLink to="/">
              <Text size={Text.size.s}>Back to home screen</Text>
            </UnderlinedButtonLink>
          </div>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
