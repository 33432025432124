/**
 * @deprecated use "downloadFile" instead
 * @param blob
 * @param fileName
 */
export const saveBlob = (blob, fileName) => {
  if ((navigator as any).msSaveBlob) {
    (navigator as any).msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }
};
