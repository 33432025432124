import * as authConstants from '~/data/constants/auth';
import * as constants from '~/data/constants/clientsAdmin';
import LoadingProgress from '~/data/utils/reducers/loading';
import Pagination from '~/data/utils/reducers/pagination';
import { fromJS } from 'immutable';

export const itemsPagination = new Pagination('clients');
export const loadingProgress = new LoadingProgress('clients');
export const listLoadingProgress = new LoadingProgress('clientsList');
export const changingRoleLoadingProgress = new LoadingProgress('changingRole');

export const connectedClientsPagination = new Pagination('connectedClients');
export const connectedClientsLoadingProgress = new LoadingProgress('connectedClients');

export const availableClientsPagination = new Pagination('availableClients');
export const availableClientsLoadingProgress = new LoadingProgress('availableClients');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { clientsAdmin },
    } = payload;
    newState.mergeIn(['entities'], fromJS(clientsAdmin));
  });

const loadItems = (state, action) =>
  state.withMutations((newState) => {
    const { result, limit } = action.payload;
    const { count } = action;
    mergeData(newState, action.payload);
    itemsPagination.set(newState, count, limit, fromJS(result));
    listLoadingProgress.setLoaded(newState);
  });

const loadItem = (state, payload) =>
  state.withMutations((newState) => {
    mergeData(newState, payload);
    loadingProgress.setLoaded(newState);
  });

const loadConnectedClients = (state, action) =>
  state.withMutations((newState) => {
    const { result, limit } = action.payload;
    const { count } = action;
    mergeData(newState, action.payload);
    connectedClientsPagination.set(newState, count, limit, fromJS(result));
    connectedClientsLoadingProgress.setLoaded(newState);
  });

const loadAvailableClients = (state, action) =>
  state.withMutations((newState) => {
    const { result, limit } = action.payload;
    const { count } = action;
    mergeData(newState, action.payload);
    availableClientsPagination.set(newState, count, limit, fromJS(result));
    availableClientsLoadingProgress.setLoaded(newState);
  });

const deleteConnectedClient = (state, id) =>
  state.withMutations((newState) => {
    connectedClientsLoadingProgress.changeProgress(newState, false, true, false);
    newState.deleteIn(['entities', id]);
    connectedClientsPagination.removePageItem(newState, id);
  });

const deleteClient = (state, id) =>
  state.withMutations((newState) => {
    loadingProgress.changeProgress(newState, false, true, false);
    newState.deleteIn(['entities', id]);
    itemsPagination.removePageItem(newState, id);
  });

const changeUserRole = (state, action) =>
  state.withMutations((newState) => {
    const { userId, clientId, isAdmin } = action.payload;
    const inArray = ['entities', clientId, 'clientUsers'];

    changingRoleLoadingProgress.changeProgress(newState, false, true, false);
    state.getIn(inArray).forEach((user, index) => {
      if (user.get('userId') === userId) {
        newState.setIn([...inArray, index, 'isAdmin'], isAdmin);
      }
    });
  });

const initialState = fromJS({
  entities: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadItems(state, action);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);

    case constants.DELETE_CONNECTED_CLIENT_SUCCESS:
      return deleteConnectedClient(state, action.id);
    case constants.DELETE_CLIENT_SUCCESS:
      return deleteClient(state, action.id);

    case constants.DELETE_CONNECTED_CLIENT_START:
      return connectedClientsLoadingProgress.setLoading(state);
    case constants.CONNECTED_CLIENTS_LOAD_SUCCESS:
      return loadConnectedClients(state, action);
    case constants.CONNECTED_CLIENTS_LOAD_FAILED:
      return connectedClientsLoadingProgress.setLoadFailed(state);

    case constants.AVAILABLE_CLIENTS_LOAD_START:
      return availableClientsLoadingProgress.setLoading(state);
    case constants.AVAILABLE_CLIENTS_LOAD_SUCCESS:
      return loadAvailableClients(state, action);
    case constants.AVAILABLE_CLIENTS_LOAD_FAILED:
      return availableClientsLoadingProgress.setLoadFailed(state);

    case constants.CHANGE_USER_START:
      return changingRoleLoadingProgress.setLoading(state);
    case constants.CHANGE_USER_SUCCESS:
      return changeUserRole(state, action);
    case constants.CHANGE_USER_FAILED:
      return changingRoleLoadingProgress.setLoadFailed(state);

    case constants.LOAD_START:
      return loadingProgress.setLoading(state);
    case constants.LOAD_SUCCESS:
    case constants.UPDATE_SUCCESS:
      return loadItem(state, action.payload);
    case constants.LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);
    case constants.CLEAR_SINGLE:
      return loadingProgress.clear(state);
    case authConstants.SESSION_CLOSE:
    case constants.CLEAR:
      return initialState;
    default:
      return state;
  }
};
