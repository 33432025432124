import Breadcrumbs from '~/app/components/core/breadcrumbs/index';
import Header from '~/app/components/core/header/index';
import Layout from '~/app/components/core/layout/index';
import ForbiddenPopup from '~/app/components/core/popup/forbidden';
import Loader from '~/app/components/core/preloader/index';
import {
  adminPath,
  baseAuditorPath,
  clientsPath,
  manageClientsPath,
  privacyPath,
  taxonomiesPath,
  usersPath,
} from '~/app/constants/url/auditor';
import ProtectedRoute from '~/app/utils/protected-route';
import { actions, Roles, selectors } from '~/data/index.js';
import jwtDecode from 'jwt-decode';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { useAppDispatch, useAppSelector } from '~/data/utils/hooks';
import { ClientsRouter } from './clients/ClientsRouter';
import { ClientsContainer } from './manage-clients/ManageClientsRouter';
import PrivacyRoute from './privacy/index';
import TaxonomiesContainer from './taxonomies/index';
import { UsersRoute } from './manage-users/ManageUsersRouter';
import { LandingPage } from './landing-page/landing-page';
import { AdminPanelRouter } from './admin-panel/AdminPanelRouter';

const AuditorRoot = ({ hardShutdown, update }) => {
  const dispatch = useAppDispatch();

  const isAccessDenied = useAppSelector(selectors.api.getForbidden);
  const isLoggedIn = useAppSelector(selectors.auth.isLoggedIn);
  const role = useAppSelector(selectors.auth.getSessionRole);
  const token = useAppSelector(selectors.auth.getSessionToken);
  const apiUnauthorized = useAppSelector(selectors.api.getUnauthorized);

  const cancelToken = () => dispatch(actions.auth.cancelToken());
  const signOut = () => dispatch(actions.auth.signOut());
  const loginLocally = (code) => dispatch(actions.auth.loginLocally(code));
  const updateClients = () => dispatch(actions.clients.updateClients());
  const refresh = () => dispatch(actions.auth.refresh());
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');

  useEffect(() => {
    if (!isLoggedIn && code) {
      (async () => {
        const success = await loginLocally(code);
        updateClients();
        const redirectUrl = window.localStorage.getItem('redirectUrl');
        if (redirectUrl && success) {
          window.location.replace(redirectUrl);
        }
      })();
    }
  }, []);

  const logout = async () => {
    await cancelToken();
    signOut();
    hardShutdown();
    window.location.replace(baseAuditorPath);
  };

  if (!isLoggedIn || !role) {
    if (isAccessDenied) {
      return <ForbiddenPopup logout={logout} />;
    }
    if (!code && !isLoggedIn) {
      return <LandingPage />;
    }
    return <Loader />;
  }

  if (apiUnauthorized) {
    signOut();
    window.localStorage.setItem('redirectUrl', window.location.href);
    window.location.replace(baseAuditorPath);
  }

  const isAdmin = role === Roles.ADMIN || role === Roles.CLIENT_ADMIN;
  const isSuperAdmin = role === Roles.ADMIN;
  try {
    const { email } = jwtDecode<{ email: string }>(token);
    update({ email });
  } catch (e) {
    console.log(e);
  }

  return (
    <Breadcrumbs.Provider>
      <Layout
        refresh={refresh}
        logout={logout}
        header={<Header logout={logout} isAdmin={isAdmin} isSuperAdmin={isSuperAdmin} />}
        content={
          <Switch>
            {/* @ts-expect-error - Type '{ path: string; component: () => Element; }' is not assignable to type 'IntrinsicAttributes & Omit<AnyPropsType, string | number>'. Property 'path' does not exist on type 'IntrinsicAttributes & Omit<AnyPropsType, string | number>'.*/}
            <ProtectedRoute path={manageClientsPath} component={ClientsContainer} />
            {/* @ts-expect-error - Type '{ path: string; component: () => Element; }' is not assignable to type 'IntrinsicAttributes & Omit<AnyPropsType, string | number>'. Property 'path' does not exist on type 'IntrinsicAttributes & Omit<AnyPropsType, string | number>'. */}
            <ProtectedRoute path={usersPath} component={UsersRoute} />
            {/* @ts-expect-error - Type '{ path: string; component: () => Element; allowedRoles: string; }' is not assignable to type 'IntrinsicAttributes & Omit<AnyPropsType, string | number>'. Property 'path' does not exist on type 'IntrinsicAttributes & Omit<AnyPropsType, string | number>'.*/}
            <ProtectedRoute path={adminPath} component={AdminPanelRouter} allowedRoles={Roles.ADMIN} />
            <Route path={clientsPath} component={ClientsRouter} />
            <Route path={privacyPath} component={PrivacyRoute} />
            <Route path={taxonomiesPath} component={TaxonomiesContainer} />
            <Redirect from={baseAuditorPath} to={clientsPath} />
          </Switch>
        }
      />
      {isAccessDenied && <ForbiddenPopup logout={logout} />}
    </Breadcrumbs.Provider>
  );
};

const withIntercom = (Component) => (props) => {
  const intercomProps = useIntercom();
  return <Component {...props} {...intercomProps} />;
};

export default withIntercom(AuditorRoot);
