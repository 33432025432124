import { AnyPropsType } from '~/AnyPropsType';
import { clientsPagePath, reportsPath } from '~/app/constants/url/auditor';
import React from 'react';
import { Row, SelectInput, Text } from '~/ui/index.js';
import { formatDate } from '~/app/utils/format-data';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Grid,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import TableRowsLoader from '~/ui/components/mui-table/table-loader';
import Search from '@mui/icons-material/Search';

import s from './styles.module.scss';
import cx from '~/data/utils/helpers/cx';
import { Show } from '~/app/components/core/show';

const options = [
  { value: 'LastUpdated', label: 'Recent update' },
  { value: 'Name', label: 'Alphanumeric' },
];

const defaultSortDirectionMap = {
  LastUpdated: 'DESC',
  Name: 'ASC',
};

const Clients = ({
  clients,
  onSearch,
  search,
  onSort,
  sort,
  page,
  perPage,
  totalItems,
  onPagination,
  isClientsLoaded,
}: AnyPropsType) => {
  const history = useHistory();

  const columns = [
    {
      id: 'clientName',
      field: 'clientName',
      label: 'Client name',
      width: 750,
    },
    { id: 'vatNumber', field: 'vatNumber', label: 'VAT Number', width: 100 },
    {
      id: 'lastUpdated',
      field: 'lastUpdated',
      label: 'Last updated',
      width: 100,
    },
  ];

  const rows: any[] =
    clients
      ?.map((client) => ({
        clientName: client.get('name'),
        vatNumber: client.get('vatNumber'),
        lastUpdated: formatDate(client.get('lastUpdated')),
        id: client.get('id'),
      }))
      ?.toJS() || [];

  return (
    <>
      <div>
        <Row>
          <Typography fontSize={24} component="h1" className="text-dark">
            Clients
          </Typography>
        </Row>
        <Grid container spacing={3} className="mt-2">
          <Grid item xs={3}>
            <TextField
              placeholder="Search client"
              onChange={({ target: { value } }) => onSearch(value)}
              value={search}
              size="small"
              color="primary"
              fullWidth
            />
          </Grid>
          <Grid item xs={3} data-cy="clientSort">
            <Select
              IconComponent={ExpandMoreIcon}
              className="w-full"
              name="sort-clients"
              value={sort}
              onChange={(e) => {
                onSort({ key: e.target.value, direction: defaultSortDirectionMap[e.target.value] });
              }}
              input={<OutlinedInput />}
            >
              {options.map((option) => (
                <MenuItem key={option.label} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        <Row className={s.clientsTableRow}>
          <Paper classes={{ root: 'rounded-lg' }}>
            <TableContainer>
              <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, i) => (
                      <TableCell
                        key={column.id}
                        align={i !== 0 ? 'right' : 'left'}
                        classes={{ root: cx(i !== 0 && 'w-[150px]') }}
                      >
                        <Typography fontSize={16} className="text-medium">
                          {column.label}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isClientsLoaded ? (
                    rows.map((row) => (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.code}
                        component={Link}
                        to={reportsPath(row.id)}
                        className="hover:text-orange text-medium"
                      >
                        {columns.map((column, i) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={i !== 0 ? 'right' : 'left'}
                              className={cx(i !== 0 ? 'w-[150px]' : '!text-inherit')}
                            >
                              <Typography fontSize={16} className="text-inherit">
                                {value}
                              </Typography>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                  ) : (
                    <TableRowsLoader rowsNum={perPage} colsNum={3} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Show when={totalItems > 10}>
              <TablePagination
                component="div"
                classes={{ toolbar: 'justify-end' }}
                rowsPerPageOptions={[10, 25, 100]}
                count={totalItems}
                rowsPerPage={perPage}
                page={page - 1}
                onPageChange={(e, p) => {
                  history.push(clientsPagePath(p + 1));
                }}
                onRowsPerPageChange={onPagination}
                labelRowsPerPage="Items per page"
              />
            </Show>
          </Paper>
        </Row>
      </div>
    </>
  );
};

export default Clients;
