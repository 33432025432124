const prefix = (type) => `dazzler/auth/${type}`;

export const SESSION_LOADING = prefix('SESSION_LOADING');
export const SESSION_LOADED = prefix('SESSION_LOADED');
export const SESSION_LOAD_FAILED = prefix('SESSION_LOAD_FAILED');
export const SESSION_CLOSE = prefix('SESSION_CLOSE');

export const LOGIN_SUCCESS = prefix('LOGIN_SUCCESS');
export const LOGIN_FAILED = prefix('LOGIN_FAILED');

export const CHECK_IS_CLIENT_ADMIN_START = prefix('CHECK_IS_CLIENT_ADMIN_START');
export const CHECK_IS_CLIENT_ADMIN_SUCCESS = prefix('CHECK_IS_CLIENT_ADMIN_SUCCESS');
export const CHECK_IS_CLIENT_ADMIN_FAILED = prefix('CHECK_IS_CLIENT_ADMIN_FAILED');

export const REFRESH_LOADING = prefix('REFRESH_LOADING');
export const REFRESH_LOADED = prefix('REFRESH_LOADED');
export const REFRESH_LOAD_FAILED = prefix('REFRESH_LOAD_FAILED');
