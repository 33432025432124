import Breadcrumbs from '~/app/components/core/breadcrumbs/index';
import { reportComparisonsPagePath, reportComparisonsPath } from '~/app/constants/url/auditor';
import React from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';

import ComparisonsList from './ComparisonsList';

const ReportComparisonsRouter = () => {
  const { clientId, reportId, comparisonId } = useParams<{
    clientId: string;
    reportId: string;
    comparisonId: string;
  }>();
  return (
    <>
      <Breadcrumbs.Anchor
        path={reportComparisonsPath(':clientId', ':reportId', ':comparisonId')}
        link={reportComparisonsPath(clientId, reportId, comparisonId)}
        component={<span title="Report">Comparisons</span>}
      />
      <Switch>
        <Route
          exact
          path={reportComparisonsPagePath(':clientId', ':reportId', ':comparisonId', ':page([1-9]\\d*)')}
          component={ComparisonsList}
        />
        <Redirect
          from={reportComparisonsPath(clientId, reportId, comparisonId)}
          to={reportComparisonsPagePath(clientId, reportId, comparisonId, 1)}
        />
      </Switch>
    </>
  );
};

export default ReportComparisonsRouter;
