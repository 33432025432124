import * as actions from '../actions/auth';
import * as constants from '../constants/auth';
import * as selectors from '../selectors/auth';

export const jwtCheck =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (typeof action === 'function') {
      const token = window.sessionStorage.getItem('auth');
      const expiresIn = window.sessionStorage.getItem('expiresIn');

      if (token && expiresIn) {
        const tokenHasExpired = new Date().getTime() / 1000 > Number(expiresIn) - 60;
        const tokenAboutToExpire = new Date().getTime() / 1000 > Number(expiresIn) - 60 * 1;

        if (!selectors.getRefreshLoading(getState()) && !selectors.getSessionLoading(getState())) {
          if (tokenHasExpired) {
            dispatch({ type: constants.REFRESH_LOADING });
            return dispatch(actions.refresh()).then(() => next(action));
          }

          if (tokenAboutToExpire) {
            dispatch({ type: constants.REFRESH_LOADING });
            dispatch(actions.refresh());
          }
        }
      }
    }
    return next(action);
  };
