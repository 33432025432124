import {
  Button,
  Chip,
  Divider,
  FormControl,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  TextField,
  Typography,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import * as Tabs from '@radix-ui/react-tabs';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createEnumParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { Show } from '~/app/components/core/show';
import { useDebounceValue } from '~/app/utils/debounce';
import useIntersectionObserver from '~/app/utils/hooks/useIntersectionObserver';
import { ValidationCheckReviewStatus } from '~/data/openapi-client/index';
import {
  useGetReport,
  useGetValidationChecks,
  useGetVersions,
  useListVersionVisualExcels,
} from '~/data/reports/queries';
import { ValidationCheckReviewStatusParam } from '~/data/query-params';
import cx from '~/data/utils/helpers/cx';
import { CheckSource } from '~/data/utils/types';
import { ReadonlyCheckCard } from './card';
import { logger } from '~/logger';
import { Workbook, type WorkbookHandle } from '~/app/components/visual-excel/workbook';
import type { CellRange } from '~/app/components/visual-excel/worksheet';
import { Match, Switch } from '~/app/components/core/switch';
import Breadcrumbs from '~/app/components/core/breadcrumbs';
import { reportVersionReadonlyCheckPath } from '~/app/constants/url/auditor';

const sourceOptions = [
  {
    value: CheckSource.PWC,
    label: CheckSource.PWC,
  },
  {
    value: CheckSource.EBA,
    label: CheckSource.EBA,
  },
  {
    value: undefined,
    label: 'All',
  },
];

const thresholdSelectOptions = [
  {
    label: '<',
    value: 'lt',
  },
  {
    label: '>',
    value: 'gt',
  },
] as const;

type ThresholdFilter = (typeof thresholdSelectOptions)[number]['value'];

function ReadonlyChecksPage() {
  const {
    clientId = '',
    reportId = '',
    versionId = '',
  } = useParams<{ clientId?: string; reportId?: string; versionId?: string }>();
  const lastCardRef = React.useRef<HTMLLIElement | null>(null);
  const workbookHandle = React.useRef<WorkbookHandle>(null);

  const [reportFileId, setReportFileId] = useState<string | null>(null);
  const [openedCheckId, setOpenedCheckId] = useState<null | string>(null);

  const [params, setParams] = useQueryParams({
    status: withDefault(ValidationCheckReviewStatusParam, ValidationCheckReviewStatus.Pending),
    search: withDefault(StringParam, undefined),
    source: withDefault(StringParam, undefined),
    threshold: withDefault(StringParam, undefined),
    thresholdFilter: withDefault(createEnumParam(thresholdSelectOptions.map((item) => item.value)), 'gt'),
    subject: withDefault(StringParam, undefined),
  });

  const search = useDebounceValue(params.search, 300);
  const { source, threshold, subject, thresholdFilter } = params;

  const { data: report, isInitialLoading: isReportLoading } = useGetReport(clientId, reportId);

  const readonlyVersion = report?.versions.find((v) => v.id === versionId);

  const { data: currentVersion, isInitialLoading: isCurrentVersionLoading } = useGetVersions(
    clientId,
    reportId,
    versionId ?? '',
  );

  const { data: visualExcels, isLoading: isVisualExcelListLoading } = useListVersionVisualExcels(
    clientId,
    reportId,
    currentVersion?.id!,
    {
      enabled: !!clientId && !!reportId && !!currentVersion?.id,
    },
  );

  // versionParticlesFileId, reportFileId
  const versionFileMap = useMemo(() => {
    const map = new Map<string, string>();

    for (const visualExcel of visualExcels ?? []) {
      map.set(visualExcel.versionParticlesFileId, visualExcel.reportFileId);
    }

    return map;
  }, [visualExcels]);

  const subjectOptions = [
    ...(currentVersion?.subjects?.map((item) => ({
      value: item,
      label: item,
    })) ?? []),
    {
      value: undefined,
      label: 'All',
    },
  ];

  const {
    data: checks,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading: isChecksLoading,
    isFetching: isChecksFetching,
  } = useGetValidationChecks(clientId, reportId, versionId ?? '', {
    ...params,
    status: params.status,
    search,
    threshold:
      params.threshold && !Number.isNaN(+params.threshold)
        ? `${params.thresholdFilter} ${params.threshold}`
        : undefined,
  });

  const statusTab = params.status;

  const checkList = checks?.pages.flatMap((page) => page.items) ?? [];

  const fileTitle = visualExcels?.find((file) => file.reportFileId === reportFileId)?.filename;

  const loadMoreChecks = () => {
    if (!hasNextPage || isFetchingNextPage) return;
    fetchNextPage();
  };

  const observer = useIntersectionObserver((entry) => {
    if (!entry[0].isIntersecting) return;
    loadMoreChecks();
  });

  const handleSelectRange = (versionParticlesFileId: string, sheet: string, range: CellRange) => {
    if (!versionFileMap.has(versionParticlesFileId)) {
      logger.error('There is no version particles file id %s mapped to a spreadsheet', versionParticlesFileId);
      return;
    }

    workbookHandle.current!.selectRange(versionFileMap.get(versionParticlesFileId)!, sheet, range);
  };

  const workbookHandlers = useMemo(
    () => ({
      updateFileId: (reportFileId: string) => {
        setReportFileId(reportFileId);
      },
    }),
    [],
  );

  return (
    <div>
      <Breadcrumbs.Anchor
        path={reportVersionReadonlyCheckPath(':clientId', ':reportId', ':versionId')}
        link={reportVersionReadonlyCheckPath(':clientId', ':reportId', ':versionId')}
        component={
          <span>
            {`Version ${readonlyVersion?.order ?? ''} Review Checks`}
            <Chip label="Readonly" size="small" className="bg-primary-blue text-white ml-1" />
          </span>
        }
      />
      <Typography fontSize={24} component="h1">
        {isReportLoading ? <Skeleton variant="text" /> : report?.name}
      </Typography>
      <Tabs.Root
        value={statusTab}
        onValueChange={(value) => {
          setParams({ status: value as ValidationCheckReviewStatus });
        }}
        className="mt-4"
      >
        <Tabs.List className="flex gap-1">
          <Tabs.Trigger className="basis-[16%]" value={ValidationCheckReviewStatus.Pending}>
            <div
              className={cx(
                'rounded-[4px] w-full border-t-[3px] border-orange bg-white p-2 flex flex-col items-start',
                statusTab === ValidationCheckReviewStatus.Pending &&
                  'border-b border-l border-r bg-orange bg-opacity-10',
              )}
            >
              <Typography fontSize={10}>To review</Typography>
              <Typography fontSize={20} className="w-full text-start">
                {isCurrentVersionLoading ? (
                  <Skeleton variant="text" />
                ) : (
                  Intl.NumberFormat().format(currentVersion?.pendingChecksCount ?? 0)
                )}
              </Typography>
            </div>
          </Tabs.Trigger>
          <Divider orientation="vertical" flexItem className="mx-2 border-[#DEDEDE]" />
          <Tabs.Trigger className="basis-[16%]" value={ValidationCheckReviewStatus.Flagged}>
            <div
              className={cx(
                'rounded-[4px] w-full border-t-[3px] border-[#EB8C00] bg-white p-2 flex flex-col items-start',
                statusTab === ValidationCheckReviewStatus.Flagged &&
                  'border-b border-l border-r bg-[#EB8C00] bg-opacity-10',
              )}
            >
              <Typography fontSize={10}>Flagged</Typography>
              <Typography fontSize={20} className="w-full text-start">
                {isCurrentVersionLoading ? (
                  <Skeleton variant="text" />
                ) : (
                  Intl.NumberFormat().format(currentVersion?.flaggedChecksCount ?? 0)
                )}
              </Typography>
            </div>
          </Tabs.Trigger>
          <Divider orientation="vertical" flexItem className="mx-2 border-[#DEDEDE]" />
          <Tabs.Trigger className="basis-[16%]" value={ValidationCheckReviewStatus.Waived}>
            <div
              className={cx(
                'rounded-[4px] w-full border-t-[3px] border-[#2B9AF3] bg-white p-2 flex flex-col items-start',
                statusTab === ValidationCheckReviewStatus.Waived &&
                  'border-b border-l border-r bg-[#2B9AF3] bg-opacity-10',
              )}
            >
              <Typography fontSize={10}>Waived</Typography>
              <Typography fontSize={20} className="w-full text-start">
                {isCurrentVersionLoading ? (
                  <Skeleton variant="text" />
                ) : (
                  Intl.NumberFormat().format(currentVersion?.waivedChecksCount ?? 0)
                )}
              </Typography>
            </div>
          </Tabs.Trigger>

          <Tabs.Trigger className="basis-[16%]" value={ValidationCheckReviewStatus.BelowThreshold}>
            <div
              className={cx(
                'rounded-[4px] w-full border-t-[3px] border-[#22992E] bg-white p-2 flex flex-col items-start',
                statusTab === ValidationCheckReviewStatus.BelowThreshold &&
                  'border-b border-l border-r bg-[#22992E] bg-opacity-10',
              )}
            >
              <Typography fontSize={10}>Passed below threshold</Typography>
              <Typography fontSize={20} className="w-full text-start">
                {isCurrentVersionLoading ? (
                  <Skeleton variant="text" />
                ) : (
                  Intl.NumberFormat().format(currentVersion?.belowThresholdChecksCount ?? 0)
                )}
              </Typography>
            </div>
          </Tabs.Trigger>
          <Tooltip title='to see all checks, download the Validation checks in the "Downloads" section'>
            <div className="basis-[16%]">
              <div className="rounded-[4px] w-full border-t-[3px] border-[#22992E] bg-white p-2 flex flex-col items-start">
                <Typography fontSize={10}>Passed</Typography>
                <Typography fontSize={20} className="w-full text-start">
                  {isCurrentVersionLoading ? (
                    <Skeleton variant="text" />
                  ) : (
                    Intl.NumberFormat().format(currentVersion?.successfulChecksCount ?? 0)
                  )}
                </Typography>
              </div>
            </div>
          </Tooltip>

          <Divider orientation="vertical" flexItem className="mx-2 border-[#DEDEDE]" />
          <Tooltip title='to see all checks, download the Validation checks in the "Downloads" section'>
            <div className="basis-[16%]">
              <div className="rounded-[4px] w-full border-t-[3px] border-text-dark-grey bg-white p-2 flex flex-col items-start">
                <Typography fontSize={10}>Total</Typography>
                <Typography fontSize={20} className="w-full text-start">
                  {isCurrentVersionLoading ? (
                    <Skeleton variant="text" />
                  ) : (
                    Intl.NumberFormat().format(currentVersion?.validationChecksCount ?? 0)
                  )}
                </Typography>
              </div>
            </div>
          </Tooltip>
        </Tabs.List>
      </Tabs.Root>
      <div className="flex justify-between items-center">
        <div className="mt-4 flex gap-4 flex-wrap">
          <TextField
            className="w-[321px]"
            label="Search checks"
            value={params.search ?? ''}
            onChange={(e) => {
              setParams({ search: e.target.value });
            }}
          />
          <FormControl className="w-[160px]">
            <InputLabel>Source</InputLabel>

            <Select
              value={params.source ?? ''}
              onChange={(e) => {
                setParams({ source: e.target.value });
              }}
              label="Source"
            >
              {sourceOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Paper className="w-[180px] h-[45px] py-1 px-2 flex gap-1 mt-1">
            <Select
              classes={{ select: '!py-1.5 !pl-1.5' }}
              value={params.thresholdFilter}
              onChange={(e) => {
                setParams({ thresholdFilter: e.target.value as ThresholdFilter });
              }}
            >
              {thresholdSelectOptions.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
            <InputBase
              type="number"
              className="w-[160px]"
              placeholder="Threshold"
              // passing ' ' to show empty string, because '' is the same as undefined for InputBase
              value={params.threshold === '' ? ' ' : params.threshold ?? ''}
              onChange={(e) => {
                if (Number.isNaN(+e.target.value)) return;
                setParams({ threshold: e.target.value });
              }}
            />
          </Paper>

          <FormControl className="w-[160px]">
            <InputLabel>Subject</InputLabel>

            <Select
              value={params.subject ?? ''}
              onChange={(e) => {
                setParams({ subject: e.target.value });
              }}
              label="Subject"
            >
              {subjectOptions.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {!!visualExcels?.length ? (
            <FormControl className="w-[160px]">
              <InputLabel>File</InputLabel>

              <Select
                defaultValue={visualExcels?.[0].reportFileId}
                value={reportFileId ?? ''}
                onChange={(e) => {
                  workbookHandle.current?.setActiveFileId(e.target.value ?? '');
                  workbookHandle.current?.clearSelection();
                }}
                label="File"
              >
                {visualExcels?.map((item) => (
                  <MenuItem key={item.reportFileId} value={item.reportFileId}>
                    {item.filename}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
          <Button
            className="bg-orange text-white h-[45px] mt-1"
            disabled={!search && !source && !threshold && !subject && thresholdFilter === 'gt'}
            variant="contained"
            onClick={() => {
              setParams({
                search: undefined,
                source: undefined,
                threshold: undefined,
                subject: undefined,
                thresholdFilter: 'gt',
              });
            }}
          >
            Reset
          </Button>
          <Show when={!!search || !!source || !!threshold || !!subject}>
            <div className="flex items-center">
              <Typography className="text-sm font-medium">Filter Result: {checks?.pages.at(-1)?.count}</Typography>
            </div>
          </Show>
        </div>
      </div>

      <div className="flex gap-2">
        <div className="basis-1/2 w-1/2 mt-4">
          <Switch>
            <Match when={(visualExcels?.length ?? 0) > 0}>
              <div className="sticky top-0">
                <Typography component="h1" className={fileTitle ? 'block mb-4 font-medium text-xl' : 'hidden'}>
                  {fileTitle}
                </Typography>
                <Workbook handlers={workbookHandlers} ref={workbookHandle} fileList={visualExcels ?? []} />
              </div>
            </Match>
            <Match when={isVisualExcelListLoading}>
              <Skeleton variant="rectangular" width="100%" height={500} className="rounded-[4px]" />
            </Match>
          </Switch>
        </div>
        <div className="mt-[60px] flex flex-col gap-1 basis-1/2">
          <Show when={isChecksFetching}>
            <LinearProgress />
          </Show>
          {checkList.map((item) => (
            <li
              key={item.id}
              className="list-none"
              ref={(node) => {
                if (item.id !== checkList.at(-1)?.id || !node) return;

                if (lastCardRef.current) {
                  observer.unobserve(lastCardRef.current);
                }

                if (!hasNextPage) return;
                lastCardRef.current = node;
                observer.observe(node);
              }}
            >
              <ReadonlyCheckCard
                item={item}
                checkList={checkList}
                handleSelectRange={handleSelectRange}
                setOpenedCheckId={setOpenedCheckId}
                open={item.id === openedCheckId}
              />
            </li>
          ))}
          <Show when={isFetchingNextPage || isChecksLoading}>
            <>
              {new Array(isChecksLoading ? 10 : 3).fill(null).map(() => (
                <Skeleton variant="rectangular" width="100%" height={124} className="rounded-[4px]" />
              ))}
            </>
          </Show>
        </div>
      </div>
    </div>
  );
}

export default ReadonlyChecksPage;
