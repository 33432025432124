const prefix = (type) => `dazzler/api/${type}`;

export const UNAUTHORIZED = prefix('UNAUTHORIZED');
export const SERVER_ERROR = prefix('SERVER_ERROR');
export const NOT_FOUND = prefix('NOT_FOUND');
export const CONNECTION_ERROR = prefix('CONNECTION_ERROR');

export const SUCCESS = prefix('SUCCESS');

export const CLEAR = prefix('CLEAR');

export const RESET = prefix('RESET');
export const SERVICE_UNAVAILABLE = prefix('SERVICE_UNAVAILABLE');
export const FORBIDDEN = prefix('FORBIDDEN');
export const UNEXPECTED_ERROR = prefix('UNEXPECTED_ERROR');
