import { AnyPropsType } from '~/AnyPropsType';
import React from 'react';
import { Text } from '~/ui/index.js';
import { Button } from '@mui/material';
import Modal from '~/ui/components/modal/index';

import s from './styles.module.scss';

const ErrorPopup = ({ closeFunction, error }: AnyPropsType) => (
  <Modal
    open
    closeIcon
    onClose={closeFunction}
    modalTitle={<Text weight={Text.weight.medium}>Server Error</Text>}
    contentText={
      error?.reason === 'ActivityLimitReached'
        ? 'There are too many pending requests to be able to download the report. Please try again in an hour.'
        : error?.message || 'Contact your system administrator if this keeps happening.'
    }
  >
    <div className={s.errorForm}>
      <div className={s.errorFormButtons}>
        <Button onClick={closeFunction} variant="outlined">
          Close
        </Button>
      </div>
    </div>
  </Modal>
);

// ErrorPopup.propTypes = {
//   closeFunction: PropTypes.func.isRequired,
//   error: PropTypes.shape({
//     message: PropTypes.string.isRequired,
//     reason: PropTypes.string.isRequired,
//   }).isRequired,
// };

export default ErrorPopup;
