import * as constants from '~/data/constants/api';
import { fromJS } from 'immutable';

const initialState = fromJS({
  unauthorized: false,
  serverError: false,
  connectionError: false,
  notFound: false,
  serviceUnavailable: false,
});

const setError = (state, type, error) =>
  state.withMutations((newState) => {
    newState.set(type, true);
    newState.set('error', error);
  });

const reset = (state) =>
  state.withMutations((newState) => {
    newState.set('unauthorized', false);
    newState.set('serverError', false);
    newState.set('connectionError', false);
    newState.set('notFound', false);
    newState.set('serviceUnavailable', false);
    newState.set('unexpectedError', false);
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.UNAUTHORIZED:
      return (state as any).set('unauthorized', true);
    case constants.SERVER_ERROR:
      return setError(state, 'serverError', action.error);
    case constants.CONNECTION_ERROR:
      return setError(state, 'connectionError', action.error);
    case constants.NOT_FOUND:
      return setError(state, 'notFound', action.error);
    case constants.SERVICE_UNAVAILABLE:
      return setError(state, 'serviceUnavailable', action.error);
    case constants.UNEXPECTED_ERROR:
      return setError(state, 'unexpectedError', action.error);
    case constants.FORBIDDEN:
      return setError(state, 'forbidden', 'Forbidden');
    case constants.SUCCESS:
      return reset(state);
    case constants.RESET:
      return reset(state);
    default:
      return state;
  }
};
