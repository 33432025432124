import React from 'react';
import { baseAuditorPath } from '~/app/constants/url/auditor';
import { Redirect, Route, Switch } from 'react-router-dom';

import Auditor from './auditor/index';

const Dazzler = () => (
  <Switch>
    <Route path={baseAuditorPath} component={Auditor} />
    <Redirect to={{ pathname: baseAuditorPath, search: window.location.search }} />
  </Switch>
);

export default Dazzler;
