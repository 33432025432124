import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import s from './styles.module.scss';

type linkPropTypes = {
  className?: string;
  children: any;
  to: any;
};

export const UnderlinedButtonLink = ({ className, children, ...props }: linkPropTypes) => (
  <Link className={cx(s.buttonUnderlined, className)} {...props}>
    {children}
  </Link>
);
