import Typography from '@mui/material/Typography';
import React, { useRef, useState } from 'react';
import Dropzone, { DropzoneOptions } from 'react-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import { Show } from '~/app/components/core/show';
import { IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDeleteReportFile, useUploadReportFile } from '~/data/reports/queries';
import cx from '~/data/utils/helpers/cx';
import { AxiosError } from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { Match, Switch } from '~/app/components/core/switch';
import LinearProgress from '@mui/material/LinearProgress';
import { logger } from '~/logger';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  borderWidth: 1,
  borderRadius: 2,
  borderColor: '#696969',
  borderStyle: 'dashed',
  color: '#696969',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  minHeight: 200,
};

const focusedStyle = {
  borderColor: '#2196F3',
};

const acceptStyle = {
  borderColor: '#00E676',
};

const rejectStyle = {
  borderColor: '#FF1744',
};

export type FileData = {
  name: string;
  id?: string;
};

interface UploaderProps {
  options?: DropzoneOptions;
  framework: string;
  extensions: string[];
  defaultFileData?: FileData;
  onSettled: () => Promise<void>;
}

export function Uploader({ options, framework, defaultFileData, onSettled, extensions }: UploaderProps) {
  const { clientId = '', reportId = '' } = useParams<{ clientId?: string; reportId?: string }>();
  const [file, setFile] = useState<FileData | null>(defaultFileData ?? null);
  const [uploadError, setUploadError] = useState<null | AxiosError>(null);
  const abortController = useRef<AbortController>(new AbortController());
  const [progress, setProgress] = useState<number>(0);

  const { mutate: uploadFile, isLoading: isFileUploading } = useUploadReportFile({
    onSettled: onSettled,
    onSuccess(data) {
      setFile({ name: data.name ?? 'unknown file', id: data.reportFileId });
    },
    onError(error) {
      if (error.message.match(/cancel/)) {
        logger.info(
          'file upload for client %s and report %s was cancelled. reporting framework = %s',
          clientId,
          reportId,
          framework,
        );
        return;
      }
      setUploadError(error);
    },
  });
  const { mutate: deleteFile } = useDeleteReportFile(clientId, reportId);

  function upload(file: File) {
    setFile({ name: file.name });
    uploadFile({
      clientId,
      reportId,
      file,
      reportingFramework: framework,
      signal: abortController.current.signal,
      onUploadProgress: (ev) => {
        setProgress(Math.round((ev.loaded * 100) / (ev.total ?? 1)));
      },
    });
  }

  return (
    <Dropzone
      onDrop={(acceptedFiles) => {
        const file = acceptedFiles[0];
        upload(file);
      }}
      multiple={false}
      {...options}
    >
      {({ getRootProps, getInputProps, isFocused, isDragAccept, isDragReject }) => {
        const style = {
          ...baseStyle,
          ...(isFocused ? focusedStyle : {}),
          ...(isDragAccept ? acceptStyle : {}),
          ...(isDragReject ? rejectStyle : {}),
        };
        return (
          <Switch>
            <Match when={file != null}>
              {/* @ts-expect-error - Type '{ borderStyle: "solid"; flex: number; display: string; flexDirection: string; alignItems: string; justifyContent: string; padding: string; borderWidth: number; borderRadius: number; borderColor: string; color: string; outline: string; transition: string; minHeight: number; }' is not assignable to type 'Properties<string | number, string & {}>'. Types of property 'flexDirection' are incompatible. Type 'string' is not assignable to type 'FlexDirection | undefined'. */}
              <div style={{ ...baseStyle, borderStyle: 'solid' }} className="flex-basis-1/3">
                <Typography fontSize={21} className="mb-3">
                  {framework} {extensions.join(', ')}
                </Typography>
                <ul className="w-full flex flex-col gap-2">
                  <li
                    key={file?.name}
                    className={cx('bg-secondary-main rounded-md', !!uploadError && 'border-red border')}
                  >
                    <div className="w-full flex justify-between items-center px-3 py-2">
                      <div className={cx('truncate flex items-center gap-x-3', !!uploadError ? 'text-red' : '')}>
                        <Show when={isFileUploading}>
                          <div className="relative leading-none">
                            <CircularProgress variant="determinate" value={progress} />
                            <div className="inset-0 absolute flex items-center justify-center">
                              <Typography variant="caption" className="text-xs" component="span">{`${Math.round(
                                progress,
                              )}%`}</Typography>
                            </div>
                          </div>
                        </Show>
                        <Typography className="text-lg">{file?.name}</Typography>
                      </div>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (isFileUploading) {
                            abortController.current.abort();
                          } else if (file?.id) {
                            deleteFile({ fileId: file.id });
                          }
                          setFile(null);
                          setUploadError(null);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                    <Show when={isFileUploading}>
                      <LinearProgress className="rounded-b-md" />
                    </Show>
                  </li>
                </ul>
              </div>
            </Match>
            <Match when={file == null}>
              {/* @ts-expect-error - Type '{ borderColor: string; flex: number; display: string; flexDirection: string; alignItems: string; justifyContent: string; padding: string; borderWidth: number; borderRadius: number; borderStyle: string; color: string; outline: string; transition: string; minHeight: number; }' is not assignable to type 'Properties<string | number, string & {}>'. Types of property 'flexDirection' are incompatible. Type 'string' is not assignable to type 'FlexDirection | undefined'. */}
              <div {...getRootProps({ style })} className="hover:!border-orange flex-basis-1/3">
                <input {...getInputProps()} />
                <Typography fontSize={21}>
                  {framework} {extensions.join(', ')}
                </Typography>

                <Typography fontSize={16}>
                  Drag and drop or{' '}
                  <span role="button" className="text-orange">
                    choose file
                  </span>
                </Typography>
              </div>
            </Match>
          </Switch>
        );
      }}
    </Dropzone>
  );
}
